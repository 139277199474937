import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

// import qubesApi from "./api";
import userSlice from "./slices/user-slice";
import modelSlice from "./slices/model-slice";
import locationSlice from "./slices/location-slice";

export const store = configureStore({
  reducer: {
    // [qubesApi.reducerPath]: qubesApi.reducer,
    [userSlice.name]: userSlice.reducer,
    [modelSlice.name]: modelSlice.reducer,
    [locationSlice.name]: locationSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(qubesApi.middleware);
  // },
});

// Export typed useSelector for consumption
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Export typed useDispatch for consumption
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
