import { EuiFlexGroup, EuiIcon, EuiText } from "@elastic/eui";
import { WebLocationBannerProps } from "../../../common/web/web-location-banner";
import "./web-location-banner.scss";
import { WalmartMobileIcon } from "../../../../assets/assets";

export const WalmartWebLocationBanner = ({
  city,
  postCode,
}: WebLocationBannerProps) => {
  return (
    <div className="walmart-web-location-banner">
      <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
        <img
          className="pickup-location-image"
          src={WalmartMobileIcon}
          alt="pickup location logo"
        />
        <EuiFlexGroup
          className="pickup-location-text"
          direction="column"
          gutterSize="none"
          alignItems="baseline"
        >
          <EuiText style={{ fontSize: "16px", fontWeight: "700" }}>
            How do you want your items?
          </EuiText>
          <EuiText style={{ fontSize: "14px", lineHeight: "18px" }}>
            {city}, {postCode}
            <EuiIcon type={"dot"} size="s" />
            {city} Supercenter
          </EuiText>
        </EuiFlexGroup>
        <EuiIcon type="arrowDown" size="s" color="ghost" />
      </EuiFlexGroup>
    </div>
  );
};
