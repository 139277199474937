import { UidKey } from "../common";

export type POSEventStatus =
  | "Sold"
  | "Granted"
  | "Denied"
  | "Not Found"
  | "Error";

/**
 * This type is used as the constructor argument for the
 * {@link PointOfSaleEvent} class. This allows us to pass
 * named arguments into the constuctor instead of relying
 * upon positional arguments.
 */
export type IPointOfSaleEvent = UidKey & {
  active: boolean;
  correlationCodeUid?: string;
  initiated: string;
  quantity: number;
  rawCorrelationCode: string;
  registerId: string;
  saleCompleted?: string;
  upc?: string;
  status: POSEventStatus;
  store: string;
  extraConfig?: unknown;
};

export class PointOfSaleEvent implements IPointOfSaleEvent {
  uid: string;
  active: boolean;
  correlationCodeUid?: string;
  initiated: string;
  quantity: number;
  rawCorrelationCode: string;
  registerId: string;
  saleCompleted?: string;
  upc?: string;
  status: POSEventStatus;
  store: string;
  extraConfig?: unknown;

  constructor(arg: IPointOfSaleEvent) {
    this.uid = arg.uid;
    this.active = arg.active;
    this.correlationCodeUid = arg.correlationCodeUid;
    this.initiated = arg.initiated;
    this.quantity = arg.quantity;
    this.rawCorrelationCode = arg.rawCorrelationCode;
    this.registerId = arg.registerId;
    this.saleCompleted = arg.saleCompleted;
    this.upc = arg.upc;
    this.status = arg.status;
    this.store = arg.store;
    this.extraConfig = arg.extraConfig;
  }

  /**
   * Clone this instance
   * @returns {ReceiptLineItem} a new instance of ReceiptLineItem with the same values
   */
  clone(): PointOfSaleEvent {
    return new PointOfSaleEvent({ ...this });
  }
}

const _sample: Required<IPointOfSaleEvent> = {
  uid: "string",
  active: false,
  correlationCodeUid: "string",
  initiated: "string",
  quantity: 1,
  rawCorrelationCode: "string",
  registerId: "string",
  saleCompleted: "string",
  upc: "string",
  status: "Not Found",
  store: "string",
  extraConfig: "json",
};

export const PointOfSaleEventFields = Object.keys(
  new PointOfSaleEvent(_sample)
);
