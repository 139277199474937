import {
  CostcoLogo,
  CvsLogo,
  KrogerLogo,
  KrogerLogoBlue,
} from "../../../assets/assets";
import { ItemMaster } from "../../../model/pos/item-master";
import { Location } from "../../../services/geoapify-service";
import CostcoWebsiteHomePage from "../../costco/web/web-home-page/web-home-page";
import WebReceiptPage from "../../common/web/web-home-receipt/web-receipt-page";
import { CostcoWebLocationBanner } from "../../costco/web/web-location-banner/web-location-banner";
import { CostcoWebNavigationBanner } from "../../costco/web/web-navigation-banner/web-navigation-banner";
import { CostcoWebSessionBanner } from "../../costco/web/web-session-banner/web-session-banner";
import KrogerWebsiteHomePage from "../../kroger/web/web-home-page/web-home-page";
import { PathSeparator, WebItemPath } from "./web-item-path.ts/web-item-path";
import { KrogerWebLocationBanner } from "../../kroger/web/web-location-banner/web-location-banner";
import { KrogerWebSessionBanner } from "../../kroger/web/web-session-banner/web-session-banner";
import { WebCartPageProps } from "./web-cart-page";
import { WebHomePageProps } from "./web-home-page";
import WebItemDetailPage from "./web-item-detail-page/web-item-detail-page";
import { WebReceiptPageProps } from "./web-receipt-page";
import { CostcoWebItemDetailOrdering } from "../../costco/web/web-item-detail-ordering/web-item-detail-ordering";
import { KrogerWebItemDetailOrdering } from "../../kroger/web/web-item-detail-ordering/web-item-detail-ordering";
import { WebItemDetailOrderingProps } from "./web-item-detail-ordering";
import { CostcoWebItemFurtherDetails } from "../../costco/web/web-item-detail-further-details/web-item-detail-further-details";
import { KrogerWebItemFurtherDetails } from "../../kroger/web/web-item-detail-further-details/web-item-detail-further-details";
import { WebItemProps } from "./web-item-props";
import { KrogerWebItemDetailPricing } from "../../kroger/web/web-item-detail-pricing/web-item-detail-pricing";
import { CostcoWebItemDetailPricing } from "../../costco/web/web-item-detail-pricing/web-item-detail-pricing";
import WebCartPage from "./web-cart-page/web-cart-page";
import { CostcoWebCartDeliveryInfo } from "../../costco/web/web-cart-delivery-info/web-cart-delivery-info";
import { KrogerWebCartDeliveryInfo } from "../../kroger/web/web-cart-delivery-info/web-cart-delivery-info";
import ENV from "../../../services/env-service";
import { CvsWebSessionBanner } from "../../cvs/web/web-session-banner/web-session-banner";
import CvsWebsiteHomePage from "../../cvs/web/web-home-page/web-home-page";
import { CvsWebLocationBanner } from "../../cvs/web/web-location-banner/web-location-banner";
import { CvsWebLinkBanner } from "../../cvs/web/web-link-banner/web-link-banner";
import { CvsWebItemDetailPricing } from "../../cvs/web/web-item-detail-pricing/web-item-detail-pricing";
import { CvsWebItemDetailOrdering } from "../../cvs/web/web-item-detail-ordering/web-item-detail-ordering";
import { CvsWebItemFurtherDetails } from "../../cvs/web/web-item-detail-further-details/web-item-detail-further-details";
import { EuiSpacer, EuiFlexGroup, EuiText, EuiFlexItem } from "@elastic/eui";
import { CvsWebCartDeliveryInfo } from "../../cvs/web/web-cart-delivery-info/web-cart-delivery-info";

export type Company = "costco" | "kroger" | "cvs";

export const CompanyResolver = {
  subdomain: "costco" as Company, //(window.location.hostname?.split(".")?.[0] ?? "kroger") as Company,

  getRootPageContents: (reroute: (url: string) => void) => {
    switch (CompanyResolver.subdomain) {
      case "cvs":
      case "costco":
      case "kroger": {
        return (
          <>
            <div
              role="button"
              className="button pos"
              onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/pos`)}
              tabIndex={2}
            >
              <img src={CompanyResolver.getLogoLight()} alt="logo" />
              <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
                Point of Sale
              </div>
            </div>
            <div
              className="button ecommerce"
              role="button"
              onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/web`)}
              tabIndex={3}
            >
              <img src={CompanyResolver.getLogoLight()} alt="logo" />
              <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
                E-Commerce
              </div>
            </div>
          </>
        );
      }
    }
  },

  getItemFilters: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return " and demoCategories like '%costco%' or demoCategories like '%club%'";
      }
      case "kroger": {
        return " and demoCategories like '%kroger%' or demoCategories like '%grocery%'";
      }
      case "cvs": {
        return " and demoCategories like '%cvs%' or demoCategories like '%grocery%'";
      }
      default:
        throw new Error(
          "getItemFilters don't know how to handle " + CompanyResolver.subdomain
        );
    }
  },

  getLogo: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return CostcoLogo;
      }
      case "kroger": {
        return KrogerLogoBlue;
      }
      case "cvs": {
        return CvsLogo;
      }
      default:
        throw new Error(
          "getLogo don't know how to handle " + CompanyResolver.subdomain
        );
    }
  },

  getLogoLight: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return CostcoLogo;
      }
      case "kroger": {
        return KrogerLogo;
      }
      case "cvs": {
        return CvsLogo;
      }
      default:
        throw new Error(
          "getLogoLight don't know how to handle " + CompanyResolver.subdomain
        );
    }
  },

  getWebHeader: (shoppingCartQuantity: number, location?: Location) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return (
          <>
            <CostcoWebSessionBanner
              shoppingCartQuantity={shoppingCartQuantity}
            />
            <CostcoWebNavigationBanner />
            <CostcoWebLocationBanner
              city={location?.city ?? ""}
              state={location?.state ?? ""}
              postCode={location?.postCode ?? ""}
            />
          </>
        );
      }
      case "kroger": {
        return (
          <>
            <KrogerWebSessionBanner
              shoppingCartQuantity={shoppingCartQuantity}
            />
            <KrogerWebLocationBanner
              city={location?.city ?? ""}
              state={location?.state ?? ""}
              postCode={location?.postCode ?? ""}
            />
          </>
        );
      }
      case "cvs": {
        return (
          <>
            <CvsWebSessionBanner shoppingCartQuantity={shoppingCartQuantity} />
            <CvsWebLinkBanner />
            <CvsWebLocationBanner />
          </>
        );
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getHomePage: (props: WebHomePageProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebsiteHomePage {...props} />;
      }
      case "kroger": {
        return <KrogerWebsiteHomePage {...props} />;
      }
      case "cvs": {
        return <CvsWebsiteHomePage {...props} />;
      }
      default:
        throw new Error(
          "getHomePage don't know how to handle " + CompanyResolver.subdomain
        );
    }
  },

  getReceiptPage: (props: WebReceiptPageProps) => {
    return <WebReceiptPage {...props} />;
  },

  getCartPage: (props: WebCartPageProps) => {
    return <WebCartPage {...props} />;
  },

  getCartDeliveryInfo: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebCartDeliveryInfo />;
      }
      case "kroger": {
        return <KrogerWebCartDeliveryInfo />;
      }
      case "cvs": {
        return <CvsWebCartDeliveryInfo />;
      }
      default:
        throw new Error(
          "getCartDeliveryInfo don't know how to handle " +
            CompanyResolver.subdomain
        );
    }
  },
  getShippingText: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return "Shipping and Handling for";
      }
      case "kroger":
      case "cvs": {
        return "Pickup for";
      }
      default:
        throw new Error(
          "getShippingText don't know how to handle " +
            CompanyResolver.subdomain
        );
    }
  },

  getWebItemPath: (item: ItemMaster) => {
    let separator: PathSeparator;
    switch (CompanyResolver.subdomain) {
      case "costco":
        separator = "forward-slash";
        break;
      case "kroger":
      case "cvs":
        separator = "arrow-right";
        break;
      default:
        throw new Error(
          "getWebItemPath don't know how to handle " + CompanyResolver.subdomain
        );
    }

    return <WebItemPath item={item} separator={separator} />;
  },

  getItemDetailPage: (props: WebItemProps) => {
    return <WebItemDetailPage {...props} />;
  },

  getItemDetailPricing: (props: WebItemProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebItemDetailPricing {...props} />;
      }
      case "kroger": {
        return <KrogerWebItemDetailPricing {...props} />;
      }
      case "cvs": {
        return <CvsWebItemDetailPricing {...props} />;
      }
      default:
        throw new Error(
          "getItemDetailPricing don't know how to handle " +
            CompanyResolver.subdomain
        );
    }
  },

  getItemDetailOrdering: (props: WebItemDetailOrderingProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebItemDetailOrdering {...props} />;
      }
      case "kroger": {
        return <KrogerWebItemDetailOrdering {...props} />;
      }
      case "cvs": {
        return <CvsWebItemDetailOrdering {...props} />;
      }
      default:
        throw new Error(
          "getItemDetailOrdering don't know how to handle " +
            CompanyResolver.subdomain
        );
    }
  },

  getItemDetailFurtherDetails: (props: WebItemProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebItemFurtherDetails {...props} />;
      }
      case "kroger": {
        return <KrogerWebItemFurtherDetails {...props} />;
      }
      case "cvs": {
        return <CvsWebItemFurtherDetails {...props} />;
      }
      default:
        throw new Error(
          "getItemDetailFurtherDetails don't know how to handle " +
            CompanyResolver.subdomain
        );
    }
  },

  getItemDetailFeatures: (props: WebItemProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco":
      case "kroger": {
        return (
          <>
            <EuiSpacer size="xl" />
            <EuiFlexGroup
              className="item-features"
              direction="column"
              alignItems="flexStart"
              justifyContent="flexStart"
              gutterSize="none"
            >
              <EuiText>Features:</EuiText>
              <EuiFlexItem>
                <ul>
                  {props.item.primaryFeatures?.map((feature) => {
                    return <li key={feature}>{feature}</li>;
                  })}
                </ul>
                {props.item.sanofiOtcSku ? (
                  <ul className="sanofiSku">
                    <li>**Purchase Restrictions Apply</li>
                  </ul>
                ) : null}
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        );
      }
      case "cvs": {
        return <EuiSpacer size="l" />;
      }
      default:
        throw new Error(
          "getItemDetailFurtherDetails don't know how to handle " +
            CompanyResolver.subdomain
        );
    }
  },
};
