import { ItemMaster } from "./model/pos/item-master";
import { ReceiptLineItem } from "./model/pos/receipt-line-item";

/**
 * Get the total cost off all scanned items
 * @param {ReceiptLineItem[]} receiptLineItems - The items in the customer's cart
 * @param {ItemMaster[]} items - The items for sale
 * @returns {string} The total cost of all the scanned items, to the second decimal place, as a string
 */
export const getTotal = (
  receiptLineItems: ReceiptLineItem[],
  items: ItemMaster[]
): string => {
  const total = receiptLineItems.reduce(
    (agg: number, nextItem: ReceiptLineItem) => {
      return (
        agg +
        nextItem.quantity *
          items.find((item) => item.uid === nextItem.itemUid)!.price
      );
    },
    0
  );

  return `$${total.toFixed(2)}`;
};
