import { WalmartLogo } from "../../assets/assets";
import { AbstractCompanyComponentResolver } from "./abstract-company-resolver";
import { Location } from "../../services/geoapify-service";
import { ItemMaster } from "../../model/pos/item-master";
import { WebHomePageProps } from "../common/web/web-home-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import { WebItemProps } from "../common/web/web-item-props";
import {
  EuiSpacer,
  EuiFlexGroup,
  EuiText,
  EuiFlexItem,
  EuiHorizontalRule,
} from "@elastic/eui";
import { WalmartWebBanner } from "../walmart/web/web-banner/web-banner";
import { WalmartWebLocationBanner } from "../walmart/web/web-location-banner/web-location-banner";
import { WalmartWebLinkBanner } from "../walmart/web/web-link-banner/web-link-banner";
import WalmartWebsiteHomePage from "../walmart/web/web-home-page/web-home-page";
import { WalmartWebCartDeliveryInfo } from "../walmart/web/web-cart-delivery-info/web-cart-delivery-info";
import { WalmartWebItemDetailPricing } from "../walmart/web/web-item-detail-pricing/web-item-detail-pricing";
import { WalmartWebItemDetailOrdering } from "../walmart/web/web-item-detail-ordering/web-item-detail-ordering";
import { WalmartWebItemFurtherDetails } from "../walmart/web/web-item-detail-further-details/web-item-detail-further-details";

export class WalmartComponentResolver extends AbstractCompanyComponentResolver {
  constructor() {
    super("walmart");
  }

  getItemFilters(): string {
    return " and demoCategories like '%walmart%' or demoCategories like '%grocery%'";
  }

  getLogo() {
    return WalmartLogo;
  }

  getLogoLight() {
    return WalmartLogo;
  }

  getWebHeader(shoppingCartQuantity: number, location?: Location): JSX.Element {
    return (
      <>
        <WalmartWebBanner shoppingCartQuantity={shoppingCartQuantity}>
          <WalmartWebLocationBanner
            city={location?.city ?? ""}
            state={location?.state ?? ""}
            postCode={location?.postCode ?? ""}
          />
        </WalmartWebBanner>
        <WalmartWebLinkBanner />
      </>
    );
  }

  getHomePage(props: WebHomePageProps): JSX.Element {
    return <WalmartWebsiteHomePage {...props} />;
  }

  getCartDeliveryInfo(): JSX.Element {
    return <WalmartWebCartDeliveryInfo />;
  }

  getShippingText(): string {
    return "Pickup for";
  }

  getWebItemPath(item: ItemMaster): JSX.Element {
    // Walmart site doesn't show a item path
    return <div></div>;
  }

  getItemDetailPricing(props: WebItemProps): JSX.Element {
    return <WalmartWebItemDetailPricing {...props} />;
  }

  getItemDetailOrdering(props: WebItemDetailOrderingProps): JSX.Element {
    return <WalmartWebItemDetailOrdering {...props} />;
  }

  getItemDetailFurtherDetails(props: WebItemProps): JSX.Element {
    return <WalmartWebItemFurtherDetails {...props} />;
  }

  getItemDetailFeatures(props: WebItemProps): JSX.Element {
    return (
      <>
        <EuiSpacer size="s" />
        <EuiFlexGroup
          className="item-features"
          direction="column"
          alignItems="flexStart"
          justifyContent="flexStart"
          gutterSize="none"
        >
          <EuiHorizontalRule margin="m" />
          <EuiText style={{ fontSize: "16px", fontWeight: "700" }}>
            About this item
          </EuiText>
          <EuiSpacer size="m" />
          <EuiFlexItem style={{ paddingRight: "5px" }}>
            <ul>
              {props.item.primaryFeatures?.map((feature) => {
                return <li key={feature}>{feature}</li>;
              })}
            </ul>
            {props.item.sanofiOtcSku ? (
              <ul className="sanofiSku">
                <li>**Purchase Restrictions Apply</li>
              </ul>
            ) : null}
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  }
}
