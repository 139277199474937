import { Customer } from "../../model/sanofi-otc/customer";
import { QRCode } from "../../model/sanofi-otc/qr-code";
import { Survey, SurveyData } from "../../model/sanofi-otc/survey";
import { QubesService } from "../../services/qubes-service";
import { SurveyIds } from "../../utils/survey-constants";

export const startNewSurvey = async (
  customer: Customer,
  surveyId: SurveyIds,
  onSurveyCreated: (survey: Survey) => void
) => {
  console.info("Insert a new Survey record");
  try {
    if (!customer) {
      console.error("Customer is required");
      return;
    }
    const newSurvey: Survey = {
      uid: "set_by_insert_operation",
      active: true,
      customerUid: customer?.uid,
      startedAt: new Date().toISOString(),
      status: "Started",
      surveyData: {} as SurveyData,
      surveyId,
    };
    const insertResult = await QubesService.insertSurvey(newSurvey);
    if (!insertResult.success || !insertResult.keys?.uid) {
      throw new Error("Unsuccessful insert of the survey");
    } else {
      newSurvey.uid = insertResult.keys.uid;
      onSurveyCreated(newSurvey);
      console.info(`New survey successfully inserted | ${newSurvey.uid}`);
    }
  } catch (err) {
    console.error("Error inserting survey", err);
  }
};

export const completeSurvey = async (
  customer: Customer,
  survey: Survey,
  onSurveyUpdated: (survey: Survey) => void,
  clearSurvey: (undefined: any) => void,
  onExpirationDateUpdated: (date: string) => void,
  onNewSurveyCode: (correlationCode: string, posCorrCodeUid: string) => void,
  correlationCode?: string
) => {
  if (!customer) {
    console.error("Customer is required");
    return;
  }
  if (!survey) {
    console.error("Current survey is not defined");
    return;
  }
  console.info("Update the Survey record");
  try {
    const surveyClone: Survey = {
      ...survey,
      status: correlationCode ? "CompletedOtcAllowed" : "CompletedOtcDenied",
      completedAt: new Date().toISOString(),
    };
    const updateResult = await QubesService.updateSurvey(surveyClone);
    if (
      !updateResult.success ||
      !updateResult.numberOfRowsUpdated ||
      updateResult.numberOfRowsUpdated < 1
    ) {
      throw new Error("Unsuccessful update of the survey");
    } else {
      onSurveyUpdated(surveyClone);
      console.info(`Survey successfully updated | ${surveyClone.uid}`);
    }
  } catch (err) {
    console.error("Error inserting survey", err);
  }

  if (correlationCode) {
    console.info("Insert the PosCorrelationCode record");
    try {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 14);

      onExpirationDateUpdated(expirationDate.toLocaleDateString("en-US"));

      const posCorrCode: QRCode = {
        uid: "set_by_insert_operation",
        surveyUid: survey.uid,
        active: true,
        expirationDate: expirationDate.toISOString().split("T")[0],
        correlationCode: correlationCode,
      };
      const qrInsertResult = await QubesService.insertQRCode(posCorrCode);
      if (!qrInsertResult.success || !qrInsertResult.keys?.uid) {
        throw new Error("Unsuccessful insert of the QR Code");
      } else {
        posCorrCode.uid = qrInsertResult.keys.uid;
        console.info(`QR code successfully inserted | ${posCorrCode.uid}`);
        onNewSurveyCode(correlationCode, posCorrCode.uid);
      }
    } catch (err) {
      console.error("Error inserting qr code", err);
    }
  } else {
    console.info("No correlation code so no QR code record created");
  }

  clearSurvey(undefined);
};
