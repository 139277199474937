import { EuiFlexItem, EuiFlexGroup, EuiText, EuiIcon } from "@elastic/eui";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { WebItemProps } from "../web-item-props";

export type PathSeparator = "forward-slash" | "arrow-right";

export type WebItemPathProps = {
  separator: PathSeparator;
} & WebItemProps;

export const WebItemPath = ({ item, separator }: WebItemPathProps) => {
  const getSeparator = useCallback(
    (stepInPath: string) => {
      switch (separator) {
        case "forward-slash":
          return <EuiText key={`${stepInPath}-slash`}>/</EuiText>;
        case "arrow-right":
          return <EuiIcon type="arrowRight" size="s" />;
      }
    },
    [separator]
  );

  return (
    <EuiFlexItem key={"item-path"} className="item-path" grow={false}>
      <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
        <Link key={"Home"} to={`/web`}>
          Home
        </Link>
        {item.websiteCategoryPath?.map((stepInPath) => {
          return (
            <EuiFlexGroup key={stepInPath} alignItems="center" gutterSize="s">
              {getSeparator(stepInPath)}
              <Link key={stepInPath} to={`/web`}>
                {stepInPath}
              </Link>
            </EuiFlexGroup>
          );
        })}
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};
