import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import "./web-link-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";

export const SamsclubWebLinkBanner = ({
  children,
}: {
  children: JSX.Element;
}) => {
  return (
    <div className="samsclub-web-link-banner">
      {children}
      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="l"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexItem grow={false}>
          <FakeLink text="More Offers" />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ flexDirection: "row", gap: "5px" }}>
          <FakeLink text="Sam's Cash" />
          <EuiIcon type="arrowDown" style={{ color: "#0068a0" }} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Sam's Club Credit" />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ flexDirection: "row", gap: "5px" }}>
          <FakeLink text="Member's Mark" />
          <EuiIcon type="arrowDown" style={{ color: "#0068a0" }} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Instant Savings" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Help Center" />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ flexDirection: "row", gap: "5px" }}>
          <FakeLink text="More" />
          <EuiIcon type="arrowDown" style={{ color: "#0068a0" }} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
