import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import ENV from "./env-service";

type ConnectionName = string;

const HttpsConnections: Record<ConnectionName, AxiosInstance> = {};

const PUBLIC_URL = ENV.PUBLIC_URL;
if (PUBLIC_URL) axios.defaults.baseURL = PUBLIC_URL;

const HttpsService = {
  getHttps() {
    let httpsInstance: AxiosInstance = HttpsConnections["qubes"];
    if (!httpsInstance) {
      httpsInstance = axios.create({});

      //could possibly detect expired tokens here and either refresh the token/force login
      // probably need to update the userSlice to remove the user

      httpsInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        let params = config?.params ?? {};

        if (typeof params["_ts"] === "undefined") {
          params["_ts"] = String(new Date().getTime());
          config.params = params;
        }
        return config;
      });

      HttpsConnections["qubes"] = httpsInstance;
    }

    return httpsInstance;
  },
};

export default HttpsService;
