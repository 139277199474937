import FakeLink from "../../../../components/fake-link/fake-link";

export const CostcoWebNavigationBanner = () => {
  return (
    <div className="web-navigation-banner">
      <FakeLink text="Shop" />
      <FakeLink text="Grocery" />
      <FakeLink text="Same-Day" />
      <FakeLink text="Deals" />
      {/* <FakeLink text="Business Delivery" /> */}
      <FakeLink text="Optical" />
      <FakeLink text="Pharmacy" />
      <FakeLink text="Services" />
      <FakeLink text="Photo" />
      <FakeLink text="Travel" />
      <FakeLink text="Membership" />
      <FakeLink text="Locations" />
    </div>
  );
};
