import { ItemMaster } from "../../model/pos/item-master";
import { Company } from "../common/web/company-resolver";
import { WebCartPageProps } from "../common/web/web-cart-page";
import WebCartPage from "../common/web/web-cart-page/web-cart-page";
import { WebHomePageProps } from "../common/web/web-home-page";
import WebReceiptPage from "../common/web/web-home-receipt/web-receipt-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import WebItemDetailPage from "../common/web/web-item-detail-page/web-item-detail-page";
import { WebItemProps } from "../common/web/web-item-props";
import { WebReceiptPageProps } from "../common/web/web-receipt-page";
import { Location } from "../../services/geoapify-service";
import ENV from "../../services/env-service";

export abstract class AbstractCompanyComponentResolver {
  companyName: Company;

  constructor(companyName: Company) {
    this.companyName = companyName;
  }

  abstract getItemFilters(): string;

  getRootPageContents(reroute: (url: string) => void): JSX.Element {
    return (
      <>
        <div
          role="button"
          className="button pos"
          onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/pos`)}
          tabIndex={2}
        >
          <img src={this.getLogoLight()} alt="logo" />
          <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
            Point of Sale
          </div>
        </div>
        <div
          className="button ecommerce"
          role="button"
          onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/web`)}
          tabIndex={3}
        >
          <img src={this.getLogoLight()} alt="logo" />
          <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
            E-Commerce
          </div>
        </div>
      </>
    );
  }

  abstract getLogo(): string;

  abstract getLogoLight(): string;

  abstract getWebHeader(
    shoppingCartQuantity: number,
    location?: Location
  ): JSX.Element;

  abstract getHomePage(props: WebHomePageProps): JSX.Element;

  getReceiptPage(props: WebReceiptPageProps): JSX.Element {
    return <WebReceiptPage {...props} />;
  }

  getCartPage(props: WebCartPageProps): JSX.Element {
    return <WebCartPage {...props} />;
  }

  abstract getCartDeliveryInfo(): JSX.Element;

  abstract getShippingText(): string;

  abstract getWebItemPath(item: ItemMaster): JSX.Element;

  getItemDetailPage(props: WebItemProps): JSX.Element {
    return <WebItemDetailPage {...props} />;
  }

  abstract getItemDetailPricing(props: WebItemProps): JSX.Element;

  abstract getItemDetailOrdering(
    props: WebItemDetailOrderingProps
  ): JSX.Element;

  abstract getItemDetailFurtherDetails(props: WebItemProps): JSX.Element;

  abstract getItemDetailFeatures(props: WebItemProps): JSX.Element;
}
