import { useCallback } from "react";
import { ItemMaster } from "../../../model/pos/item-master";
import "./list.scss";
import { getTotal } from "../../../helpers";
import { ReceiptLineItem } from "../../../model/pos/receipt-line-item";
import { CompanyResolver } from "../../../company/common/web/company-resolver";

/**
 * The props needed for List to function
 * @prop {ReceiptLineItem[]} receiptLineItems - The items currently in the user's cart
 * @prop {Item[]} items - The items for sale
 * @prop {(ReceiptLineItem) => void} onSelect - The method that handles selecting a different item from the cart
 * @prop {Item} selectedItem - The currently selected item, can be undefined if the cart is empty
 */
export type ListProps = {
  receiptLineItems: ReceiptLineItem[];
  items: ItemMaster[];
  onSelect: (item: ReceiptLineItem) => void;
  selectedItem?: ReceiptLineItem;
};

/**
 * The List component which displays all the items currently in the cart
 * as well as their cost and quantities
 * @param {ListProps} - The props needed to function
 */
const List = ({
  receiptLineItems,
  items,
  onSelect,
  selectedItem,
}: ListProps) => {
  /**
   * Build the list of scanned items
   */
  const buildList = useCallback(() => {
    /**
     * Handle the manual selection of an item
     * @param {ItemMaster} item - the item to be selected
     */
    const handleItemSelect = (item: ReceiptLineItem) => {
      onSelect(item);
    };

    return receiptLineItems
      ?.slice(0)
      .reverse()
      .map((scannedItem) => {
        const item = items.find((i) => i.uid === scannedItem.itemUid);
        return (
          <div
            className={`scanned-item ${
              selectedItem?.uid === scannedItem.uid && "selected"
            }`}
            key={scannedItem.uid}
            onClick={() => handleItemSelect(scannedItem)}
            role="button"
          >
            <div className="name">{item!.name}</div>
            <div className="quantity">{scannedItem.quantity}</div>
            <div className="price">
              {`$${(item!.price * scannedItem.quantity).toFixed(2)}`}
            </div>
          </div>
        );
      });
  }, [onSelect, items, receiptLineItems, selectedItem?.uid]);

  return (
    <div className="list-container" key="list">
      <img
        src={CompanyResolver.get().getComponentResolver().getLogo()}
        alt=" logo"
      />
      <div className="bottom">
        <div className="list">
          {receiptLineItems.length ? buildList() : null}
        </div>
        <div className="total">
          <div className="spacer" />
          <div className="label">Total</div>
          <div className="amount">{getTotal(receiptLineItems, items)}</div>
        </div>
      </div>
    </div>
  );
};

export default List;
