import HttpsService from "./https-service";

export type Location = {
  city: string;
  state: string;
  stateCode: string;
  postCode: string;
};

/**
 * Implementation wrapping the Geoapify service. See
 * https://myprojects.geoapify.com/api/7etVYPCs5PYUIDpR1ziq/keys
 */
export class GeoapifyService {
  private GEOAPIFY_API_KEY = "473ba7d9704d492f9ad8c23eb46ce6f8";

  private static _instance = new GeoapifyService();

  static get() {
    return this._instance;
  }

  private constructor() {}

  /**
   * Utilize the browser's IP address to determine the city & coordinates via IPINFO,
   * then use the geocode reverse lookup using the lat/long to determine the postCode,
   * and state. If any errors are encountered, or if no results are found, then
   * "Issaquah, Washington" is returned
   * @returns {Location} the location
   */
  async getLocationFromIP(): Promise<Location> {
    try {
      const ipResults = await HttpsService.getHttps().get(
        `https://api.geoapify.com/v1/ipinfo?&apiKey=${this.GEOAPIFY_API_KEY}`
      );

      const city = ipResults.data?.city?.name;
      const location = ipResults?.data?.location;

      if (!city || !location)
        throw new Error("Unable to determine city or location from IPINFO");

      const postCodeResults = await HttpsService.getHttps().get(
        `https://api.geoapify.com/v1/geocode/reverse?lat=${location?.latitude}&lon=${location?.longitude}&type=postcode&limit=1&format=json&apiKey=${this.GEOAPIFY_API_KEY}`
      );

      const postCode = postCodeResults.data?.results?.[0]?.postcode;
      const state = postCodeResults.data?.results?.[0]?.state;
      const stateCode = postCodeResults.data?.results?.[0]?.state_code;

      if (!postCode || !state || !stateCode)
        throw new Error(
          "Unable to determine postcode or state from reverse lookup"
        );

      return {
        city,
        state,
        stateCode,
        postCode,
      };
    } catch (err) {
      console.error(`Error trying to resolve location from IP`, err);
      return {
        city: "Issaquah",
        state: "Washington",
        stateCode: "WA",
        postCode: "98029",
      };
    }
  }
}
