import { ItemMaster } from "../../../model/pos/item-master";
import { ReceiptLineItem } from "../../../model/pos/receipt-line-item";
import "./kiosk-details-panel.scss";

export type KioskDetailsPanelProps = {
  item: ItemMaster | undefined;
  receiptLineItem: ReceiptLineItem | undefined;
  handleUpdateQuantity: (quantity: number) => void;
  remaining: number;
};

const KioskDetailsPanel = ({
  item,
  receiptLineItem,
  handleUpdateQuantity,
  remaining,
}: KioskDetailsPanelProps) => {
  if (!item) {
    return (
      <div className="kiosk-details-panel-container">
        <div className="welcome-text">
          Welcome to the Cialis self serve kiosk
        </div>
      </div>
    );
  } else {
    return (
      <div className="kiosk-details-panel-container item">
        <img alt="Item" src={item?.imageUrl} />
        <div className="inventory-section">
          <div className="quantity-section">
            <div className="name">{item?.name}:</div>
            <div className="description">{item?.description}</div>
            <div className="quantity">{`${receiptLineItem?.quantity} pack${
              receiptLineItem?.quantity === 1 ? "" : "s"
            }`}</div>
          </div>
          <div className="remaining">{`$${item?.price}`}</div>
        </div>
      </div>
    );
  }
};

export default KioskDetailsPanel;
