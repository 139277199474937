import { ItemMaster } from "../../../model/pos/item-master";
import { ReceiptLineItem } from "../../../model/pos/receipt-line-item";
import DownArrow from "../../../assets/down-arrow.svg";
import "./pos-details-panel.scss";

/**
 * The props needed for the Details Panel to function
 * @prop {ReceiptLineItem} selectedReceiptLineItem - The item currently selected
 * @prop {Item} selectedItem - The item currently selected
 * @prop {() => void} increaseQuantity - The method to call when increasing the count of the currently selected item
 * @prop {() => void} decreaseQuantity - The method to call when decreasing the count of the currently selected item
 */
export type DetailsPanelProps = {
  selectedReceiptLineItem?: ReceiptLineItem;
  selectedItem?: ItemMaster;
  increaseQuantity: () => void;
  decreaseQuantity: () => void;
};

/**
 * Handles displaying all the details about the current item
 * @returns {React.Component}
 */
const DetailsPanel = ({
  selectedReceiptLineItem,
  selectedItem,
  increaseQuantity,
  decreaseQuantity,
}: DetailsPanelProps) => {
  if (!selectedItem) {
    // Short circuit if there is no selected item return the empty container

    return (
      <div className="details-panel-container">
        <div className="no-items-message">Begin scanning items now</div>
        <img src={DownArrow} alt="down arrow" className="down-arrow" />
      </div>
    );
  } else {
    // If there is a selected item, return the details

    return (
      <div className="details-panel-container">
        <div className="info-section">
          <img alt="Item" src={selectedItem.imageUrl} />
          <div className="description-section">
            <div className="name">{selectedItem.name}</div>
            <div className="description">{selectedItem.description}</div>
            <div className="quantity">{`Quantity: ${
              selectedReceiptLineItem?.quantity ?? 0
            } ${
              selectedReceiptLineItem?.quantity === 1 ? "Unit" : "Units"
            }`}</div>
            <div className="cost">{`Unit Cost: ${selectedItem.price}`}</div>
          </div>
        </div>
        <div className="button-section">
          <div className="button remove" onClick={() => decreaseQuantity()}>
            Remove
          </div>
          <div className="filler" />
          <div className="add-subtract-container">
            <div
              className="add-subtract-button"
              onClick={() => increaseQuantity()}
            >
              +
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DetailsPanel;
