import { EuiFlexGroup } from "@elastic/eui";
import "./web-cart-delivery-info.scss";

export const MeijerWebCartDeliveryInfo = () => {
  return (
    <EuiFlexGroup
      className="meijer-cart-delivery-info"
      direction="column"
      gutterSize="s"
    ></EuiFlexGroup>
  );
};
