import { useMemo, useState } from "react";
import { getTotal } from "../../helpers";
import { ItemMaster } from "../../model/pos/item-master";
import "./checkout-modal.scss";
import { CrudResponse } from "../../qubes-client/protocol/crud.protocol";
import { Register } from "../../model/pos/register";
import { Receipt } from "../../model/pos/receipt";
import { QubesService } from "../../services/qubes-service";
import { Insertable, UidKey } from "../../model/common";
import { ReceiptLineItem } from "../../model/pos/receipt-line-item";

/**
 * The props the CheckoutModal needs to work
 * @prop {boolean} kiosk - Whether or not this is for the kiosk
 * @prop {ReceiptLineItem[]} receiptLineItems - The items in the cart
 * @prop {Item[]} items - The items for sale
 * @prop {() => void} onCancel - The method to close the modal
 * @prop {() => void} onCompleted - The method to fire when the transaction is completed
 */
export type CheckoutModalProps = {
  kiosk: boolean;
  receiptLineItems: ReceiptLineItem[];
  items: ItemMaster[];
  register: Register;
  onCancel: () => void;
  onCompleted: () => void;
};

const CheckoutModal = ({
  kiosk,
  receiptLineItems,
  items,
  register,
  onCancel,
  onCompleted,
}: CheckoutModalProps) => {
  // Whether or not we are in credit mode
  const [usingCredit, setUsingCredit] = useState<boolean>(false);

  // Whether or not we are in credit mode
  const [usingCash, setUsingCash] = useState<boolean>(false);

  /**
   * Handles rendering the correct flow
   */
  const renderCorrectFlow = useMemo(() => {
    if (usingCredit) {
      return (
        <div className="middle-row">
          <div className="message">
            Please follow the instructions on the pin pad.
          </div>
          <div
            className="complete"
            onClick={async () => {
              try {
                const receipt: Insertable<Receipt> = {
                  registerUid: register.uid,
                  active: true,
                };

                console.info("Insert Receipt");
                const receiptInsertResult: CrudResponse<UidKey> =
                  await QubesService.insertReceipt(receipt);

                if (
                  receiptInsertResult.success &&
                  receiptInsertResult.keys != null
                ) {
                  // Create each of the transaction items
                  const lineItems = await Promise.all(
                    receiptLineItems.map(async (rli) => {
                      console.log(
                        `Insert ReceiptLineItem | ItemUid: ${rli.itemUid}`
                      );
                      rli.receiptUid = receiptInsertResult.keys?.uid;
                      return QubesService.insertReceiptLineItem(rli);
                    })
                  );
                  console.info(
                    `All inserts successful? | ${lineItems?.every(
                      (li) => li.success
                    )}`
                  );
                }
              } catch (err) {
                console.error(err);
              }
              onCompleted();
            }}
          >
            Transaction Complete
          </div>
        </div>
      );
    } else if (usingCash) {
      return (
        <div className="middle-row">
          <div className="message">Total paid and change given.</div>
          <div className="complete" onClick={onCompleted}>
            Transaction Complete
          </div>
        </div>
      );
    } else {
      return (
        <div className="button-row">
          {!kiosk && (
            <div className="cash button" onClick={() => setUsingCash(true)}>
              Cash
            </div>
          )}
          <div
            className={`credit button ${kiosk && "wide"}`}
            onClick={() => setUsingCredit(true)}
          >
            Credit
          </div>
        </div>
      );
    }
  }, [kiosk, onCompleted, usingCash, usingCredit, receiptLineItems, register]);

  return (
    <div className="checkout-modal-container">
      <div className="inner-container">
        <div className="total">
          {"Total:  " + getTotal(receiptLineItems, items)}
        </div>
        {renderCorrectFlow}
        <div className="cancel" onClick={onCancel}>
          Cancel
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;
