import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import "./web-link-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";

export const CvsWebLinkBanner = () => {
  return (
    <div className="cvs-web-link-banner">
      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="l"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexItem grow={false}>
          <FakeLink text="Schedule a vaccine" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Manage Prescriptions" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Photo" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="MinuteClinic Services" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="ExtraCare savings" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Deals of the Week" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Weekly ad" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Primary care for older adults" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
