import { UidKey } from "../common";

export type ItemMaster = UidKey & {
  name: string;
  description: string;
  primaryFeatures: string[];
  specifications: string[][];
  websiteCategoryPath: string[];
  imageUrl: string;
  imageUrlLarge: string;
  price: number;
  weight: number; // for randomization
  order?: number; // for order on website home page
  environment?: "home page" | "pos";
  demoCategories?: ("retail" | "grocery")[];
  active: boolean;
  sanofiOtcSku: boolean;
  upc: string;
  surveyId?: string;
  extraConfig: unknown;
};

const _sample: Required<ItemMaster> = {
  uid: "string",
  active: false,
  description: "string",
  primaryFeatures: ["string"],
  specifications: [["string", "string"]],
  websiteCategoryPath: ["string"],
  imageUrl: "string",
  imageUrlLarge: "string",
  price: 1,
  name: "string",
  weight: 1,
  order: 1,
  environment: "home page",
  demoCategories: ["retail"],
  sanofiOtcSku: false,
  upc: "string",
  surveyId: "string",
  extraConfig: "json",
};

export const ItemMasterFieldList = Object.keys(_sample);
