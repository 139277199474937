import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import "./web-link-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";
import {
  WalmartBoxesIcon,
  WalmartCirclesIcon,
} from "../../../../assets/assets";

export const WalmartWebLinkBanner = () => {
  return (
    <div className="walmart-web-link-banner">
      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="l"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexGroup
          className="border-on-hover"
          direction="row"
          gutterSize="s"
          alignItems="center"
        >
          <img className="boxes" src={WalmartBoxesIcon} alt="boxes" />
          <EuiFlexItem className="big-text">Departments</EuiFlexItem>
          <EuiIcon type="arrowDown" size="s" />
        </EuiFlexGroup>
        <EuiFlexGroup
          className="border-on-hover"
          direction="row"
          gutterSize="s"
          alignItems="center"
        >
          <img className="boxes" src={WalmartCirclesIcon} alt="boxes" />
          <EuiFlexItem className="big-text">Services</EuiFlexItem>
          <EuiIcon type="arrowDown" size="s" />
        </EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiText>|</EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Savings" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Grocery & Essentials" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Thanksgiving" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Holiday Shop" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Gift Ideas" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="New & Trending" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Toy Shop" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Home" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Fashion" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Electronics" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
