import { BrowserRouter, Route, Routes } from "react-router-dom";
import BasePage from "../pages/base/base-page";
import LoginPage from "../pages/login/login-page";
import NotFoundPage from "../pages/not-found/not-found";
import HomePage from "../pages/home/home-page";
import POSPage from "../pages/pos/pos-page";
import KioskPage from "../pages/kiosk/kiosk-page";
import AuthWall from "../components/auth-wall/auth-wall";
import ENV from "../services/env-service";
import WebsiteBasePage from "../pages/website/website-base-page";
import SurveyPageWrapper from "../pages/survey/survey-page-wrapper";
import SetupPage from "../pages/setup/setup-page";

export default function AppRoutes() {
  return (
    <AuthWall roles={["ActivatedUser"]}>
      <BrowserRouter basename={ENV.REACT_ROUTER_BASENAME}>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginPage />
            }
          />
          <Route
            path="/"
            element={
              <BasePage>
                <HomePage />
              </BasePage>
            }
          />
          <Route
            path="/pos"
            element={
              <BasePage>
                <POSPage />
              </BasePage>
            }
          />
          <Route
            path="/kiosk"
            element={
              <BasePage>
                <KioskPage />
              </BasePage>
            }
          />
          <Route
            path="/web/*"
            element={
              <BasePage>
                <WebsiteBasePage />
              </BasePage>
            }
          />
          {/* <Route
            path="/web/cart"
            element={
              <BasePage>
                <WebsiteCartPage />
              </BasePage>
            }
          /> */}
          <Route
            path="/web/detail/:itemUidOrName"
            element={
              <BasePage>
                <WebsiteBasePage />
              </BasePage>
            }
          />
          <Route
            path="/web/receipt/:receiptUid"
            element={
              <BasePage>
                <WebsiteBasePage />
              </BasePage>
            }
          />
          <Route
            path="/survey"
            element={
              <BasePage>
                <SurveyPageWrapper />
              </BasePage>
            }
          />
          <Route
            path="/setup"
            element={
              <BasePage>
                <SetupPage />
              </BasePage>
            }
          />
          <Route
            path="*"
            element={
              <BasePage>
                <NotFoundPage />
              </BasePage>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthWall>
  );
}
