import { EuiFlexGroup, EuiIcon, EuiSpacer, EuiText } from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const SamsclubWebItemDetailPricing = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      className="samsclub-item-details--price"
      direction="column"
      justifyContent="flexStart"
      gutterSize="none"
      alignItems="baseline"
    >
      <EuiFlexGroup
        direction="row"
        gutterSize="none"
        justifyContent="flexStart"
        alignItems="center"
      >
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiText size="s">4.8 (120053)</EuiText>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <EuiText className="price">${item?.price}</EuiText>
      <EuiSpacer size="m" />
      <EuiText className="">Prices may vary in club and online.</EuiText>
    </EuiFlexGroup>
  );
};
