import { EuiFlexGroup, EuiIcon, EuiText } from "@elastic/eui";
import "./web-location-banner.scss";
import { useSelector } from "react-redux";
import { locationStateSelector } from "../../../../state/slices/location-slice";

export const SamsclubWebLocationBanner = () => {
  const location = useSelector(locationStateSelector);

  return (
    <div className="samsclub-web-location-banner">
      <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
        <EuiText style={{ fontSize: "14px" }}>Your club</EuiText>
        <EuiText
          style={{
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "18px",
            color: "#0068a0",
          }}
        >
          {location?.city}, {location?.stateCode}
        </EuiText>
        <EuiIcon type="arrowDown" size="s" />
      </EuiFlexGroup>
    </div>
  );
};
