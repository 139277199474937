import ENV from "../../services/env-service";
import { Product } from "../common/survey/product-resolver";
import { toTitleCase } from "../../utils/text-utils";
import { Question } from "../../model/sanofi-otc/question";
import SurveyWrapper, {
  SurveyWrapperProps,
} from "../common/survey/survey-wrapper/survey-wrapper";
import SurveyLandingWeb, {
  SurveyLandingProps,
} from "../common/survey/survey-landing-web/survey-landing-web";
import SurveyLanding from "../common/survey/survey-landing/survey-landing";
import SurveyStop, {
  SurveyStopProps,
} from "../common/survey/survey-stop/survey-stop";
import SurveyWelcome, {
  SurveyWelcomeProps,
} from "../common/survey/survey-welcome/survey-welcome";
import SurveyCode, {
  SurveyCodeProps,
} from "../common/survey/survey-code/survey-code";
import SurveyED from "../cialis/survey-ed/survey-ed";
import SurveyQuestion, {
  SurveyQuestionProps,
} from "../common/survey/survey-question/survey-question";

export abstract class AbstractProductComponentResolver {
  productName: Product;

  constructor(productName: Product) {
    this.productName = productName;
  }

  abstract getViews(): string[];

  abstract getQuestions(): Question[];

  abstract getDoctorStop(): JSX.Element;

  abstract getSurveyWarning(): JSX.Element;

  getRootPageContents(reroute: (url: string) => void): JSX.Element {
    return (
      <>
        <div
          className="button survey"
          onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/survey`)}
        >
          <div className="text">{toTitleCase(this.productName)} HSA</div>
        </div>
      </>
    );
  }

  getProductWrapper(props: SurveyWrapperProps) {
    return <SurveyWrapper {...props} />;
  }

  getSurveyStop(props: SurveyStopProps) {
    return <SurveyStop {...props} />;
  }

  getSurveyLandingPage(props: SurveyLandingProps) {
    return <SurveyLanding {...props} />;
  }

  getSurveyLandingPageWeb(props: SurveyLandingProps) {
    return <SurveyLandingWeb {...props} />;
  }

  getSurveyWelcome(props: SurveyWelcomeProps) {
    return <SurveyWelcome {...props} />;
  }

  getSurveyCode(props: SurveyCodeProps) {
    return <SurveyCode {...props} />;
  }

  getSurveyQuestion(props: SurveyQuestionProps) {
    return <SurveyQuestion {...props} />;
  }

  getSurveyEd() {
    return <SurveyED />;
  }
}
