import { toTitleCase } from "../../../../utils/text-utils";
import { Product } from "../product-resolver";
import "./survey-header.scss";

export type SurveyHeaderProps = {
  product: Product;
};

/**
 * The component controlling the survey header
 * @returns {JSX.Element}
 */
const SurveyHeader = ({ product }: SurveyHeaderProps) => {
  return (
    <div className="survey-header-container">
      <div className="brand">
        {toTitleCase(product)}
        <span className="copyright">&reg;</span>
        <div className="text"> OTC</div>
      </div>
    </div>
  );
};

export default SurveyHeader;
