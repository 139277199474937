import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Location } from "../../services/geoapify-service";

/**
 * @property location {`Location`} - the currently location
 */
export interface LocationState {
  location?: Location;
}

/**
 * sets the state of the slice to be an empty object.
 */
const initialState: LocationState = {};

/**
 * allows the accountInfo to be called using just the name
 */
export interface LocationPayload {
  location: Location;
}

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const modelSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    /**
     * Overwrite the current model value, or create one in state if it doesn't exist.
     * @param state is the current state of the slice
     * @param action is the action to take place (create, update, delete)
     */
    setLocation(state, action: PayloadAction<LocationPayload>) {
      if (action?.payload?.location) state.location = action.payload.location;
    },
  },
});

export const { setLocation } = modelSlice.actions;

/**
 * Selector to retrieve the location state
 * @param state the application state
 * @returns ModelState
 */
export const modelStateSelector = (state: RootState): LocationState =>
  state.location;

export const locationStateSelector = (state: RootState): Location | undefined =>
  state.location?.location;

export default modelSlice;
