import { useEffect, useState } from "react";
import "./header.scss";
import { Register } from "../../model/pos/register";
import { useSelector } from "react-redux";
import { registerStateSelector } from "../../state/slices/model-slice";

/**
 * The header component for both the POS and Kiosk demos
 */
const Header = () => {
  const register: Register | undefined = useSelector(registerStateSelector);

  // Set the current time in state
  const [currentTime, setCurrentTime] = useState<string>(
    new Date().toTimeString().slice(0, 8)
  );

  // Set the current date in state
  const [currentDate, setCurrentDate] = useState<string>(
    new Date().toDateString()
  );

  // Set a interval to keep the time current
  useEffect(() => {
    const timeUpdater = setInterval(() => {
      setCurrentTime(new Date().toTimeString().slice(0, 8));
      setCurrentDate(new Date().toDateString());
    }, 100);

    return () => {
      clearInterval(timeUpdater);
    };
  }, []);

  return (
    <div className="header-container">
      <div className="title">Costco #1234</div>
      <div className="right-container">
        <div className="date">{currentTime}</div>
        <div className="time">{currentDate}</div>
        {register ? (
          <div className="terminal">{register.name}</div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Header;
