import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import "./web-link-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";

export const MeijerWebLinkBanner = () => {
  return (
    <div className="meijer-web-link-banner">
      <EuiFlexGroup
        className="links"
        alignItems="center"
        justifyContent="spaceAround"
        gutterSize="l"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiIcon type="menu" />
          <FakeLink text="All Departments" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Valentine's Day" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Grocery" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Fresh" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Household Essentials" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Pet Care" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Health Care" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Beauty & Personal Care" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <FakeLink text="Electronics" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
