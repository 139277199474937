import { Question } from "../../model/sanofi-otc/question";
import { aspirinQuestions, aspirinViews } from "../../utils/survey-constants";
import SurveyDoctorStop from "../common/survey/survey-doctor-stop/survey-doctor-stop";
import SurveyWarning from "../common/survey/survey-warning/survey-warning";
import { AbstractProductComponentResolver } from "./abstract-product-resolver";

export class SuperAspirinComponentResolver extends AbstractProductComponentResolver {
  constructor() {
    super("super aspirin");
  }

  getViews(): string[] {
    return aspirinViews;
  }

  getQuestions(): Question[] {
    return aspirinQuestions;
  }

  getDoctorStop(): JSX.Element {
    const statements = [
      "Taking over the counter Super Aspirin® is not safe for you and you should speak to a healthcare professional.",
      "Your medical treatment should be under a doctor's supervision.",
    ];

    return (
      <SurveyDoctorStop product={this.productName} statements={statements} />
    );
  }

  getSurveyWarning(): JSX.Element {
    const bulletStatements: string[] = [];
    const paragraphStatements = [
      "Super Aspirin® OTC is also not for persons with certain health conditions or taking certain medications. This survey will check for those conditions and medications.",
    ];
    const paragraphStatementsBold: string[] = [
      'By clicking "Continue" I confirm that I will answer the survey truthfully. Otherwise, it could lead to serious health consequences including blindness and heart attack.',
    ];

    return (
      <SurveyWarning
        product={this.productName}
        bulletStatements={bulletStatements}
        paragraphStatements={paragraphStatements}
        paragraphStatementsBold={paragraphStatementsBold}
      />
    );
  }
}
