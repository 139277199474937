import { EuiFlexItem, EuiFlexGroup, EuiButton } from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";

export const KrogerWebItemDetailOrdering = ({
  addToCart,
}: WebItemDetailOrderingProps) => {
  return (
    <EuiFlexItem className="kroger-item-ordering-wrapper">
      <EuiFlexGroup className="item-ordering" direction="column">
        <EuiButton onClick={addToCart}>Add to Cart</EuiButton>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};
