import "./survey-landing-web.scss";
import { EuiImage, EuiSpacer } from "@elastic/eui";
import { Product } from "../../../common/survey/product-resolver";
import { toTitleCase } from "../../../../utils/text-utils";
import { CompanyResolver } from "../../../../company/common/web/company-resolver";

/**
 * The props needed for the landing page to work
 * @prop {() => void} iterateContent - The method to navigate to another set of content
 */
export type SurveyLandingProps = {
  product: Product;
  iterateContent: () => void;
  onCancel: () => void;
};

const SurveyLandingWeb = ({
  product,
  iterateContent,
  onCancel,
}: SurveyLandingProps) => {
  return (
    <div className="survey-landing-container-web">
      <div className="top">
        <EuiImage
          className="brand-logo"
          src={CompanyResolver.getLogo()}
          alt="Logo"
        />
        <div className="title">
          {toTitleCase(product)}
          <div className="restricted">®</div>
        </div>
        <div className="sub-title">OTC</div>
        <div className="description">
          <div className="title">Health Survey Assessment</div>
          <div className="instructions">
            You will need to complete this survey each time you want to purchase
            to determine if {toTitleCase(product)}® OTC is appropriate for you
            based on your health history.
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="begin-button" onClick={() => iterateContent()}>
          Begin
        </div>
        <EuiSpacer size="s" />
        <div className="cancel-button" onClick={onCancel}>
          Cancel
        </div>
        <div className="copyright">&copy; 2021, 2023</div>
      </div>
    </div>
  );
};

export default SurveyLandingWeb;
