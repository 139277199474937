import { toTitleCase } from "../../../../utils/text-utils";
import { Product } from "../product-resolver";
import "./survey-warning.scss";

export type SurveyWarningProps = {
  product: Product;
  bulletStatements: string[];
  paragraphStatements: string[];
  paragraphStatementsBold: string[];
};

const SurveyWarning = ({
  product,
  bulletStatements,
  paragraphStatements,
  paragraphStatementsBold,
}: SurveyWarningProps) => {
  return (
    <div className="survey-warning-container">
      <div className="header">
        {toTitleCase(product)}® OTC is not for Everyone.
      </div>
      <p className="sub-header">{toTitleCase(product)}® OTC:</p>
      {bulletStatements.map((statement) => {
        return (
          <div key={statement} className="item-bullet">
            <div className="bullet" />
            {statement}
          </div>
        );
      })}
      {paragraphStatements.map((statement) => {
        return (
          <p key={statement} className="paragraph">
            {statement}
          </p>
        );
      })}
      {paragraphStatementsBold.map((statement) => {
        return (
          <p key={statement} className="paragraph bold">
            {statement}
          </p>
        );
      })}
    </div>
  );
};

export default SurveyWarning;
