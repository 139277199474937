import { UidKey } from "../common";

export type POSStore = UidKey & {
  active: boolean;
  address1: string;
  address2: string;
  city: string;
  name: string;
  regionUid: string;
  state: string;
  zip: string;
  extraConfig: unknown;
};

const _sample: Required<POSStore> = {
  uid: "string",
  active: false,
  address1: "string",
  address2: "string",
  city: "string",
  name: "string",
  regionUid: "string",
  state: "string",
  zip: "string",
  extraConfig: "json",
};
export const POSStoreFieldList = Object.keys(_sample);
