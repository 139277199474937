import "./need-survey-modal.scss";
import QRCode from "react-qr-code";

/**
 * The props needed for the NeedSurveyModal to work
 * @prop {() => void} onClose - When the modal is closed
 */
export type SurveyModalProps = {
  onClose: () => void;
};

const NeedSurveyModal = ({ onClose }: SurveyModalProps) => {
  return (
    <div className="need-survey-modal-container">
      <div className="inner-container">
        <div className="title">HSA Survey Required</div>
        <div className="message">
          A valid QR code from the HSA survey is required to purchase from this
          kiosk.
        </div>
        <div className="message">
          To complete the survey and get your QR code, use your phone to scan
          the code below, or go to the following address
        </div>
        <div className="message">
          https://sanofi-simulators.proviniti.io/survey
        </div>
        <div className="message">
          <QRCode
            size={200}
            value="https://sanofi-simulators.proviniti.io/survey"
          />
        </div>
        <div className="complete-survey" onClick={() => onClose()}>
          OK
        </div>
      </div>
    </div>
  );
};

export default NeedSurveyModal;
