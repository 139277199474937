import { v4 } from "uuid";
import { UidKey } from "../common";
import { ItemMaster } from "./item-master";

/**
 * This type is used as the constructor argument for the
 * {@link ReceiptLineItem} class. This allows us to pass
 * named arguments into the constuctor instead of relying
 * upon positional arguments.
 */
type IReceiptLineItem = UidKey & {
  receiptUid?: string;
  itemUid: string;
  quantity: number;
  active: boolean;
  extraConfig?: unknown;
};

export class ReceiptLineItem implements IReceiptLineItem {
  uid: string;
  receiptUid: string | undefined;
  itemUid: string;
  quantity: number;
  active: boolean;
  extraConfig?: unknown;

  constructor(arg: IReceiptLineItem) {
    this.uid = arg.uid;
    this.receiptUid = arg.receiptUid;
    this.itemUid = arg.itemUid;
    this.quantity = arg.quantity;
    this.active = arg.active;
  }

  /**
   * Create an instance of `ReceiptLineItem` from an {@link ItemMaster}
   * @param {ItemMaster} item - the item on which to base the ReceiptLineItem
   * @returns {ReceiptLineItem} a new instance of ReceiptLineItem
   */
  static fromItem(item: ItemMaster): ReceiptLineItem {
    return new ReceiptLineItem({
      uid: `temp__${v4()}`,
      itemUid: item.uid,
      quantity: 1,
      active: true,
    });
  }

  /**
   * Clone this instance
   * @returns {ReceiptLineItem} a new instance of ReceiptLineItem with the same values
   */
  clone(): ReceiptLineItem {
    return new ReceiptLineItem({ ...this, uid: `temp__${v4()}` });
  }
}
