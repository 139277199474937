import { EuiFlexGroup, EuiText } from "@elastic/eui";
import FakeLink from "../../../../components/fake-link/fake-link";

export const WalmartWebCartDeliveryInfo = () => {
  return (
    <EuiFlexGroup
      className="walmart-cart-delivery-info"
      direction="column"
      gutterSize="s"
    >
      <EuiText className="delivery-info-title">Pickup</EuiText>
      <EuiText className="delivery-info-details">
        Orders will be ready for pickup by the scheduled time.
      </EuiText>
      <FakeLink text="Delivery Details" />
    </EuiFlexGroup>
  );
};
