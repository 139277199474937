import CostcoLogo from "./costco-logo.svg";
import ShoppingCartLogoBlue from "./cart-blue.svg";
import ShoppingCartLogoWhite from "./cart-white.svg";
import ShoppingCartLogoBlack from "./cart-black.svg";
import BarCode from "./bar-code.svg";
import HomePageShoppingBanner1 from "./costco-homepage/top.avif";
import HomePageShoppingBanner2 from "./kroger-homepage/pizza.webp";
import HomePageShoppingBannerCVS from "./cvs-homepage/CVS_header.png";
import StopSign from "./stopsign.jpg";
import KrogerLogo from "./kroger-logo-white.svg";
import KrogerLogoBlue from "./kroger-logo-blue.svg";
import PickupLogo from "./pickup.svg";
import CvsLogo from "./cvs-homepage/cvs-logo.svg";
import CvsPickupIcon from "./cvs-homepage/cvs_pickup.png";

export {
  /** Costco */
  CostcoLogo,
  HomePageShoppingBanner1,
  /** Kroger */
  HomePageShoppingBanner2,
  ShoppingCartLogoBlue,
  ShoppingCartLogoWhite,
  KrogerLogo,
  KrogerLogoBlue,
  PickupLogo,
  /** Cvs */
  CvsLogo,
  HomePageShoppingBannerCVS,
  CvsPickupIcon,
  /** Other */
  BarCode,
  ShoppingCartLogoBlack,
  StopSign,
};
