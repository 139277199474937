import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
  EuiText,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import "./web-banner.scss";
import { ShoppingCartLogoWhite, WalmartLogo } from "../../../../assets/assets";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";

export const WalmartWebBanner = ({
  children,
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="walmart-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage
                className="walmart-logo"
                src={WalmartLogo}
                alt="Walmart logo"
              />
            </Link>
          </EuiFlexItem>
          {children} {/**this will be the location bar **/}
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search everything at Walmart online and in store"
              fullWidth
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem grow={false} className="right-side">
          <EuiFlexGroup
            gutterSize="l"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <EuiFlexGroup
              className="dark-background-on-hover"
              direction="row"
              gutterSize="s"
              alignItems="center"
            >
              <EuiIcon type="heart" size="m" />
              <EuiFlexGroup direction="column" gutterSize="none">
                <EuiText className="top-row-text">Reorder</EuiText>
                <EuiText className="bottom-row-text">My Items</EuiText>
              </EuiFlexGroup>
            </EuiFlexGroup>
            <EuiFlexGroup
              className="dark-background-on-hover"
              direction="row"
              gutterSize="s"
              alignItems="center"
            >
              <EuiIcon type="user" size="m" />
              <EuiFlexGroup direction="column" gutterSize="none">
                <EuiText className="top-row-text">Sign In</EuiText>
                <EuiText className="bottom-row-text">Account</EuiText>
              </EuiFlexGroup>
            </EuiFlexGroup>

            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart dark-background-on-hover"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <EuiFlexGroup
                  direction="column"
                  justifyContent="spaceAround"
                  alignItems="center"
                  gutterSize="xs"
                >
                  <img
                    className="shopping-cart-image"
                    src={ShoppingCartLogoWhite}
                    alt="shopping cart logo"
                  />
                  <EuiFlexItem className="top-row-text">$0.00</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                  {shoppingCartQuantity}
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
