import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ItemMaster } from "../../model/pos/item-master";
import List from "../../components/pos-content/list/list";
import DetailsPanel from "../../components/pos-content/details-panel/pos-details-panel";
import ScanButton from "../../components/pos-content/scan-button/scan-button";
import PayNowButton from "../../components/pos-content/pay-now-button/pay-now-button";
import "./pos-page.scss";
import CheckoutModal from "../../components/checkout-modal/checkout-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  posStoreStateSelector,
  registerStateSelector,
  setRegister,
  setStore,
} from "../../state/slices/model-slice";
import SurveyModal from "../../components/survey-modal/survey-modal";
import { QubesService } from "../../services/qubes-service";
import { ReceiptLineItem } from "../../model/pos/receipt-line-item";
import { PointOfSaleEvent } from "../../model/sanofi-otc/point-of-sale-event";
import { CompanyResolver } from "../../company/common/web/company-resolver";
import { toTitleCase } from "../../utils/text-utils";

/**
 * This is the page that controls all actions within the POS demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const POSPage = (): JSX.Element => {
  const dispatch = useDispatch();

  // Grab and set the items the POS is going to be playing with
  const [items, setItems] = useState<ItemMaster[]>([]);

  // The scanned items
  const [scannedItems, setScannedItems] = useState<ReceiptLineItem[]>([]);

  // The currently selected or most recently added item
  const [selectedItem, setSelectedItem] = useState<ReceiptLineItem | undefined>(
    undefined
  );

  // The state of the survey scan modal
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState<boolean>(false);

  // The state of the checkout workflow
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);

  // This map tracks any items that have an associated POSEvent that needs to be updated upon sale completion
  const itemToEventMap = useRef<Record<string, PointOfSaleEvent>>({});

  // The register we are running on
  const register = useSelector(registerStateSelector);
  // The store the register is in
  const store = useSelector(posStoreStateSelector);

  useEffect(() => {
    document.title = toTitleCase(
      CompanyResolver.get().getComponentResolver().companyName
    );
  }, []);

  /**
   * Load the data model
   */
  useEffect(() => {
    //load the items
    QubesService.loadItems()
      .then((resp) => {
        if (resp.data) {
          resp.data.sort((a, b) => {
            return a.weight - b.weight;
          });
          setItems(resp.data);
        } else {
          throw new Error("Unable to load items");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    //load the registers
    QubesService.loadRegisters()
      .then((resp) => {
        if (resp.data) {
          const register = resp.data.find((reg) =>
            reg.name.startsWith("Terminal")
          );

          dispatch(setRegister(register!));
        } else {
          throw new Error("Unable to load registers");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    //load the store
    QubesService.loadStores()
      .then((resp) => {
        if (resp.data && resp.data[0]) {
          dispatch(setStore(resp.data[0]));
        } else {
          throw new Error("Unable to load stores");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  // Make sure an item is always selected if there are any scanned items to select
  useEffect(() => {
    // Try to find the selected item in the scanned items list
    const foundItem = scannedItems.find(
      (item) => item.uid === selectedItem?.uid
    );

    // If the selected item is no longer in the scanned items list,
    // select the first item in the scanned items list
    if (!foundItem) setSelectedItem(scannedItems[scannedItems.length - 1]);
  }, [scannedItems, selectedItem?.uid]);

  /**
   * Grab a random item from the items list
   */
  const getRandomItem = () => {
    // Generate a random number
    let randNum: number = Math.random() * 90;

    // Organize the weights
    const weights: number[] = items.map(
      (
        (sum) => (item) =>
          sum + item.weight
      )(0)
    );

    // The random Item we grabbed
    let randomItem = items[weights.findIndex((el: number) => randNum <= el)];

    if (
      scannedItems.findIndex(
        (item) => item.itemUid === "LL6hU26NNhUyMXFcoTP0ou3vn64Naclc"
      ) > -1
    ) {
      const filteredItems = items.filter(
        (item) => item.uid !== "LL6hU26NNhUyMXFcoTP0ou3vn64Naclc"
      );

      randNum = Math.random() * 40;

      randomItem =
        filteredItems[weights.findIndex((el: number) => randNum <= el)];
    }

    // Resolved a receiptLineItem for this random item
    const receiptLineItem = ReceiptLineItem.fromItem(randomItem);

    //set it as the selected item and update the list of scanned items
    setSelectedItem(receiptLineItem);
    setScannedItems((scannedItems) => [...scannedItems, receiptLineItem]);

    //if this is an item that requires a survey, open the modal
    if (randomItem.sanofiOtcSku) {
      setIsSurveyModalOpen(true);
    }
  };

  const reset = useCallback(() => {
    setIsCheckingOut(false);
    setIsSurveyModalOpen(false);
    setSelectedItem(undefined);
    setScannedItems([]);
    itemToEventMap.current = {};
  }, [
    setIsCheckingOut,
    setIsSurveyModalOpen,
    setSelectedItem,
    setScannedItems,
  ]);

  /**
   * increase the quantity of the selected item by creating another instance of
   * the ReceiptLineitem
   */
  const increaseQuantity = () => {
    if (selectedItem) {
      const clone = selectedItem.clone();

      const item = items.find((i) => i.uid === selectedItem?.itemUid);

      setScannedItems((scannedItems) => [...scannedItems, clone]);

      setSelectedItem(clone);
      if (item?.sanofiOtcSku) {
        //if we are adding a surveyable sku item, we need to show the survey again
        setIsSurveyModalOpen(true);
      }
    }
  };

  /**
   * decrease the quantity of the selected item by removing this instance of
   * the ReceiptLineitem from the scanned items
   */
  const decreaseQuantity = useCallback(() => {
    if (selectedItem) {
      setScannedItems((scannedItems) =>
        // all the existing scanned items less the one that is changing
        scannedItems.filter((si) => si.uid !== selectedItem?.uid)
      );

      // unset selected item and remove it from the scanned items list
      if (scannedItems.length > 0) {
        setSelectedItem(scannedItems[scannedItems.length - 1]);
      } else {
        setSelectedItem(undefined);
      }
      // since we're removing the item, it may have an associated POSEvent
      delete itemToEventMap.current[selectedItem.uid];
    }
  }, [scannedItems, selectedItem]);

  /**
   * Handle rendering the correct modal, based on local state
   */
  const renderCorrectModal = useMemo(() => {
    /**
     * Handle the survey scan
     * @param {boolean} accepted - Whether or not the scan was accepted
     */
    const handleSurveyScan = (posEvent?: PointOfSaleEvent) => {
      //if there isn't a selectedItem, what do we do??? There's no way to recover and deal with the modal
      if (!selectedItem) return;

      setIsSurveyModalOpen(false);
      if (!posEvent) {
        decreaseQuantity();
      } else {
        itemToEventMap.current[selectedItem.uid] = posEvent;
      }
    };

    const processEntry = async (entry: [string, PointOfSaleEvent]) => {
      const rcl = scannedItems.find((si) => si.uid === entry[0]);
      const item = items.find((i) => i.uid === rcl?.itemUid);
      console.info(`Update the POSEvent records to "Sold" | ${item?.name}`);
      entry[1].upc = item?.upc;
      entry[1].quantity = 1;
      entry[1].status = "Sold";
      entry[1].saleCompleted = new Date().toISOString();
      return QubesService.updatePOSEvent(entry[1]);
    };

    if (isSurveyModalOpen) {
      if (!register || !store) {
        console.error(`Register and Store must be available!`);
        return;
      }
      return (
        <SurveyModal
          handleSubmitSurvey={handleSurveyScan}
          pos
          registerId={register.uid}
          storeId={store.uid}
        />
      );
    } else if (isCheckingOut) {
      return (
        <CheckoutModal
          kiosk={false}
          receiptLineItems={scannedItems}
          items={items}
          register={register!}
          onCancel={() => setIsCheckingOut(false)}
          onCompleted={async () => {
            //for every receiptLineItem with an associated POSEvent, need to resolve those
            // NOTE: This should really be done transactionally with the checkout. We would
            // not want to have a case where the checkout succeeded but the POSEvent update
            // had failed
            try {
              const updates = Object.entries(itemToEventMap.current).map(
                processEntry
              );

              await Promise.all(updates);
            } catch (err) {
              console.error("Error update the pos events", err);
            }

            reset();
          }}
        />
      );
    }
  }, [
    isSurveyModalOpen,
    isCheckingOut,
    selectedItem,
    decreaseQuantity,
    register,
    store,
    scannedItems,
    items,
    reset,
  ]);

  return (
    <div className="pos-page-container">
      <div className="pos-content">
        <div className="left-column">
          <List
            receiptLineItems={scannedItems}
            items={items}
            onSelect={setSelectedItem}
            selectedItem={selectedItem}
          />
          <div className="bottom-bar" />
        </div>
        <div className="right-column">
          <DetailsPanel
            selectedReceiptLineItem={selectedItem}
            selectedItem={items.find((i) => i.uid === selectedItem?.itemUid)}
            increaseQuantity={increaseQuantity}
            decreaseQuantity={decreaseQuantity}
          />
          <ScanButton getRandomItem={getRandomItem} />
          <PayNowButton checkout={() => setIsCheckingOut(true)} />
        </div>
      </div>
      {renderCorrectModal}
    </div>
  );
};

export default POSPage;
