import { UidKey } from "../common";

export type Register = UidKey & {
  name: string;
  active: boolean;
  extraConfig: unknown;
};

const _sample: Required<Register> = {
  uid: "string",
  active: false,
  name: "string",
  extraConfig: "json",
};
export const RegisterFieldList = Object.keys(_sample);
