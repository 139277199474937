import { DemoConfig, DemoConfigProduct } from "../../../model/pos/demo-config";
import { AbstractProductComponentResolver } from "../../resolver/abstract-product-resolver";
import { CialisComponentResolver } from "../../resolver/cialis-component-resolver";
import { SuperAspirinComponentResolver } from "../../resolver/super-aspirin-component-resolver";

export type Product = "cialis" | "super aspirin";
export type ProductList = [Product, string];

export class ProductResolver {
  private static readonly _instance = new ProductResolver();

  static get(): ProductResolver {
    return this._instance;
  }

  private componentResolver: AbstractProductComponentResolver | undefined;
  private demoConfig: DemoConfig<DemoConfigProduct> | undefined;

  private constructor() {}

  getProduct(): Product {
    return this.componentResolver?.productName!;
  }

  getProductResolver(): AbstractProductComponentResolver {
    return this.componentResolver!;
  }

  getUserDemoPreference(): DemoConfig<DemoConfigProduct> | undefined {
    return this.demoConfig;
  }

  setUserDemoPreference(dc: DemoConfig<DemoConfigProduct>) {
    this.demoConfig = dc;
    switch (dc?.value?.activeProduct) {
      case "cialis":
        this.componentResolver = new CialisComponentResolver();
        break;
      case "super aspirin":
        this.componentResolver = new SuperAspirinComponentResolver();
        break;
      default:
        console.error(
          `Do not know how to handle product ${dc?.value?.activeProduct}`
        );
    }
    console.info(`Demoing ${dc?.value?.activeProduct}`);
  }

  // subdomain: "cialis", // window.location.hostname?.split(".")?.[0] ?? "cialis",
}
