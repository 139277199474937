import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import HomePageSellItem from "../home-page-sell-item/home-page-sell-item";
import "./home-page-item-row.scss";
import { ItemMaster } from "../../model/pos/item-master";

export type HomePageItemRowProps = {
  items: ItemMaster[];
};

/**
 * A component that shows a list of items in a single row
 */
const HomePageItemRow = ({ items }: HomePageItemRowProps) => {
  return (
    <EuiFlexItem className="home-page-item-row">
      <EuiFlexGroup
        className="sale-row"
        direction="row"
        justifyContent="spaceEvenly"
        alignItems="flexStart"
      >
        {items.map((item) => {
          return (
            <HomePageSellItem
              key={item.uid}
              item={item}
              itemsDatabase={items}
            />
          );
        })}
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};

export default HomePageItemRow;
