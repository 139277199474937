import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
  EuiText,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import "./web-banner.scss";
import {
  SamsclubLogo,
  SamsReorderIcon,
  ShoppingCartLogoWhite,
} from "../../../../assets/assets";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";

export const SamsclubWebBanner = ({
  children,
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="samsclub-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexGroup
          alignItems="center"
          gutterSize="l"
          justifyContent="flexStart"
        >
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage
                className="walmart-logo"
                src={SamsclubLogo}
                alt="Walmart logo"
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexGroup
            className="left-nav-button"
            direction="row"
            gutterSize="s"
            alignItems="center"
          >
            <EuiText className="left-nav-button-text">Departments</EuiText>
            <EuiIcon type="arrowDown" />
          </EuiFlexGroup>
          <EuiFlexGroup
            className="left-nav-button"
            direction="row"
            gutterSize="s"
            alignItems="center"
          >
            <EuiText className="left-nav-button-text">Sam's Services</EuiText>
            <EuiIcon type="arrowDown" />
          </EuiFlexGroup>
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search"
              fullWidth
            />
          </EuiFlexItem>
          <EuiFlexGroup
            className="right-nav-button"
            direction="column"
            gutterSize="none"
            alignItems="center"
          >
            <img
              className="shopping-cart-image"
              src={SamsReorderIcon}
              alt="shopping cart logo"
            />

            <EuiText className="top-row-text">Reorder</EuiText>
          </EuiFlexGroup>
          <EuiFlexGroup
            className="right-nav-button"
            direction="column"
            gutterSize="none"
            alignItems="center"
          >
            <EuiFlexGroup direction="row" gutterSize="none" alignItems="center">
              <EuiIcon type="user" size="l" />
              <EuiIcon type="arrowDown" />
            </EuiFlexGroup>
            <EuiText className="top-row-text">Sign in</EuiText>
          </EuiFlexGroup>
        </EuiFlexGroup>
        <EuiFlexItem grow={false} className="right-side">
          <EuiFlexGroup
            gutterSize="l"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart dark-background-on-hover"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <EuiFlexGroup
                  direction="column"
                  justifyContent="spaceAround"
                  alignItems="center"
                  gutterSize="xs"
                >
                  <img
                    className="shopping-cart-image"
                    src={ShoppingCartLogoWhite}
                    alt="shopping cart logo"
                  />
                  <EuiFlexItem className="top-row-text">Cart</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                  {shoppingCartQuantity}
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
