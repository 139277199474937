import { useEffect, useState } from "react";
import "./setup-page.scss";
import { QubesService } from "../../services/qubes-service";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiSelectOption,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import {
  Company,
  CompanyList,
  CompanyResolver,
} from "../../company/common/web/company-resolver";
import {
  Product,
  ProductList,
  ProductResolver,
} from "../../product/common/survey/product-resolver";
import { userSelector } from "../../state/slices/user-slice";
import { useSelector } from "react-redux";
import {
  DemoConfig,
  DemoConfigCompany,
  DemoConfigProduct,
} from "../../model/pos/demo-config";

const IN_DEV_MODE = false;

/**
 * The self serve Kiosk demo page
 * @returns {JSX.Element}
 */
const SetupPage = (): JSX.Element => {
  const [companyOptions, setCompanyOptions] = useState<EuiSelectOption[]>();
  const [selectedCompany, setSelectedCompany] = useState<string>(
    CompanyResolver.get().getCompany()
  );
  const companySelectId = useGeneratedHtmlId({ prefix: "companySelect" });

  const [productOptions, setProductOptions] = useState<EuiSelectOption[]>();
  const [selectedProduct, setSelectedProduct] = useState<string>(
    ProductResolver.get().getProduct()
  );
  const productSelectId = useGeneratedHtmlId({ prefix: "productSelect" });

  const user = useSelector(userSelector);

  const onCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!user) {
      console.warn("User has not been resolved");
      return;
    }
    const c: Company = e.target.value as Company;
    if (!c) {
      console.debug(
        "Removed selection of company, but we don't allow that so no further action will be taken"
      );
      return;
    }
    setSelectedCompany(c);
    const dc: DemoConfig<DemoConfigCompany> = {
      uid: CompanyResolver.get().getUserDemoPreference()?.uid!,
      usergroupuid: user.uid!,
      property: "company",
      value: {
        activeCompany: c,
      },
    };
    QubesService.upsertDemoPreference<DemoConfigCompany>(dc).then((result) => {
      if (result.success && result.numberOfRowsUpdated === 1) {
        if (!dc.uid && (result.keys as any)?.uid) {
          dc.uid = (result.keys as any).uid;
        }

        CompanyResolver.get().setUserDemoPreference(dc);
      } else {
        alert("Error: Could not apply the Company preference");
      }
    });
  };

  const onProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!user) {
      console.warn("User has not been resolved");
      return;
    }
    const p: Product = e.target.value as Product;
    if (!p) {
      console.debug(
        "Removed selection of product, but we don't allow that so no further action will be taken"
      );
      return;
    }
    setSelectedProduct(p);
    const dc: DemoConfig<DemoConfigProduct> = {
      uid: ProductResolver.get().getUserDemoPreference()?.uid!,
      usergroupuid: user.uid!,
      property: "product",
      value: {
        activeProduct: p,
      },
    };
    QubesService.upsertDemoPreference<DemoConfigProduct>(dc).then((result) => {
      if (result.success && result.numberOfRowsUpdated === 1) {
        if (!dc.uid && (result.keys as any)?.uid) {
          dc.uid = (result.keys as any).uid;
        }

        ProductResolver.get().setUserDemoPreference(dc);
      } else {
        alert("Error: Could not apply the Product preference");
      }
    });
  };

  /**
   * Load the data model
   */
  useEffect(() => {
    QubesService.loadAvailableCompanies()
      .then((resp) => {
        if (resp?.data?.[0]) {
          const companies: CompanyList[] = JSON.parse(
            resp.data[0].value as string
          );
          companies.unshift(["" as Company, "", false]);
          setCompanyOptions(
            companies
              .filter((c) => IN_DEV_MODE || c[2])
              .map((c: CompanyList) => {
                return { value: c[0], text: c[1] };
              })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });

    QubesService.loadAvailableProducts()
      .then((resp) => {
        if (resp?.data?.[0]) {
          const products: ProductList[] = JSON.parse(
            resp.data[0].value as string
          );
          products.unshift(["" as Product, ""]);
          setProductOptions(
            products.map((p: ProductList) => {
              return { value: p[0], text: p[1] };
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [setCompanyOptions, setProductOptions]);

  return (
    <div className="setup-page-container">
      <EuiFlexItem grow={false}>
        <h1>SETUP PAGE</h1>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup className="form-row" alignItems="center">
          <EuiFlexItem>Company</EuiFlexItem>
          <EuiFlexItem>
            <EuiSelect
              id={companySelectId}
              options={companyOptions}
              value={selectedCompany}
              onChange={(e) => onCompanyChange(e)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup className="form-row" alignItems="center">
          <EuiFlexItem>Product</EuiFlexItem>
          <EuiFlexItem>
            <EuiSelect
              id={productSelectId}
              options={productOptions}
              value={selectedProduct}
              onChange={(e) => onProductChange(e)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {selectedCompany && selectedProduct ? (
          <a href="/"> {"<<<<<"} Back To Home</a>
        ) : (
          <EuiText style={{ marginLeft: "20px" }}>
            Please select Company and Product to run the demo.
          </EuiText>
        )}
      </EuiFlexItem>
    </div>
  );
};

export default SetupPage;
