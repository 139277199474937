import { Link, useNavigate } from "react-router-dom";
import "./home-page.scss";
import { useCallback, useEffect } from "react";
import { CompanyResolver } from "../../company/common/web/company-resolver";
import { ProductResolver } from "../../product/common/survey/product-resolver";
import { toTitleCase } from "../../utils/text-utils";
import { getProductThemeOverrides } from "../survey-web/theme-provider";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = toTitleCase(
      CompanyResolver.get().getComponentResolver().companyName
    );
  }, []);

  const cleanPath = useCallback((path: string) => {
    if (path.startsWith("//")) return path.substring(1);
    else return path;
  }, []);
  /**
   * Navigate to a different page via React Router
   */
  const reroute = (suffix: string) => {
    navigate(cleanPath(suffix));
  };

  return (
    <div
      className="_home_page"
      style={
        getProductThemeOverrides(ProductResolver.get().getProduct()) as any
      }
    >
      {ProductResolver.get().getProductResolver().getRootPageContents(reroute)}
      {CompanyResolver.get()
        .getComponentResolver()
        .getRootPageContents(reroute)}
      <Link to="/setup">Setup</Link>
    </div>
  );
};

export default HomePage;
