import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
  EuiText,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import "./web-banner.scss";
import {
  MeijerCartIcon,
  MeijerLogo,
  MeijerPickupIcon,
  MeijerUserIcon,
} from "../../../../assets/assets";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";

export const MeijerWebBanner = ({
  children,
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="meijer-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage
                className="meijer-logo"
                src={MeijerLogo}
                alt="Meijer logo"
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search"
              fullWidth
            />
          </EuiFlexItem>
          {/*  this will be the location bar  */}
        </EuiFlexGroup>
        <EuiFlexItem grow={false} className="right-side">
          <EuiFlexGroup
            gutterSize="l"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <EuiFlexGroup
              direction="row"
              gutterSize="s"
              alignItems="center"
              className="hover-button account-button"
            >
              <img className="user-icon" src={MeijerUserIcon} alt="user icon" />
              <EuiText className="text">Sign In</EuiText>
              <EuiFlexItem grow={false}>
                <EuiIcon
                  className="arrow-down"
                  type="arrowDown"
                  size="m"
                  style={{ color: "#31597d" }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup
              direction="row"
              gutterSize="s"
              alignItems="center"
              className="hover-button pickup-button"
            >
              <img
                className="user-icon"
                src={MeijerPickupIcon}
                alt="user icon"
              />
              <EuiText className="text">Pickup</EuiText>
              <EuiFlexItem grow={false}>
                <EuiIcon
                  className="arrow-down"
                  type="arrowDown"
                  size="m"
                  style={{ color: "#31597d" }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>

            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart hover-button"
                alignItems="center"
                gutterSize="none"
              >
                <EuiFlexGroup
                  direction="column"
                  justifyContent="spaceAround"
                  alignItems="center"
                  gutterSize="xs"
                >
                  <img
                    className="shopping-cart-image"
                    src={MeijerCartIcon}
                    alt="shopping cart logo"
                  />
                </EuiFlexGroup>
                {shoppingCartQuantity > 0 && (
                  <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                    {shoppingCartQuantity}
                  </EuiFlexItem>
                )}
                <EuiText className="text">Cart</EuiText>
                <EuiFlexItem grow={false}>
                  <EuiIcon
                    className="arrow-down"
                    type="arrowDown"
                    size="m"
                    style={{ color: "#31597d" }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
