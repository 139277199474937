import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiButton,
  EuiSpacer,
  EuiSelect,
  EuiIcon,
  EuiHorizontalRule,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";
import {
  SamsclubDeliveryIcon,
  SamsclubPickupIcon,
  SamsclubPlusIcon,
  SamsclubShippingIcon,
} from "../../../../assets/assets";

export const SamsclubWebItemDetailOrdering = ({
  item,
  itemCount,
  updateItemCount,
  increment,
  decrement,
  addToCart,
}: WebItemDetailOrderingProps) => {
  return (
    <>
      <EuiFlexItem className="samsclub-item-ordering-wrapper">
        <EuiFlexGroup
          className="purchase-type-box-row"
          direction="row"
          alignItems="center"
          justifyContent="flexStart"
          gutterSize="s"
        >
          <EuiFlexGroup
            className="purchase-type-box selected"
            direction="column"
            alignItems="baseline"
            justifyContent="flexStart"
            gutterSize="none"
          >
            <EuiFlexGroup direction="row" gutterSize="xs" alignItems="center">
              <img src={SamsclubShippingIcon} alt="shipping" />
              <EuiText className="title">Shipping</EuiText>
            </EuiFlexGroup>
            <EuiText className="timing">Check ZIP Code</EuiText>
          </EuiFlexGroup>
          <EuiFlexGroup
            className="purchase-type-box"
            direction="column"
            alignItems="baseline"
            justifyContent="flexStart"
            gutterSize="none"
          >
            <EuiFlexGroup direction="row" gutterSize="xs" alignItems="center">
              <img src={SamsclubPickupIcon} alt="shipping" />
              <EuiText className="title">Pickup</EuiText>
            </EuiFlexGroup>
            <EuiText className="timing">As soon as 11 am</EuiText>
          </EuiFlexGroup>
          <EuiFlexGroup
            className="purchase-type-box"
            direction="column"
            alignItems="baseline"
            justifyContent="flexStart"
            gutterSize="none"
          >
            <EuiFlexGroup direction="row" gutterSize="xs" alignItems="center">
              <img src={SamsclubDeliveryIcon} alt="shipping" />
              <EuiText className="title">Delivery</EuiText>
            </EuiFlexGroup>
            <EuiText className="timing">Check an address</EuiText>
          </EuiFlexGroup>
        </EuiFlexGroup>
        <EuiSpacer size="m" />
        <EuiText style={{ fontWeight: "700", fontSize: "16px" }}>
          Shipping
        </EuiText>
        <div style={{ fontSize: "14px" }}>
          Free for{" "}
          <span
            style={{ color: "#0067a0", fontWeight: "700", fontStyle: "italic" }}
          >
            &nbsp;Plus&nbsp;
          </span>{" "}
          on eligible orders over $50
        </div>
        <EuiSpacer size="l" />
        <EuiFlexGroup
          gutterSize="xs"
          alignItems="center"
          style={{ color: "#0067a0" }}
        >
          <EuiText style={{ fontWeight: "700" }}>Check ZIP code </EuiText>
          <EuiIcon type="arrowRight" />
        </EuiFlexGroup>
        <EuiHorizontalRule margin="l" />
        <EuiFlexGroup direction="row">
          <EuiSelect options={[{ value: 1, text: "1" }]}></EuiSelect>
          <EuiButton onClick={addToCart}>Add to Cart</EuiButton>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiSpacer size="s" />
      <EuiFlexGroup
        className="samsclub-item-ordering-wrapper-plus-member"
        direction="column"
        gutterSize="xs"
        alignItems="flexStart"
      >
        <EuiFlexGroup
          direction="row"
          gutterSize="s"
          alignItems="center"
          style={{ color: "#0067a0" }}
        >
          <img src={SamsclubPlusIcon} alt="join" />
          <EuiText style={{ fontWeight: "700", fontSize: "16px" }}>
            Become a{" "}
            <span
              style={{
                fontStyle: "italic",
              }}
            >
              &nbsp;Plus&nbsp;
            </span>{" "}
            member
          </EuiText>
        </EuiFlexGroup>
        <EuiText style={{ fontSize: "15px" }}>
          Free shipping on eligible orders over $50
        </EuiText>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiFlexGroup
        key={"item-further-details"}
        className="samsclub-item-further-details"
        direction="column"
        alignItems="center"
      >
        <EuiText className="header">Highlights</EuiText>
        <EuiFlexItem className="product-details" grow={false}>
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiSpacer size="xs" />
            <EuiFlexItem>
              <ul>
                {item.primaryFeatures?.map((feature) => {
                  return <li key={feature}>{feature}</li>;
                })}
              </ul>
            </EuiFlexItem>
            <EuiSpacer size="l" />
            <EuiFlexItem
              style={{
                color: "#0067a0",
                fontWeight: "700",
                marginLeft: "20px",
              }}
            >
              Read more
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
