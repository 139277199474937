export const toTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

// Format the price above to USD using the locale, style, and currency.
const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const toCurrency = (num: number): string => {
  return USDollar.format(num);
};
