import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiButton,
  EuiIcon,
  EuiImage,
  EuiText,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";
import { MeijerInfoIcon } from "../../../../assets/assets";

export const MeijerWebItemDetailOrdering = ({
  addToCart,
}: WebItemDetailOrderingProps) => {
  return (
    <EuiFlexItem className="meijer-item-ordering-wrapper">
      <EuiFlexGroup className="item-ordering" direction="column">
        <EuiButton onClick={addToCart}>
          <EuiIcon type="plus" />
          Add to Cart
        </EuiButton>
      </EuiFlexGroup>
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiImage src={MeijerInfoIcon} alt="info icon" />
        <EuiText>
          Applied promotions and discounted prices will be shown in cart.
        </EuiText>
      </EuiFlexGroup>
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiImage src={MeijerInfoIcon} alt="info icon" />
        <EuiText>
          Final cost determined at checkout and may vary from estimate.
        </EuiText>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};
