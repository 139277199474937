import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { QubesUser } from "../../qubes-client/model/model";

/**
 * @property user {`User`} - the currently logged in user, or undefined if no user logged in
 */
export interface UserState {
  user?: QubesUser;
}

/**
 * sets the state of the slice to be an empty object.
 */
const initialState: UserState = {};

/**
 * allows the accountInfo to be called using just the name
 */
export interface UserPayload {
  user: QubesUser;
}

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    /**
     * Overwrite the current user value, or create one in state if it doesn't exist.
     * @param state is the current state of the slice
     * @param action is the action to take place (create, update, delete)
     */
    setUser(state, action: PayloadAction<UserPayload>) {
      if (action?.payload?.user) {
        state.user = action.payload.user;
        if (action.payload.user.sess)
          //the sess property may not always be present, such as in the case of using the /api/ping
          window.sessionStorage.setItem("X-SESS", action.payload.user.sess);
      } else {
        state.user = undefined;
        window.sessionStorage.removeItem("X-SESS");
      }
    },
  },
});

export const { setUser } = userSlice.actions;

/**
 * Selector to retrieve the model state
 * @param state the application state
 * @returns ModelState
 */
export const userStateSelector = (state: RootState): UserState => state.user;

export const userSelector = (state: RootState): QubesUser | undefined =>
  state.user.user;

export default userSlice;
