import { EuiFlexGroup, EuiFlexItem, EuiImage } from "@elastic/eui";
import "./web-link-banner2.scss";
import FakeLink from "../../../../components/fake-link/fake-link";
import {
  MeijerCouponsIcon,
  MeijerMperksIcon,
  MeijerPharmacyIcon,
  MeijerPhotoIcon,
  MeijerServicesIcon,
  MeijerWeeklyAdIcon,
} from "../../../../assets/assets";

export const MeijerWebLinkBanner2 = () => {
  return (
    <div className="meijer-web-link-banner2">
      <EuiFlexGroup
        className="links"
        alignItems="center"
        justifyContent="spaceAround"
        gutterSize="xl"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiImage
            className="mperks-logo"
            src={MeijerMperksIcon}
            alt="MPerks logo"
          />
          <FakeLink text="mPerks" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiImage
            className="mperks-logo"
            src={MeijerPharmacyIcon}
            alt="Pharmacy logo"
          />
          <FakeLink text="Pharmacy" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiImage
            className="mperks-logo"
            src={MeijerCouponsIcon}
            alt="Coupon logo"
          />
          <FakeLink text="Coupons" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiImage
            className="mperks-logo"
            src={MeijerWeeklyAdIcon}
            alt="Weekly Ad logo"
          />
          <FakeLink text="Weekly Ad" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiImage
            className="mperks-logo"
            src={MeijerServicesIcon}
            alt="Services logo"
          />
          <FakeLink text="Services" />
        </EuiFlexItem>
        <EuiFlexItem className="meijer-link" grow={false}>
          <EuiImage
            className="mperks-logo"
            src={MeijerPhotoIcon}
            alt="Photo logo"
          />
          <FakeLink text="Meijer Photo" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
