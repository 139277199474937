import { EuiFlexGroup, EuiIcon, EuiSpacer, EuiText } from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const MeijerWebItemDetailPricing = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      className="meijer-item-details--price"
      direction="row"
      justifyContent="flexStart"
      gutterSize="none"
      alignItems="baseline"
    >
      <EuiText className="price">${item?.price}</EuiText>
      <EuiFlexGroup
        className="pricing-right-side"
        direction="column"
        alignItems="flexStart"
        style={{ flexGrow: 0, marginLeft: "250px" }}
        gutterSize="xs"
      >
        <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
          <EuiIcon
            type="checkInCircleFilled"
            style={{ color: "#0c60a5", marginLeft: "-24px" }}
          />
          <EuiText style={{ fontWeight: "700" }}>In stock at Alpine</EuiText>
        </EuiFlexGroup>
        <EuiText size="xs" className="check-nearby">
          Check nearby stores
        </EuiText>
        <EuiSpacer size="s" />
        <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
          <EuiText style={{ fontWeight: "600" }}>Aisle B | 16 </EuiText>
          <EuiText style={{ marginLeft: "8px" }}>Section 36</EuiText>
          <EuiIcon
            size="l"
            type="iInCircle"
            style={{ color: "#0c60a5", marginLeft: "8px" }}
          />
        </EuiFlexGroup>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
