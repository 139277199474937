import { EuiFlexGroup, EuiText } from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";
import { toCurrency } from "../../../../utils/text-utils";

export const CostcoWebItemDetailPricing = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      className="costco-item-details--price"
      direction="row"
      justifyContent="flexStart"
    >
      <EuiText className="item-details--price-values">Your Price</EuiText>
      <EuiText className="item-details--price-values">
        {toCurrency(item.price)}
      </EuiText>
    </EuiFlexGroup>
  );
};
