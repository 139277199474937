/**
 * Replacer function to deal with ES6 maps when calling JSON.stringify. Must be used
 * in conjunction with `reviver`
 */
export function replacer(key: any, value: any[]) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

/**
 * Reviver function to deal with ES6 maps when calling JSON.parse. Must be used
 * in conjunction with `replacer`
 */
export function reviver(key: any, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}
