import { useMemo, useRef, useState } from "react";
import "./survey-modal.scss";
import { QubesService } from "../../services/qubes-service";
import {
  POSEventStatus,
  PointOfSaleEvent,
} from "../../model/sanofi-otc/point-of-sale-event";

/**
 * The props needed for the SurveyModal to work
 * @prop {(boolean) => void} handleSubmitSurvey - Update the value of the acceptedQR parent state
 * @prop {boolean} pos - The POS specific verbiage
 * @prop {string} registerId - The id of this register
 * @prop {string} storeId - The id of this store
 */
export type SurveyModalProps = {
  handleSubmitSurvey: (posEvent?: PointOfSaleEvent) => void;
  pos?: boolean;
  registerId: string;
  storeId: string;
};

export type CodeStatus = {
  status: POSEventStatus;
  reason: string;
  message: string;
};

const SurveyModal = ({
  handleSubmitSurvey,
  pos,
  registerId,
  storeId,
}: SurveyModalProps) => {
  // The Survey code being entered by the user
  const [surveyCode, setSurveyCode] = useState<string>("");

  // Whether or not the Survey Code has been submitted
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [codeStatus, setCodeStatus] = useState<CodeStatus>();

  const posEventRef = useRef<PointOfSaleEvent>();

  /**
   * Render the correct phase of the modal
   */
  const renderPhase = useMemo(() => {
    /**
     * Handle the submission of the Survey Code
     */
    const handleSurveySubmission = async () => {
      if (!surveyCode) return;

      let newCodeStatus: CodeStatus;

      let correlationCodeUid: string | undefined = undefined;
      try {
        // See if this QR code exists in POSCorrelationCode
        const qrCodeResult = await QubesService.loadQRCode(surveyCode);

        if (qrCodeResult.data) {
          const qrCodes = qrCodeResult.data;
          if (qrCodes.length > 1) {
            console.error(
              `Multiple records with the same QR Code found | ${qrCodes
                .map((qrc) => qrc.uid)
                ?.join(",")}`
            );
            throw new Error("Multiple records with the same QR code found");
          } else if (qrCodes.length === 0) {
            console.error(`No records with the QR Code found | ${surveyCode}`);
            newCodeStatus = {
              status: "Not Found",
              reason: "Code was not found",
              message:
                "A valid QR code must be provided to complete this purchase",
            };
          } else {
            //we have a single QR code record
            const qr = qrCodes[0];

            // check the expiration - append the EoD time with no timezone
            // so that the created date is EoD for the current browser's time
            // zone
            const expirationDate: Date = new Date(
              `${qr.expirationDate}T23:59:59.999`
            );
            correlationCodeUid = qr.uid;
            if (new Date() > expirationDate || !qr.active) {
              newCodeStatus = {
                status: "Denied",
                reason: "QR Code expired",
                message: "Codes must be used within 14 days of creation",
              };
            } else {
              // we have a valid, unexpired code. Check if the code has already been used
              const posEvents = await QubesService.loadPosEventResult(
                surveyCode
              );
              if (
                posEvents.data &&
                posEvents.data.length > 0 &&
                posEvents.data.some((posEvent) => posEvent.saleCompleted) // has a sale been completed?
              ) {
                newCodeStatus = {
                  status: "Denied",
                  reason: "QR Code already used",
                  message: "A new survey must completed to purchase this item",
                };
              } else {
                newCodeStatus = {
                  status: "Granted",
                  reason: "",
                  message: "",
                };
              }
            }
          }
        } else {
          throw new Error("QRCode lookup result had no `data` element");
        }
      } catch (err) {
        newCodeStatus = {
          status: "Error",
          reason: "Unexpected error has occurred",
          message: "An unexpected error has occurred. Please try again.",
        };
        console.error(err);
      }

      setCodeStatus(newCodeStatus);

      try {
        console.info(`Insert POSEvent`);
        const posEvent = new PointOfSaleEvent({
          uid: "",
          initiated: new Date().toISOString(),
          quantity: 0,
          rawCorrelationCode: surveyCode,
          correlationCodeUid,
          registerId,
          status: newCodeStatus.status,
          store: storeId,
          active: true,
        });
        const event = await QubesService.insertPOSEvent(posEvent);
        if (
          !event.success ||
          (event.numberOfRowsUpdated ?? 0) < 1 ||
          !event.keys
        ) {
          console.error(`Failed to insert the POSEvent record`);
        } else {
          posEvent.uid = event.keys?.uid;
          posEventRef.current = posEvent;
        }
      } catch (err) {
        console.error("Error inserting the POSEvent", err);
      }
      setIsSubmitted(true);
    };

    if (!isSubmitted) {
      return (
        <>
          <label className="text" htmlFor="survey-code">
            Survey QR Code:{" "}
          </label>
          <input
            type="text"
            id="survey-code"
            className="survey-code-input"
            value={surveyCode}
            onChange={(e) => setSurveyCode(e.target.value)}
          />
          <div className="complete-survey" onClick={handleSurveySubmission}>
            Submit Survey QR Code
          </div>
          <div
            className="complete-survey remove"
            onClick={() => handleSubmitSurvey()}
          >
            Cancel
          </div>
        </>
      );
    } else {
      switch (codeStatus?.status) {
        case "Granted":
          return (
            <>
              <div className="message">
                Your survey code has been accepted. Thank you.
              </div>
              <div
                className="complete-survey"
                onClick={() => handleSubmitSurvey(posEventRef.current)}
              >
                {pos ? "Continue Scanning Items" : "Continue Checking Out"}
              </div>
            </>
          );
        default:
          return (
            <>
              <div className="message">
                <b>DENIED</b>: {codeStatus?.reason}
              </div>
              <div className="message">{codeStatus?.message}</div>
              <div
                className="complete-survey"
                onClick={() => {
                  setSurveyCode("");
                  setIsSubmitted(false);
                }}
              >
                Try Again
              </div>
              <div
                className="complete-survey remove"
                onClick={() => handleSubmitSurvey()}
              >
                {pos ? "Remove Item" : "Cancel"}
              </div>
            </>
          );
      }
    }
  }, [
    handleSubmitSurvey,
    isSubmitted,
    pos,
    registerId,
    storeId,
    surveyCode,
    codeStatus,
  ]);

  return (
    <div className="survey-modal-container">
      <div className="inner-container">
        <div className="title">HSA Survey Form</div>
        {renderPhase}
      </div>
    </div>
  );
};

export default SurveyModal;
