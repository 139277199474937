import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiButton,
  EuiSpacer,
  EuiHorizontalRule,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";
import { toCurrency } from "../../../../utils/text-utils";
import {
  WalmartPickupIcon,
  WalmartShippingIcon,
} from "../../../../assets/assets";
import { Location } from "../../../../services/geoapify-service";
import { useSelector } from "react-redux";
import { locationStateSelector } from "../../../../state/slices/location-slice";

export const WalmartWebItemDetailOrdering = ({
  item,
  itemCount,
  updateItemCount,
  increment,
  decrement,
  addToCart,
}: WebItemDetailOrderingProps) => {
  const location: Location | undefined = useSelector(locationStateSelector);

  return (
    <EuiFlexItem className="walmart-item-ordering-wrapper">
      <EuiSpacer size="m" />
      <EuiText className="item-details--price-values">
        {toCurrency(item.price)}
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="xs">Price when purchased online</EuiText>
      <EuiSpacer size="m" />
      <EuiButton onClick={addToCart}>Add to Cart</EuiButton>
      <EuiHorizontalRule margin="s" />
      <EuiText className="how-do-you-want" size="m">
        How do you want your item?
      </EuiText>
      <EuiSpacer size="m" />
      <EuiFlexGroup
        className="purchase-type-box-row"
        direction="row"
        alignItems="center"
        justifyContent="flexStart"
      >
        <EuiFlexGroup
          className="purchase-type-box"
          direction="column"
          alignItems="center"
          justifyContent="flexStart"
          gutterSize="none"
        >
          <img src={WalmartShippingIcon} alt="shipping" />
          <EuiText className="title">Shipping</EuiText>
          <EuiText className="timing">Arrives today</EuiText>
          <EuiText className="warning">Order within 40 min</EuiText>
        </EuiFlexGroup>
        <EuiFlexGroup
          className="purchase-type-box"
          direction="column"
          alignItems="center"
          justifyContent="flexStart"
          gutterSize="none"
        >
          <img src={WalmartPickupIcon} alt="pickup" />
          <EuiText className="title">Pickup</EuiText>
          <EuiText className="timing">Today</EuiText>
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiText>
        Pickup from{" "}
        <span className="bold-and-underline">{location?.city} Supercenter</span>
      </EuiText>
      <EuiText>
        Delivery to{" "}
        <span className="bold-and-underline">
          {location?.city}, {location?.postCode}
        </span>
      </EuiText>
    </EuiFlexItem>
  );
};
