import { EuiIcon } from "@elastic/eui";
import { WebLocationBannerProps } from "../../../common/web/web-location-banner";
import "./web-location-banner.scss";

export const CostcoWebLocationBanner = ({
  city,
  postCode,
}: WebLocationBannerProps) => {
  return (
    <div className="costco-web-location-banner">
      <div className="web-location-banner-my-warehouse">
        <div className="label">My Warehouse</div>
        <div className="value">
          <EuiIcon type={"mapMarker"} />
          {city ?? ""}
        </div>
      </div>
      <div className="web-location-banner-my-zipcode">
        <div className="label">Delivery Location</div>
        <div className="value">
          <EuiIcon type={"container"} />
          {postCode ?? ""}
        </div>
      </div>
    </div>
  );
};
