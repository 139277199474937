import { useEffect, useMemo, useState } from "react";
import "./base-page.scss";
import { QubesService } from "../../services/qubes-service";
import { CompanyResolver } from "../../company/common/web/company-resolver";
import { ProductResolver } from "../../product/common/survey/product-resolver";
import { useSelector } from "react-redux";
import { userSelector } from "../../state/slices/user-slice";
import {
  DemoConfigCompany,
  DemoConfigProduct,
} from "../../model/pos/demo-config";
import { EuiErrorBoundary } from "@elastic/eui";

type BasePageProps = {
  children: React.ReactNode;
};

const BasePage: React.FC<BasePageProps> = (props) => {
  const [companyIsLoading, setCompanyIsLoading] = useState<boolean>(true);
  const [productIsLoading, setProductIsLoading] = useState<boolean>(true);
  const [configIsMissing, setConfigIsMissing] = useState<boolean>(false);
  const isSetupPage = window.location.pathname === "/setup";

  const user = useSelector(userSelector);

  /**
   * Load the active company and product
   */
  useEffect(() => {
    if (!user) {
      console.debug("No user yet...");
      return;
    } else {
      console.debug("User set. Loading demo preferences...");
    }
    QubesService.loadDemoPreferences<DemoConfigCompany>(user.uid!, "company")
      .then((resp) => {
        if (resp?.data?.[0]?.value?.activeCompany) {
          try {
            const companyPreference = resp.data[0];
            console.debug(
              `Received demo config for ${companyPreference?.value?.activeCompany}`
            );
            CompanyResolver.get().setUserDemoPreference(companyPreference);
            if (!companyPreference?.value?.activeCompany) {
              console.warn(
                `The user has a DemoConfig record for company, but the no value is specified in the result. Routing user to setup page`
              );
              setConfigIsMissing(true);
            }
          } catch (err) {
            console.error(
              "Failure to process the user's company preference",
              err
            );
          }
        } else {
          console.info("No existing selection for this user for company");
          setConfigIsMissing(true);
          if (resp?.data?.[0]) {
            // it is possible that we still have an existing record but no company is specified
            CompanyResolver.get().setUserDemoPreference(resp.data[0]);
          }
        }
        setCompanyIsLoading(false);
      })
      .catch((err) => {
        console.error(
          "Error encountered while attempting to load the activeCompany for the current user",
          err
        );
        alert(
          "An unexpected error has occurred. Please refresh the page to try again."
        );
      });

    QubesService.loadDemoPreferences<DemoConfigProduct>(user.uid!, "product")
      .then((resp) => {
        if (resp?.data?.[0]?.value?.activeProduct) {
          try {
            const productPreference = resp.data[0];
            console.debug(
              `Received demo config for ${productPreference?.value?.activeProduct}`
            );
            ProductResolver.get().setUserDemoPreference(productPreference);
            if (!productPreference?.value?.activeProduct) {
              console.warn(
                `The user has a DemoConfig record for product, but the no value is specified in the result. Routing user to setup page`
              );
              setConfigIsMissing(true);
            }
          } catch (err) {
            console.error(
              "Failure to process the user's product preference",
              err
            );
          }
        } else {
          console.info("No existing selection for this user for product");
          setConfigIsMissing(true);
          if (resp?.data?.[0]) {
            // it is possible that we still have an existing record but no product is specified
            ProductResolver.get().setUserDemoPreference(resp.data[0]);
          }
        }
        setProductIsLoading(false);
      })
      .catch((err) => {
        console.error(
          "Error encountered while attempting to load the activeProduct for the current user",
          err
        );
        alert(
          "An unexpected error has occurred. Please refresh the page to try again."
        );
      });
  }, [setCompanyIsLoading, user]);

  useEffect(() => {
    if (
      configIsMissing &&
      !companyIsLoading &&
      !productIsLoading &&
      !isSetupPage
    ) {
      console.info("Missing config --> routing to setup page");
      console.info(
        `${!companyIsLoading} | ${!productIsLoading} | ${!configIsMissing} | ${
          window.location.pathname
        }`
      );
      window.location.replace("/setup");
    } else {
      console.debug(
        `${!companyIsLoading} | ${!productIsLoading} | ${!configIsMissing} | ${
          window.location.pathname
        }`
      );
    }
  }, [configIsMissing, companyIsLoading, productIsLoading, isSetupPage]);

  const loadingDiv = useMemo(() => <div>"Loading..."</div>, []);

  const resolvedComponent = useMemo(() => {
    if (companyIsLoading || productIsLoading) return loadingDiv;
    else if (!configIsMissing || isSetupPage)
      return (
        <EuiErrorBoundary>
          <div className="_base_page">{props.children}</div>
        </EuiErrorBoundary>
      );
    else return null;
  }, [
    companyIsLoading,
    productIsLoading,
    loadingDiv,
    configIsMissing,
    isSetupPage,
    props.children,
  ]);

  return resolvedComponent;
};

export default BasePage;
