import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import "./web-session-banner.scss";
import { CvsLogo, ShoppingCartLogoBlack } from "../../../../assets/assets";
import FakeLink from "../../../../components/fake-link/fake-link";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";

export const CvsWebSessionBanner = ({
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="cvs-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage className="cvs-logo" src={CvsLogo} alt="Cvs logo" />
            </Link>
          </EuiFlexItem>
          <EuiFlexGroup
            className="links"
            alignItems="center"
            justifyContent="spaceBetween"
            gutterSize="s"
            style={{ flexGrow: 0, flexShrink: 0 }}
          >
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                direction="row"
                gutterSize="none"
                alignItems="flexEnd"
              >
                <FakeLink text="Prescriptions" />
                <EuiIcon type={"arrowDown"} />
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                direction="row"
                gutterSize="none"
                alignItems="flexEnd"
              >
                <FakeLink text="Health" />
                <EuiIcon type={"arrowDown"} />
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                direction="row"
                gutterSize="none"
                alignItems="flexEnd"
              >
                <FakeLink text="Shop" />
                <EuiIcon type={"arrowDown"} />
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                direction="row"
                gutterSize="none"
                alignItems="flexEnd"
              >
                <FakeLink text="Savings & Memberships" />
                <EuiIcon type={"arrowDown"} />
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search products and services"
              fullWidth
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem grow={false} className="right-side">
          <EuiFlexGroup
            gutterSize="l"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <EuiFlexGroup
              style={{ flexGrow: 0, flexShrink: 0 }}
              justifyContent="center"
              alignItems="center"
              gutterSize="s"
            >
              <EuiIcon type="user" size="l" />
              <FakeLink text="Account" />
              <EuiIcon type="arrowDown" size="m" />
            </EuiFlexGroup>

            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <EuiFlexGroup
                  direction="column"
                  justifyContent="spaceAround"
                  gutterSize="xs"
                >
                  <img
                    className="shopping-cart-image"
                    src={ShoppingCartLogoBlack}
                    alt="shopping cart logo"
                  />
                  <EuiFlexItem>Cart</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                  {shoppingCartQuantity}
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
