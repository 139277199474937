import { CostcoLogo } from "../../assets/assets";
import { AbstractCompanyComponentResolver } from "./abstract-company-resolver";
import { Location } from "../../services/geoapify-service";
import { CostcoWebLocationBanner } from "../costco/web/web-location-banner/web-location-banner";
import { CostcoWebNavigationBanner } from "../costco/web/web-navigation-banner/web-navigation-banner";
import { CostcoWebSessionBanner } from "../costco/web/web-session-banner/web-session-banner";
import { ItemMaster } from "../../model/pos/item-master";
import { WebHomePageProps } from "../common/web/web-home-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import { WebItemProps } from "../common/web/web-item-props";
import CostcoWebsiteHomePage from "../costco/web/web-home-page/web-home-page";
import { CostcoWebCartDeliveryInfo } from "../costco/web/web-cart-delivery-info/web-cart-delivery-info";
import { WebItemPath } from "../common/web/web-item-path.ts/web-item-path";
import { CostcoWebItemDetailPricing } from "../costco/web/web-item-detail-pricing/web-item-detail-pricing";
import { CostcoWebItemDetailOrdering } from "../costco/web/web-item-detail-ordering/web-item-detail-ordering";
import { CostcoWebItemFurtherDetails } from "../costco/web/web-item-detail-further-details/web-item-detail-further-details";
import { EuiSpacer, EuiFlexGroup, EuiText, EuiFlexItem } from "@elastic/eui";

export class CostcoComponentResolver extends AbstractCompanyComponentResolver {
  constructor() {
    super("costco");
  }

  getItemFilters(): string {
    return " and demoCategories like '%costco%' or demoCategories like '%club%'";
  }

  getLogo() {
    return CostcoLogo;
  }

  getLogoLight() {
    return CostcoLogo;
  }

  getWebHeader(shoppingCartQuantity: number, location?: Location): JSX.Element {
    return (
      <>
        <CostcoWebSessionBanner shoppingCartQuantity={shoppingCartQuantity} />
        <CostcoWebNavigationBanner />
        <CostcoWebLocationBanner
          city={location?.city ?? ""}
          state={location?.state ?? ""}
          postCode={location?.postCode ?? ""}
        />
      </>
    );
  }

  getHomePage(props: WebHomePageProps): JSX.Element {
    return <CostcoWebsiteHomePage {...props} />;
  }

  getCartDeliveryInfo(): JSX.Element {
    return <CostcoWebCartDeliveryInfo />;
  }

  getShippingText(): string {
    return "Shipping and Handling for";
  }

  getWebItemPath(item: ItemMaster): JSX.Element {
    return <WebItemPath item={item} separator={"forward-slash"} />;
  }

  getItemDetailPricing(props: WebItemProps): JSX.Element {
    return <CostcoWebItemDetailPricing {...props} />;
  }

  getItemDetailOrdering(props: WebItemDetailOrderingProps): JSX.Element {
    return <CostcoWebItemDetailOrdering {...props} />;
  }

  getItemDetailFurtherDetails(props: WebItemProps): JSX.Element {
    return <CostcoWebItemFurtherDetails {...props} />;
  }

  getItemDetailFeatures(props: WebItemProps): JSX.Element {
    return (
      <>
        <EuiSpacer size="xl" />
        <EuiFlexGroup
          className="item-features"
          direction="column"
          alignItems="flexStart"
          justifyContent="flexStart"
          gutterSize="none"
        >
          <EuiText>Features:</EuiText>
          <EuiFlexItem>
            <ul>
              {props.item.primaryFeatures?.map((feature) => {
                return <li key={feature}>{feature}</li>;
              })}
            </ul>
            {props.item.sanofiOtcSku ? (
              <ul className="sanofiSku">
                <li>**Purchase Restrictions Apply</li>
              </ul>
            ) : null}
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  }
}
