import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiImage,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";
import { toCurrency } from "../../../../utils/text-utils";
import { CvsPickupIcon } from "../../../../assets/assets";
import { useSelector } from "react-redux";
import { locationStateSelector } from "../../../../state/slices/location-slice";

export const CvsWebItemDetailPricing = ({ item }: WebItemProps) => {
  const location = useSelector(locationStateSelector);

  return (
    <EuiFlexGroup
      className="cvs-item-details--price"
      direction="column"
      justifyContent="flexStart"
      gutterSize="s"
    >
      <EuiText className="item-details--price-values">
        {toCurrency(item.price)}
      </EuiText>
      <EuiSpacer size="s" />
      <EuiText className="item-details--price-purchaseoptions">
        How to get it
      </EuiText>
      <EuiFlexGroup
        className="item-details--price-box"
        direction="column"
        justifyContent="flexStart"
        gutterSize="s"
      >
        <EuiFlexGroup
          gutterSize="xs"
          alignItems="center"
          style={{ flexGrow: 0 }}
        >
          <EuiImage
            className="item-details--pickup-icon"
            src={CvsPickupIcon}
            alt="Pickup logo"
          />
          <EuiFlexGroup
            direction="column"
            justifyContent="center"
            gutterSize="none"
            style={{ flexGrow: 0, marginLeft: "10px" }}
          >
            <EuiText className="item-details--price-pickup">Pickup</EuiText>
            {/* <EuiIcon type="arrowDown" size="s" color="primary" /> */}
            <EuiText className="item-details--price-availability">
              Within 3 hours
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexGroup>
        <EuiHorizontalRule margin="xs" />
        <EuiFlexGroup gutterSize="s" style={{ flexGrow: 0 }}>
          <EuiIcon type={"check"} color="#118738" size="l" />
          <EuiText className="item-details--in-stock">In stock at</EuiText>
          <EuiFlexGroup
            className="item-details--address"
            direction="column"
            gutterSize="none"
          >
            <EuiText className="item-details--address">2424 N MAIN ST.</EuiText>
            <EuiText className="item-details--address">
              {location
                ? `${location.city.toUpperCase()}, ${location.stateCode} ${
                    location.postCode
                  }`
                : ""}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiText className="item-details--check-more-stores">
            Check more stores
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
