import { EuiFlexGroup, EuiText, EuiSpacer } from "@elastic/eui";
import "./web-item-detail-further-details.scss";
import { WebItemProps } from "../../../common/web/web-item-props";
import HomePageItemRow from "../../../../components/home-page-item-row/home-page-item-row";

export const SamsclubWebItemFurtherDetails = ({
  itemsDatabase,
}: WebItemProps) => {
  /**
   * This is a non-standard usage of this section. Sams club site is a little different from the rest
   */
  return (
    <EuiFlexGroup
      key={"item-further-details"}
      className="samsclub-item-further-details-similar-items"
      direction="column"
      alignItems="center"
    >
      <EuiText
        style={{ fontWeight: "700", fontSize: "24px", alignSelf: "flex-start" }}
      >
        Similar items for you
      </EuiText>
      <EuiSpacer size="m" />
      <HomePageItemRow items={itemsDatabase?.slice(0, 4) ?? []} />
    </EuiFlexGroup>
  );
};
