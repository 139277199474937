import "./pay-now-button.scss";

/**
 * Props needed for PayNowButton to work
 * @prop {() => void} checkout - The method to update the parent state so we can display the checkout workflow
 */
export type PayNowButtonProps = {
  checkout: () => void;
};

/**
 * The button which handles the user initiating checkout
 * @returns {React.Component}
 */
const PayNowButton = ({ checkout }: PayNowButtonProps) => {
  return (
    <div className="pay-now-button-container" onClick={checkout}>
      Check Out
    </div>
  );
};

export default PayNowButton;
