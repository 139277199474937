import { Company } from "../../company/common/web/company-resolver";
import { Product } from "../../product/common/survey/product-resolver";

export const getProductThemeOverrides = (product: Product) => {
  switch (product) {
    case "cialis": {
      return {
        "--survey-background-color-one": "#303030",
        "--survey-title-color": "#ffd065",
        "--survey-subtitle-color": "#f5f5f5",
        "--survey-subtext-color": "#bfbfbf",
        "--survey-begin-button-color": "#247d26",
        "--survey-answer-button-color": "#e8e8e8",
        "--survey-warning-color": "#ed1c24",
      };
    }
    case "super aspirin": {
      return {
        "--survey-background-color-one": "#35495e",
        "--survey-title-color": "#42b883",
        "--survey-subtitle-color": "#f5f5f5",
        "--survey-subtext-color": "#bfbfbf",
        "--survey-begin-button-color": "#ff7e67",
        "--survey-answer-button-color": "#e8e8e8",
        "--survey-warning-color": "#ed1c24",
      };
    }
  }
};

export const getCompanyThemeOverrides = (company: Company) => {
  switch (company) {
    case "costco": {
      return {
        "--customer-sell-item-details-image-size": "316px",
        "--customer-sell-item-details-row-height": "605px",
        "--customer-sell-item-details-name-size": "20px",
        "--customer-sell-item-details-name-weight": "400",
        "--customer-sell-item-details-itemno-visibility": "visible",
        "--customer-sell-item-details-and-ordering-direction": "row",
        "--customer-sell-item-details-and-ordering-direction-grow": 2,
        "--customer-sell-item-background-color-top":
          "linear-gradient(to top, #dbdbdb, #eaeaea, #f2f2f2)",
        "--customer-sell-item-background-color-bottom":
          "linear-gradient(to bottom, #dbdbdb, #eaeaea, #f2f2f2)",
        "--customer-sell-item-color": "black",
        "--customer-sell-item-details-page-background-color": "#fff",
      };
    }
    case "kroger": {
      return {
        "--customer-sell-item-details-image-size": "425px",
        "--customer-sell-item-details-row-height": "425px",
        "--customer-sell-item-details-name-size": "32px",
        "--customer-sell-item-details-name-weight": "400",
        "--customer-sell-item-details-itemno-visibility": "visible",
        "--customer-sell-item-details-and-ordering-direction": "column",
        "--customer-sell-item-details-and-ordering-direction-grow": 0,
        "--customer-sell-item-background-color-top": "rgb(40, 116, 193)",
        "--customer-sell-item-background-color-bottom": "rgb(40, 116, 193)",
        "--customer-sell-item-border-right": "1px solid rgb(40, 116, 193)",
        "--customer-sell-item-border-left": "1px solid rgb(40, 116, 193)",
        "--customer-sell-item-color": "white",
        "--customer-sell-item-details-page-background-color": "#fff",
      };
    }
    case "cvs": {
      return {
        "--customer-sell-item-details-image-size": "405px",
        "--customer-sell-item-details-row-height": "475px",
        "--customer-sell-item-details-name-size": "28px",
        "--customer-sell-item-details-name-weight": "700",
        "--customer-sell-item-details-itemno-visibility": "hidden",
        "--customer-sell-item-details-and-ordering-direction": "column",
        "--customer-sell-item-details-and-ordering-direction-grow": 0,
        "--customer-sell-item-background-color-top": "#004d99",
        "--customer-sell-item-background-color-bottom": "#004d99",
        "--customer-sell-item-border-right": "1px solid #004d99",
        "--customer-sell-item-border-left": "1px solid #004d99",
        "--customer-sell-item-color": "white",
        "--customer-sell-item-details-page-background-color":
          "rgb(242, 242, 242)",
      };
    }
    case "samsclub": {
      return {
        "--customer-sell-item-details-image-size": "316px",
        "--customer-sell-item-details-row-height": "950px",
        "--customer-sell-item-details-name-size": "24px",
        "--customer-sell-item-details-name-weight": "700",
        "--customer-sell-item-details-itemno-visibility": "visible",
        "--customer-sell-item-details-and-ordering-direction": "column",
        "--customer-sell-item-details-and-ordering-direction-grow": 2,
        "--customer-sell-item-background-color-top": "#0068a0",
        "--customer-sell-item-background-color-bottom": "#0068a0",
        "--customer-sell-item-border-right": "1px solid rgb(40, 116, 193)",
        "--customer-sell-item-border-left": "1px solid rgb(40, 116, 193)",
        "--customer-sell-item-color": "white",
        "--customer-sell-item-details-page-background-color": "#fff",
      };
    }
    case "walmart": {
      return {
        "--customer-sell-item-details-image-size": "316px",
        "--customer-sell-item-details-row-height": "450px",
        "--customer-sell-item-details-name-size": "24px",
        "--customer-sell-item-details-name-weight": "700",
        "--customer-sell-item-details-itemno-visibility": "visible",
        "--customer-sell-item-details-and-ordering-direction": "row",
        "--customer-sell-item-details-and-ordering-direction-grow": 2,
        "--customer-sell-item-background-color-top": "#0072dc",
        "--customer-sell-item-background-color-bottom": "#0072dc",
        "--customer-sell-item-border-right": "1px solid rgb(40, 116, 193)",
        "--customer-sell-item-border-left": "1px solid rgb(40, 116, 193)",
        "--customer-sell-item-color": "white",
        "--customer-sell-item-details-page-background-color": "#fff",
      };
    }
  }
};
