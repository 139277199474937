import { CvsLogo } from "../../assets/assets";
import { AbstractCompanyComponentResolver } from "./abstract-company-resolver";
import { Location } from "../../services/geoapify-service";
import { ItemMaster } from "../../model/pos/item-master";
import { WebHomePageProps } from "../common/web/web-home-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import { WebItemProps } from "../common/web/web-item-props";
import { WebItemPath } from "../common/web/web-item-path.ts/web-item-path";
import { EuiSpacer } from "@elastic/eui";
import { CvsWebLinkBanner } from "../cvs/web/web-link-banner/web-link-banner";
import { CvsWebLocationBanner } from "../cvs/web/web-location-banner/web-location-banner";
import { CvsWebSessionBanner } from "../cvs/web/web-session-banner/web-session-banner";
import CvsWebsiteHomePage from "../cvs/web/web-home-page/web-home-page";
import { CvsWebCartDeliveryInfo } from "../cvs/web/web-cart-delivery-info/web-cart-delivery-info";
import { CvsWebItemDetailPricing } from "../cvs/web/web-item-detail-pricing/web-item-detail-pricing";
import { CvsWebItemDetailOrdering } from "../cvs/web/web-item-detail-ordering/web-item-detail-ordering";
import { CvsWebItemFurtherDetails } from "../cvs/web/web-item-detail-further-details/web-item-detail-further-details";

export class CvsComponentResolver extends AbstractCompanyComponentResolver {
  constructor() {
    super("cvs");
  }

  getItemFilters(): string {
    return " and demoCategories like '%cvs%' or demoCategories like '%grocery%'";
  }

  getLogo() {
    return CvsLogo;
  }

  getLogoLight() {
    return CvsLogo;
  }

  getWebHeader(shoppingCartQuantity: number, location?: Location): JSX.Element {
    return (
      <>
        <CvsWebSessionBanner shoppingCartQuantity={shoppingCartQuantity} />
        <CvsWebLinkBanner />
        <CvsWebLocationBanner />
      </>
    );
  }

  getHomePage(props: WebHomePageProps): JSX.Element {
    return <CvsWebsiteHomePage {...props} />;
  }

  getCartDeliveryInfo(): JSX.Element {
    return <CvsWebCartDeliveryInfo />;
  }

  getShippingText(): string {
    return "Pickup for";
  }

  getWebItemPath(item: ItemMaster): JSX.Element {
    return <WebItemPath item={item} separator={"arrow-right"} />;
  }

  getItemDetailPricing(props: WebItemProps): JSX.Element {
    return <CvsWebItemDetailPricing {...props} />;
  }

  getItemDetailOrdering(props: WebItemDetailOrderingProps): JSX.Element {
    return <CvsWebItemDetailOrdering {...props} />;
  }

  getItemDetailFurtherDetails(props: WebItemProps): JSX.Element {
    return <CvsWebItemFurtherDetails {...props} />;
  }

  getItemDetailFeatures(props: WebItemProps): JSX.Element {
    return <EuiSpacer size="l" />;
  }
}
