import { MeijerLogo } from "../../assets/assets";
import { AbstractCompanyComponentResolver } from "./abstract-company-resolver";
import { Location } from "../../services/geoapify-service";
import { ItemMaster } from "../../model/pos/item-master";
import { WebHomePageProps } from "../common/web/web-home-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import { WebItemProps } from "../common/web/web-item-props";
import { WebItemPath } from "../common/web/web-item-path.ts/web-item-path";
import { MeijerWebBanner } from "../meijer/web/web-banner/web-banner";
import { MeijerWebLinkBanner } from "../meijer/web/web-link-banner/web-link-banner";
import { MeijerWebLinkBanner2 } from "../meijer/web/web-link-banner2/web-link-banner2";
import MeijerWebsiteHomePage from "../meijer/web/web-home-page/web-home-page";
import { MeijerWebItemDetailOrdering } from "../meijer/web/web-item-detail-ordering/web-item-detail-ordering";
import { MeijerWebItemDetailPricing } from "../meijer/web/web-item-detail-pricing/web-item-detail-pricing";
import { MeijerWebItemFurtherDetails } from "../meijer/web/web-item-detail-further-details/web-item-detail-further-details";
import { MeijerWebCartDeliveryInfo } from "../meijer/web/web-cart-delivery-info/web-cart-delivery-info";

export class MeijerComponentResolver extends AbstractCompanyComponentResolver {
  constructor() {
    super("meijer");
  }

  getItemFilters(): string {
    return " and demoCategories like '%meijer%' or demoCategories like '%grocery%'";
  }

  getLogo() {
    return MeijerLogo;
  }

  getLogoLight() {
    return MeijerLogo;
  }

  getWebHeader(shoppingCartQuantity: number, location?: Location): JSX.Element {
    return (
      <>
        <MeijerWebBanner shoppingCartQuantity={shoppingCartQuantity} />
        <MeijerWebLinkBanner />
        <MeijerWebLinkBanner2 />
      </>
    );
  }

  getHomePage(props: WebHomePageProps): JSX.Element {
    return <MeijerWebsiteHomePage {...props} />;
  }

  getCartDeliveryInfo(): JSX.Element {
    return <MeijerWebCartDeliveryInfo />;
  }

  getShippingText(): string {
    return "Pickup for";
  }

  getWebItemPath(item: ItemMaster): JSX.Element {
    return <WebItemPath item={item} separator={"pipe"} />;
  }

  getItemDetailPricing(props: WebItemProps): JSX.Element {
    return <MeijerWebItemDetailPricing {...props} />;
  }

  getItemDetailOrdering(props: WebItemDetailOrderingProps): JSX.Element {
    return <MeijerWebItemDetailOrdering {...props} />;
  }

  getItemDetailFurtherDetails(props: WebItemProps): JSX.Element {
    return <MeijerWebItemFurtherDetails {...props} />;
  }

  getItemDetailFeatures(props: WebItemProps): JSX.Element {
    return <></>;
  }
}
