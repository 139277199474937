import "./survey-ed.scss";

/**
 * The Erectile Dysfunction content
 * @returns {JSX.Element}
 */
const SurveyED = () => {
  return (
    <div className="survey-ed-container">
      <div className="header">For Erectile Dysfunction (ED)</div>
      <p className="paragraph">
        ED is a condition in men who have trouble getting or keeping an erection
        hard enough to have satisfactory sexual activity, this happens on{" "}
        <b>multiple occasions</b>.
      </p>
      <p className="paragraph">
        Cialis&reg; OTC treats ED and is only to be taken by men{" "}
        <u>18 years and older</u>.
      </p>
    </div>
  );
};

export default SurveyED;
