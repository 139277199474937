import { EuiFlexGroup, EuiIcon, EuiText } from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const WalmartWebItemDetailPricing = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      className="walmart-item-details--price"
      direction="row"
      justifyContent="flexStart"
      gutterSize="none"
      alignItems="center"
    >
      <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
      <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
      <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
      <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
      <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
      <EuiText size="xs">(4.8)</EuiText>
      <EuiText size="m" style={{ marginLeft: "10px", marginRight: "10px" }}>
        |
      </EuiText>
      <EuiText size="s" style={{ textDecoration: "underline" }}>
        12.3K ratings
      </EuiText>
    </EuiFlexGroup>
  );
};
