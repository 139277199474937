import { Company } from "../../company/common/web/company-resolver";
import { Product } from "../../product/common/survey/product-resolver";
import { UidKey } from "../common";

export type DemoConfig<T> = UidKey & {
  usergroupuid: string;
  property: string;
  value: T;
};

const _sample: Required<DemoConfig<unknown>> = {
  uid: "string",
  usergroupuid: "string",
  property: "string",
  value: "string",
};

export const DemoConfigFieldList = Object.keys(_sample);

export type DemoConfigCompany = {
  activeCompany: Company;
};

export type DemoConfigProduct = {
  activeProduct: Product;
};
