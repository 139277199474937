import { UidKey } from "../common";

/**
 * The customer
 */
export type Customer = UidKey & {
  active: boolean;
  birthdate: string;
  extraConfig?: unknown;
  firstName: string;
  lastName: string;
  mobile: string;
};

const _sample: Required<Customer> = {
  uid: "string",
  active: false,
  birthdate: "string",
  firstName: "string",
  lastName: "string",
  mobile: "string",
  extraConfig: "json",
};
export const CustomerFields = Object.keys(_sample);
