import { useEffect } from "react";
import "./web-receipt-page.scss";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import HomePageItemRow from "../../../../components/home-page-item-row/home-page-item-row";
import { Link } from "react-router-dom";
import { WebReceiptPageProps } from "../../../common/web/web-receipt-page";
import { CompanyResolver } from "../company-resolver";

/**
 * This is the page that controls all actions within the POS demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebReceiptPage = ({
  itemsDatabase,
}: WebReceiptPageProps): JSX.Element => {
  const orderNumber = "1123695413";

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <EuiFlexGroup
      className="website-receipt-page"
      direction="column"
      alignItems="center"
      justifyContent="flexStart"
      gutterSize="none"
    >
      <EuiSpacer size="l" />
      <EuiText style={{ fontSize: "24px", fontWeight: 700 }}>
        Thank you for your order!
      </EuiText>
      <EuiSpacer size="l" />
      <EuiText style={{ fontSize: "18px", fontWeight: 700 }}>
        Order Number {orderNumber}
      </EuiText>
      <EuiSpacer size="l" />
      <EuiText>We sent you a confirmation email to</EuiText>
      <EuiText>
        {CompanyResolver.get().getComponentResolver().companyName}
        -customer@email.com
      </EuiText>
      <EuiSpacer size="l" />
      <EuiButton className="blue-button">View or Change Order</EuiButton>
      <EuiSpacer size="l" />
      <Link to={"/web"}>
        <EuiButtonEmpty>Continue Shopping</EuiButtonEmpty>
      </Link>
      <EuiSpacer size="xl" />
      <EuiText className="recently-viewed-products-label">
        Recently Viewed Products
      </EuiText>
      <EuiSpacer size="m" />
      <HomePageItemRow items={itemsDatabase?.slice(0, 5) ?? []} />
    </EuiFlexGroup>
  );
};

export default WebReceiptPage;
