import { useEffect, useState } from "react";
import "./base-page.scss";
import { QubesService } from "../../services/qubes-service";
import {
  Company,
  CompanyResolver,
} from "../../company/common/web/company-resolver";
import {
  Product,
  ProductResolver,
} from "../../product/common/survey/product-resolver";

type BasePageProps = {
  children: React.ReactNode;
};

const BasePage: React.FC<BasePageProps> = (props) => {
  const [resolvedCompany, setResolvedCompany] = useState<Company>();
  const [resolvedProduct, setResolvedProduct] = useState<Product>();

  /**
   * Load the active company and product
   */
  useEffect(() => {
    QubesService.loadActiveCompany()
      .then((resp) => {
        if (resp?.data?.[0]) {
          const company: Company = resp.data[0].value as Company;
          CompanyResolver.subdomain = company;
          console.debug(`Demoing ${company}`);
          setResolvedCompany(company);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    QubesService.loadActiveProduct()
      .then((resp) => {
        if (resp?.data?.[0]) {
          const product: Product = resp.data[0].value as Product;
          ProductResolver.subdomain = product;
          console.debug(`Demoing ${product}`);
          setResolvedProduct(product);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [setResolvedCompany, setResolvedProduct]);

  return resolvedCompany && resolvedProduct ? (
    <div className="_base_page">{props.children}</div>
  ) : (
    <div>"Loading..."</div>
  );
};

export default BasePage;
