import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import { KrogerLogo, ShoppingCartLogoWhite } from "../../../../assets/assets";
import FakeLink from "../../../../components/fake-link/fake-link";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";
import "./web-session-banner.scss";

export const KrogerWebSessionBanner = ({
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="kroger-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage
                className="kroger-logo"
                src={KrogerLogo}
                alt="kroger logo"
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexGroup
            className="links"
            alignItems="center"
            gutterSize="s"
            style={{ flexGrow: 0, flexShrink: 0 }}
          >
            <EuiFlexItem grow={false}>
              <FakeLink text="Shop" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <FakeLink text="Save" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <FakeLink text="Pickup & Delivery" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <FakeLink text="Services" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <FakeLink text="Pharmacy & Health" />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search Products"
              fullWidth
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem grow={false} className="right-side">
          <EuiFlexGroup
            gutterSize="l"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <EuiFlexGroup
              style={{ flexGrow: 0, flexShrink: 0 }}
              justifyContent="center"
              alignItems="center"
              gutterSize="s"
            >
              <EuiIcon type="user" size="l" color="ghost" />
              <FakeLink text="John" />
              <EuiIcon type="arrowDown" size="m" color="ghost" />
            </EuiFlexGroup>

            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <img
                  className="shopping-cart-image"
                  src={ShoppingCartLogoWhite}
                  alt="shopping cart logo"
                />
                {shoppingCartQuantity > 0 ? (
                  <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                    {shoppingCartQuantity}
                  </EuiFlexItem>
                ) : (
                  <EuiFlexItem className="shopping-cart-quantity-placeholder"></EuiFlexItem>
                )}
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
