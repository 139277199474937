import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiSpacer,
  EuiIcon,
} from "@elastic/eui";
import "./web-item-detail-further-details.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const MeijerWebItemFurtherDetails = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      key={"item-further-details"}
      className="meijer-item-further-details"
      direction="column"
      alignItems="center"
    >
      <EuiFlexItem className="product-details" grow={false}>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiText className="section-name-title">Product Details</EuiText>
            <EuiIcon type="arrowUp" />
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <ul>
              {item.primaryFeatures?.map((feature) => {
                return <li key={feature}>{feature}</li>;
              })}
            </ul>
          </EuiFlexItem>
          <EuiSpacer size="m" />
        </EuiFlexGroup>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiText className="section-name-title">Product Features</EuiText>
            <EuiIcon type="arrowUp" />
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <table>
              <tbody>
                {item.specifications?.map((spec) => {
                  return (
                    <tr key={spec[1]}>
                      <td>{spec[0]}</td>
                      <td>{spec[1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </EuiFlexItem>
          <EuiSpacer size="s" />
        </EuiFlexGroup>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiText className="section-name-title">
              Warnings and Disclaimer
            </EuiText>
            <EuiIcon type="arrowDown" />
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
