import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { WebLocationBannerProps } from "../../../common/web/web-location-banner";
import "./web-location-banner.scss";
import { PickupLogo } from "../../../../assets/assets";
import FakeLink from "../../../../components/fake-link/fake-link";

export const KrogerWebLocationBanner = ({ city }: WebLocationBannerProps) => {
  return (
    <div className="kroger-web-location-banner">
      <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
        <img
          className="pickup-location-image"
          src={PickupLogo}
          alt="pickup location logo"
        />
        <EuiText>Pickup at {city}</EuiText>
        <EuiIcon type="arrowDown" size="s" color="ghost" />
      </EuiFlexGroup>
      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="l"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexItem grow={false}>
          <FakeLink text="Weekly Ad" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Gift Cards" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Recipes" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Meal Planning" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Blog" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Payment Cards" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Find a Store" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Digital Coupons" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
