import { UidKey } from "../common";

export type QRCode = UidKey & {
  surveyUid: string;
  correlationCode: string;
  expirationDate: string;
  active: boolean;
  extraConfig?: unknown;
};

const _sample: Required<QRCode> = {
  uid: "string",
  active: false,
  surveyUid: "string",
  correlationCode: "string",
  expirationDate: "string",
  extraConfig: "json",
};
export const QRCodeFieldList = Object.keys(_sample);
