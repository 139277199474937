import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemMaster } from "../../model/pos/item-master";
import "./website-base-page.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  registerStateSelector,
  setRegister,
  setStore,
} from "../../state/slices/model-slice";
import { QubesService } from "../../services/qubes-service";
import { GeoapifyService, Location } from "../../services/geoapify-service";
import { EuiFlexGroup, EuiText } from "@elastic/eui";

import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { ShoppingCartService } from "../../services/shopping-cart-service";
import { CompanyResolver } from "../../company/common/web/company-resolver";
import { toTitleCase } from "../../utils/text-utils";
import { getCompanyThemeOverrides } from "../survey-web/theme-provider";
import {
  locationStateSelector,
  setLocation,
} from "../../state/slices/location-slice";

/**
 * This is the page that controls all actions within the Ecommerce demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebsiteBasePage = (): JSX.Element => {
  const dispatch = useDispatch();

  const params = useParams();

  const [shoppingCartQuantity, setShoppingCartQuantity] = useState(0);

  useEffect(() => {
    document.title = toTitleCase(
      CompanyResolver.get().getComponentResolver().companyName
    );
  }, []);

  useEffect(() => {
    const sub = ShoppingCartService.get()
      .observable()
      .subscribe((next: Map<string, number>) => {
        if (next.size > 0) {
          let count = 0;
          next.forEach((entry) => {
            count += entry;
          });
          setShoppingCartQuantity(count);
        } else setShoppingCartQuantity(0);
      });

    return () => sub.unsubscribe();
  }, [setShoppingCartQuantity]);

  // Grab and set the items the POS is going to be playing with
  const [items, setItems] = useState<ItemMaster[]>([]);

  // The register we are running on
  const register = useSelector(registerStateSelector);

  const location: Location | undefined = useSelector(locationStateSelector);

  /**
   * Load the data model
   */
  useEffect(() => {
    GeoapifyService.get()
      .getLocationFromIP()
      .then((location) => {
        dispatch(setLocation({ location }));
      })
      .catch((err) => {
        console.error(err);
      });

    //load the items
    QubesService.loadItems()
      .then((resp) => {
        if (resp.data) {
          const sortedItems = resp.data
            .filter((it) => {
              return it.environment === "home page" && it.order;
            })
            .sort((a, b) => {
              return a.order! - b.order!;
            });
          setItems(sortedItems);
        } else {
          throw new Error("Unable to load items");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    //load the registers
    QubesService.loadRegisters()
      .then((resp) => {
        if (resp.data) {
          const register = resp.data.find((reg) =>
            reg.name.startsWith("ECommerce")
          );

          dispatch(setRegister(register!));
        } else {
          throw new Error("Unable to load registers");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    //load the store
    QubesService.loadStores()
      .then((resp) => {
        if (resp.data && resp.data[0]) {
          dispatch(setStore(resp.data[0]));
        } else {
          throw new Error("Unable to load stores");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  const currentItem: ItemMaster | undefined = useMemo(() => {
    if (!isEmpty(items) && params.itemUidOrName) {
      return items.find((it) => it.uid === params.itemUidOrName);
    }
  }, [params.itemUidOrName, items]);

  const webHeader = useMemo(() => {
    return CompanyResolver.get()
      .getComponentResolver()
      .getWebHeader(shoppingCartQuantity, location);
  }, [location, shoppingCartQuantity]);

  const resolvedContent = useCallback(() => {
    if (params?.itemUidOrName) {
      if (currentItem) {
        return CompanyResolver.get()
          .getComponentResolver()
          .getItemDetailPage({ item: currentItem, itemsDatabase: items });
      } else return <EuiText size="m">Loading...</EuiText>;
    } else if (window.location.pathname?.includes("cart")) {
      return CompanyResolver.get().getComponentResolver().getCartPage({
        itemsDatabase: items,
        customerLocation: location,
        register,
      });
    } else if (
      window.location.pathname?.includes("receipt") &&
      params?.receiptUid
    ) {
      return CompanyResolver.get().getComponentResolver().getReceiptPage({
        receiptUid: params.receiptUid,
        itemsDatabase: items,
      });
    } else {
      return CompanyResolver.get()
        .getComponentResolver()
        .getHomePage({ items });
    }
  }, [params, items, currentItem, location, register]);

  return (
    <EuiFlexGroup
      className="website-base-page"
      direction="column"
      alignItems="center"
      gutterSize="none"
      style={
        getCompanyThemeOverrides(
          CompanyResolver.get().getComponentResolver().companyName
        ) as any
      }
    >
      {webHeader}
      {resolvedContent()}
    </EuiFlexGroup>
  );
};

export default WebsiteBasePage;
