import "./survey-wrapper.scss";
import { useMemo } from "react";
import SurveyHeader from "../survey-header/survey-header";
import { Product } from "../product-resolver";

/**
 * The props needed for SurveyWrapper to work
 * @prop {JSX.Element} children - The content for each view
 * @prop {() => void} iterateContent - Navigate to the correct view
 * @prop {boolean} atEnd - Whether or not the customer is done and we need to change the continue text
 */
export type SurveyWrapperProps = {
  children: JSX.Element;
  iterateContent: () => void;
  onCancel?: () => void;
  atEnd?: boolean;
  isWeb?: boolean;
  codeGenerated?: boolean;
  product: Product;
};

/**
 * The styling and functionality surrounding the basic survey content
 * @param {SurveyWrapperProps} porps - All the props
 * @returns {JSX.Element}
 */
const SurveyWrapper = ({
  children,
  iterateContent,
  onCancel,
  atEnd,
  isWeb,
  codeGenerated,
  product,
}: SurveyWrapperProps) => {
  const resolvedButtonText = useMemo(() => {
    if (atEnd && isWeb && codeGenerated) return "ADD TO CART";
    else if (atEnd) return "EXIT SURVEY";
    else return "CONTINUE";
  }, [atEnd, isWeb, codeGenerated]);
  return (
    <div className="survey-wrapper-container">
      <SurveyHeader product={product} />
      <div className="bottom">
        {children}
        <div className="row">
          <div className="continue" onClick={iterateContent}>
            {resolvedButtonText}
          </div>
          {!atEnd && isWeb ? (
            <div className="cancel" onClick={onCancel}>
              CANCEL
            </div>
          ) : null}
          <div className="bar" />
        </div>
      </div>
    </div>
  );
};

export default SurveyWrapper;
