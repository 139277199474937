import { SamsclubBlueLogo, SamsclubLogo } from "../../assets/assets";
import { AbstractCompanyComponentResolver } from "./abstract-company-resolver";
import { Location } from "../../services/geoapify-service";
import { ItemMaster } from "../../model/pos/item-master";
import { WebHomePageProps } from "../common/web/web-home-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import { WebItemProps } from "../common/web/web-item-props";
import { SamsclubWebBanner } from "../samsclub/web/web-banner/web-banner";
import { SamsclubWebLinkBanner } from "../samsclub/web/web-link-banner/web-link-banner";
import { SamsclubWebLocationBanner } from "../samsclub/web/web-location-banner/web-location-banner";
import SamsclubtWebsiteHomePage from "../samsclub/web/web-home-page/web-home-page";
import { SamsclubWebItemDetailPricing } from "../samsclub/web/web-item-detail-pricing/web-item-detail-pricing";
import { WebItemPath } from "../common/web/web-item-path.ts/web-item-path";
import { SamsclubWebItemDetailOrdering } from "../samsclub/web/web-item-detail-ordering/web-item-detail-ordering";
import { SamsclubWebItemFurtherDetails } from "../samsclub/web/web-item-detail-further-details/web-item-detail-further-details";
import { SamsclubWebCartDeliveryInfo } from "../samsclub/web/web-cart-delivery-info/web-cart-delivery-info";

export class SamsclubComponentResolver extends AbstractCompanyComponentResolver {
  constructor() {
    super("samsclub");
  }

  getItemFilters(): string {
    return " and demoCategories like '%samsclub%' or demoCategories like '%club%'";
  }

  getLogo() {
    return SamsclubBlueLogo;
  }

  getLogoLight() {
    return SamsclubLogo;
  }

  getWebHeader(shoppingCartQuantity: number, location?: Location): JSX.Element {
    return (
      <>
        <SamsclubWebBanner
          shoppingCartQuantity={shoppingCartQuantity}
        ></SamsclubWebBanner>
        <SamsclubWebLinkBanner>
          <SamsclubWebLocationBanner />
        </SamsclubWebLinkBanner>
      </>
    );
  }

  getHomePage(props: WebHomePageProps): JSX.Element {
    return <SamsclubtWebsiteHomePage {...props} />;
  }

  getCartDeliveryInfo(): JSX.Element {
    return <SamsclubWebCartDeliveryInfo />;
  }

  getShippingText(): string {
    return "Pickup for";
  }

  getWebItemPath(item: ItemMaster): JSX.Element {
    return <WebItemPath item={item} separator={"forward-slash"} />;
  }

  getItemDetailPricing(props: WebItemProps): JSX.Element {
    return <SamsclubWebItemDetailPricing {...props} />;
  }

  getItemDetailOrdering(props: WebItemDetailOrderingProps): JSX.Element {
    return <SamsclubWebItemDetailOrdering {...props} />;
  }

  getItemDetailFurtherDetails(props: WebItemProps): JSX.Element {
    return <SamsclubWebItemFurtherDetails {...props} />;
  }

  getItemDetailFeatures(props: WebItemProps): JSX.Element {
    return <></>;
  }
}
