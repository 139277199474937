import { DemoConfig, DemoConfigCompany } from "../../../model/pos/demo-config";
import { AbstractCompanyComponentResolver } from "../../resolver/abstract-company-resolver";
import { CostcoComponentResolver } from "../../resolver/costco-component-resolver";
import { CvsComponentResolver } from "../../resolver/cvs-component-resolver";
import { KrogerComponentResolver } from "../../resolver/kroger-component-resolver";
import { MeijerComponentResolver } from "../../resolver/meijer-component-resolver";
import { SamsclubComponentResolver } from "../../resolver/samsclub-component-resolver";
import { TargetComponentResolver } from "../../resolver/target-component-resolver";
import { WalgreensComponentResolver } from "../../resolver/walgreens-component-resolver";
import { WalmartComponentResolver } from "../../resolver/walmart-component-resolver";

export type Company =
  | "costco"
  | "cvs"
  | "kroger"
  | "meijer"
  | "samsclub"
  | "target"
  | "walgreens"
  | "walmart";

export type CompanyList = [Company, string, boolean];

export class CompanyResolver {
  private static readonly _instance = new CompanyResolver();

  static get(): CompanyResolver {
    return this._instance;
  }

  private componentResolver: AbstractCompanyComponentResolver | undefined;
  private demoConfig: DemoConfig<DemoConfigCompany> | undefined;

  private constructor() {}

  getCompany(): Company {
    return this.componentResolver?.companyName!;
  }

  getComponentResolver(): AbstractCompanyComponentResolver {
    if (!this.componentResolver)
      console.error("Resolver is being requested before it has been created");
    return this.componentResolver!;
  }

  getUserDemoPreference(): DemoConfig<DemoConfigCompany> | undefined {
    return this.demoConfig;
  }

  setUserDemoPreference(dc: DemoConfig<DemoConfigCompany>) {
    this.demoConfig = dc;
    switch (dc?.value?.activeCompany) {
      case "costco":
        this.componentResolver = new CostcoComponentResolver();
        break;
      case "cvs":
        this.componentResolver = new CvsComponentResolver();
        break;
      case "kroger":
        this.componentResolver = new KrogerComponentResolver();
        break;
      case "meijer":
        this.componentResolver = new MeijerComponentResolver();
        break;
      case "samsclub":
        this.componentResolver = new SamsclubComponentResolver();
        break;
      case "target":
        this.componentResolver = new TargetComponentResolver();
        break;
      case "walgreens":
        this.componentResolver = new WalgreensComponentResolver();
        break;
      case "walmart":
        this.componentResolver = new WalmartComponentResolver();
        break;
      default:
        console.error(
          `Do not know how to handle company ${dc?.value?.activeCompany}`
        );
        throw new Error(
          `Do not know how to handle company ${dc?.value?.activeCompany}`
        );
    }
    console.info(`Demoing ${dc?.value?.activeCompany}`);
  }

  // subdomain: "costco" as Company, //(window.location.hostname?.split(".")?.[0] ?? "kroger") as Company,
}
