import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import "./web-location-banner.scss";

export const CvsWebLocationBanner = () => {
  return (
    <div className="cvs-web-location-banner">
      <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
        <EuiText className="location-text"> 2424 N MAIN ST.</EuiText>
        <EuiFlexItem grow={false} className="closing-time-text">
          Closes 10PM
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
