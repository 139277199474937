import { Question } from "../../model/sanofi-otc/question";
import { cialisQuestions, cialisViews } from "../../utils/survey-constants";
import SurveyDoctorStop from "../common/survey/survey-doctor-stop/survey-doctor-stop";
import SurveyWarning from "../common/survey/survey-warning/survey-warning";
import { AbstractProductComponentResolver } from "./abstract-product-resolver";

export class CialisComponentResolver extends AbstractProductComponentResolver {
  constructor() {
    super("cialis");
  }

  getViews(): string[] {
    return cialisViews;
  }

  getQuestions(): Question[] {
    return cialisQuestions;
  }

  getDoctorStop(): JSX.Element {
    const statements = [
      "Taking over the counter Cialis® is not safe for you and you should speak to a healthcare professional.",
      "Your ED treatment should be under a doctor's supervision.",
    ];

    return (
      <SurveyDoctorStop product={this.productName} statements={statements} />
    );
  }

  getSurveyWarning(): JSX.Element {
    const bulletStatements = [
      "Will NOT work if you do not have ED",
      "Will NOT increase the size of your penis",
      "Will NOT help with premature ejaculation",
      "Is NOT for women",
    ];
    const paragraphStatements = [
      "Cialis® OTC is also not for men with certain health conditions or taking certain medications. This survey will check for those conditions and medications.",
    ];
    const paragraphStatementsBold: string[] = [
      'By clicking "Continue" I confirm that I will answer the survey truthfully. Otherwise, it could lead to serious health consequences including blindness and heart attack.',
    ];

    return (
      <SurveyWarning
        product={this.productName}
        bulletStatements={bulletStatements}
        paragraphStatements={paragraphStatements}
        paragraphStatementsBold={paragraphStatementsBold}
      />
    );
  }
}
