import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiImage,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import "./cart-page-item.scss";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { WebItemProps } from "../../company/common/web/web-item-props";
import { toCurrency } from "../../utils/text-utils";

export type CartPageItemProps = {
  quantity: number;
  onQuantityChange: (newQuantity: number) => void;
} & WebItemProps;

/**
 * A component for displaying a cart item
 */
const CartPageItem = ({
  item,
  quantity,
  onQuantityChange,
}: CartPageItemProps) => {
  const updateItemCount = useCallback(
    (newValue: string) => {
      const parsedInt = parseInt(newValue);
      if (!Number.isNaN(parsedInt)) return;
      else onQuantityChange(parsedInt);
    },
    [onQuantityChange]
  );

  const decrement = useCallback(() => {
    onQuantityChange(quantity - 1);
  }, [onQuantityChange, quantity]);

  const increment = useCallback(() => {
    onQuantityChange(quantity + 1);
  }, [onQuantityChange, quantity]);

  return (
    <EuiFlexGroup
      className="cart-page-item"
      alignItems="flexStart"
      justifyContent="flexStart"
      direction="column"
      gutterSize="s"
    >
      <EuiFlexGroup className="top">
        <EuiFlexItem grow={false}>
          <EuiImage src={item.imageUrl} alt="img alternate" />
        </EuiFlexItem>
        <EuiFlexGroup
          className="cart-page-item-detail"
          direction="column"
          justifyContent="flexStart"
          gutterSize="none"
        >
          <Link to={`/web/detail/${item.uid}`}>
            <EuiText className="item-details--name">{item.name}</EuiText>
          </Link>
          <EuiSpacer size="m" />
          <EuiText size="xs">Item 269852</EuiText>
          <EuiSpacer size="s" />
          <EuiFlexItem grow={false}>
            <EuiText className="item-details--price-values">
              {toCurrency(item.price)}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>

      <EuiFlexGroup
        className="middle"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexItem grow={false}>
          <EuiFieldText
            value={quantity}
            onChange={(evt) => updateItemCount(evt.target.value)}
            prepend={<EuiIcon type={"minus"} onClick={decrement} />}
            append={<EuiIcon type={"plus"} onClick={increment} />}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column" gutterSize="xs" alignItems="flexEnd">
            <EuiText>Total</EuiText>
            <EuiText>${(quantity * item.price).toFixed(2)}</EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexItem className="bottom" grow={false}>
        Remove
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default CartPageItem;
