import { useEffect, useState } from "react";
import "./setup-page.scss";
import { QubesService } from "../../services/qubes-service";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiSelectOption,
  useGeneratedHtmlId,
} from "@elastic/eui";
import {
  Company,
  CompanyResolver,
} from "../../company/common/web/company-resolver";
import { toTitleCase } from "../../utils/text-utils";
import {
  Product,
  ProductResolver,
} from "../../product/common/survey/product-resolver";

/**
 * The self serve Kiosk demo page
 * @returns {JSX.Element}
 */
const SetupPage = (): JSX.Element => {
  const [companyOptions, setCompanyOptions] = useState<EuiSelectOption[]>();
  const [selectedCompany, setSelectedCompany] = useState<string>(
    CompanyResolver.subdomain
  );
  const companySelectId = useGeneratedHtmlId({ prefix: "companySelect" });

  const [productOptions, setProductOptions] = useState<EuiSelectOption[]>();
  const [selectedProduct, setSelectedProduct] = useState<string>(
    ProductResolver.subdomain
  );
  const productSelectId = useGeneratedHtmlId({ prefix: "productSelect" });

  const onCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const c: Company = e.target.value as Company;
    setSelectedCompany(c);
    CompanyResolver.subdomain = c;
    QubesService.updateKeyValue({
      ky: "eZA6gI3su3A6ct9UIn8WyNsUTUNITXip",
      value: c,
    });
  };

  const onProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const p: Product = e.target.value as Product;
    setSelectedProduct(p);
    ProductResolver.subdomain = p;
    QubesService.updateKeyValue({
      ky: "qqYbc6GhFAYEcAQar6yxcmiaGU5YMawx",
      value: p,
    });
  };

  /**
   * Load the data model
   */
  useEffect(() => {
    QubesService.loadAvailableCompanies()
      .then((resp) => {
        if (resp?.data?.[0]) {
          const companies: Company[] = JSON.parse(resp.data[0].value as string);
          setCompanyOptions(
            companies.map((c: Company) => {
              return { value: c, text: toTitleCase(c) };
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });

    QubesService.loadAvailableProducts()
      .then((resp) => {
        if (resp?.data?.[0]) {
          const products: Product[] = JSON.parse(resp.data[0].value as string);
          setProductOptions(
            products.map((p: Product) => {
              return { value: p, text: toTitleCase(p) };
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [setCompanyOptions, setProductOptions]);

  return (
    <div className="setup-page-container">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup className="form-row" alignItems="center">
          <EuiFlexItem>Company</EuiFlexItem>
          <EuiFlexItem>
            <EuiSelect
              id={companySelectId}
              options={companyOptions}
              value={selectedCompany}
              onChange={(e) => onCompanyChange(e)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup className="form-row" alignItems="center">
          <EuiFlexItem>Product</EuiFlexItem>
          <EuiFlexItem>
            <EuiSelect
              id={productSelectId}
              options={productOptions}
              value={selectedProduct}
              onChange={(e) => onProductChange(e)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </div>
  );
};

export default SetupPage;
