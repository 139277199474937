import { Insertable, UidKey } from "../model/common";
import { ItemMaster, ItemMasterFieldList } from "../model/pos/item-master";
import {
  IPointOfSaleEvent,
  PointOfSaleEvent,
  PointOfSaleEventFields,
} from "../model/sanofi-otc/point-of-sale-event";
import { Receipt, ReceiptFieldList } from "../model/pos/receipt";
import { ReceiptLineItem } from "../model/pos/receipt-line-item";
import { Register, RegisterFieldList } from "../model/pos/register";
import { QubesAPI } from "../qubes-client/api";
import { BiqlQueryResponse } from "../qubes-client/protocol/biql-query.protocol";
import { CrudJson } from "../qubes-client/protocol/crud.protocol";
import { POSStore, POSStoreFieldList } from "../model/sanofi-otc/pos-store";
import { QRCode, QRCodeFieldList } from "../model/sanofi-otc/qr-code";
import { Customer, CustomerFields } from "../model/sanofi-otc/customer";
import { Survey } from "../model/sanofi-otc/survey";
import { CompanyResolver } from "../company/common/web/company-resolver";
import { KeyValue } from "../model/answer";

/**
 * Service to wrap the {@link QubesAPI} client with common api
 * calls need to support the application
 */
export const QubesService = {
  // ************************ QUERIES ************************ //

  /**
   * Loads all active instances of {@link POSStore}
   * @returns a Promise BiqlQueryResponse<POSStore>
   */
  loadStores: async (): Promise<BiqlQueryResponse<POSStore>> => {
    return QubesAPI.get().biqlQuery<POSStore>(
      `from store 
        select ${POSStoreFieldList.join()} 
        where active = true`,
      "sanofi",
      "verbose"
    );
  },

  /**
   * Loads all active instances of {@link Register}
   * @returns a Promise BiqlQueryResponse<Register>
   */
  loadRegisters: async (): Promise<BiqlQueryResponse<Register>> => {
    return QubesAPI.get().biqlQuery<Register>(
      `from register 
        select ${RegisterFieldList.join()}
        where active = true`,
      "point_of_sale",
      "verbose"
    );
  },

  /**
   * Loads all active instances of {@link ItemMaster}
   * @returns a Promise BiqlQueryResponse<Item>
   */
  loadItems: async () => {
    return QubesAPI.get().biqlQuery<ItemMaster>(
      `from itemmaster 
        select ${ItemMasterFieldList.join()} 
        where active = true ${CompanyResolver.getItemFilters()}`,
      "point_of_sale",
      "verbose"
    );
  },

  /**
   * Load the POSCorrelationCode record of the specified code
   * @param {string} qrCode - the code
   * @returns a Promise BiqlQueryResponse<QRCode>
   */
  loadQRCode: async (qrCode: string) => {
    return QubesAPI.get().biqlQuery<QRCode>(
      `from poscorrelationcode 
        select ${QRCodeFieldList.join()} 
        where correlationCode = '${qrCode}' and active = true`,
      "sanofi",
      "verbose"
    );
  },

  /**
   * Load the POS correlation code record of the specified code
   * @param {string} uid - the code
   * @returns a Promise BiqlQueryResponse<QRCode>
   */
  loadPosEvent: async (uid: string) => {
    return QubesAPI.get().biqlQuery<IPointOfSaleEvent>(
      `from pointofsaleevent 
        select ${PointOfSaleEventFields.join()}
        where uid = '${uid}'`,
      "sanofi",
      "verbose"
    );
  },

  /**
   * Load the POS correlation code record of the specified code
   * @param {string} qrCode - the code
   * @returns a Promise BiqlQueryResponse<QRCode>
   */
  loadPosEventResult: async (qrCode: string) => {
    return QubesAPI.get().biqlQuery<IPointOfSaleEvent>(
      `from pointofsaleevent 
        select ${PointOfSaleEventFields.join()}
        where rawcorrelationCode = '${qrCode}' and active = true`,
      "sanofi",
      "verbose"
    );
  },

  /**
   * Load the Customers
   * @returns a Promise BiqlQueryResponse<Customer>
   */
  loadCustomersResult: async () => {
    return QubesAPI.get().biqlQuery<Customer>(
      `from customer 
        select ${CustomerFields.join()}
        where active = true`,
      "sanofi",
      "verbose"
    );
  },

  /**
   * Load the given Receipt
   * @returns a Promise BiqlQueryResponse<Receipt>
   */
  loadReceipt: async (receiptUid: string) => {
    return QubesAPI.get().biqlQuery<Receipt>(
      `from customer 
        select ${ReceiptFieldList.join()}
        where uid = '${receiptUid}' active = true`,
      "sanofi",
      "verbose"
    );
  },

  loadAvailableCompanies: async () => {
    return QubesAPI.get().biqlQuery<KeyValue>(
      `from KeyValue
        select ky, value
        where ky = 'ta6y79c3Cvd8moAptjZvaoePwiQkP8X3'`,
      "qubes",
      "verbose"
    );
  },

  loadActiveCompany: async () => {
    return QubesAPI.get().biqlQuery<KeyValue>(
      `from KeyValue
        select ky, value
        where ky = 'eZA6gI3su3A6ct9UIn8WyNsUTUNITXip'`,
      "qubes",
      "verbose"
    );
  },

  loadAvailableProducts: async () => {
    return QubesAPI.get().biqlQuery<KeyValue>(
      `from KeyValue
        select ky, value
        where ky = 'Lnl7PObLnzmu3rXhM1QtfKnx1yWjHLYk'`,
      "qubes",
      "verbose"
    );
  },

  loadActiveProduct: async () => {
    return QubesAPI.get().biqlQuery<KeyValue>(
      `from KeyValue
        select ky, value
        where ky = 'qqYbc6GhFAYEcAQar6yxcmiaGU5YMawx'`,
      "qubes",
      "verbose"
    );
  },

  // ************************ CRUDs ************************ //

  /**
   * Inserts a receipt. The type {@link Receipt}
   * @param {Receipt} receipt - the receipt to insert
   * @returns the result of the insert as a `CrudResponse<UidKey>`
   */
  insertReceipt: async (receipt: Insertable<Receipt>) => {
    const receiptInsertJson: CrudJson<Receipt> = {
      cube: "receipt",
      appSpace: "point_of_sale",
      fields: [
        ["active", true],
        ["registerUid", receipt.registerUid],
      ],
      parameters: [],
    };
    return QubesAPI.get().insertRow<Receipt, UidKey>(receiptInsertJson);
  },

  /**
   * Inserts a receipt. The type {@link ReceiptLineItem}
   * @param {ReceiptLineItem} receiptLineItem - the receiptLineItem to insert
   * @returns the result of the insert as a `CrudResponse<UidKey>`
   */
  insertReceiptLineItem: async (
    receiptLineItem: Insertable<ReceiptLineItem>
  ) => {
    const receiptInsertJson: CrudJson<ReceiptLineItem> = {
      cube: "ReceiptLineItem",
      appSpace: "point_of_sale",
      fields: [
        ["receiptUid", receiptLineItem.receiptUid],
        ["itemUid", receiptLineItem.itemUid],
        ["quantity", receiptLineItem.quantity],
        ["active", true],
      ],
      parameters: [],
    };
    return QubesAPI.get().insertRow<ReceiptLineItem, UidKey>(receiptInsertJson);
  },

  /**
   * Inserts a POS Event. The type {@link PointOfSaleEvent}
   * @param {PointOfSaleEvent} posEvent - the posEvent to insert
   * @returns the result of the insert as a `CrudResponse<UidKey>`
   */
  insertPOSEvent: async (posEvent: Insertable<PointOfSaleEvent>) => {
    const posEventInsertJson: CrudJson<PointOfSaleEvent> = {
      cube: "PointOfSaleEvent",
      appSpace: "sanofi",
      fields: [
        ["active", true],
        ["correlationCodeUid", posEvent.correlationCodeUid],
        ["initiated", new Date().toISOString()],
        ["quantity", posEvent.quantity],
        ["rawCorrelationCode", posEvent.rawCorrelationCode],
        ["registerId", posEvent.registerId],
        ["status", posEvent.status],
        ["store", posEvent.store],
        // ["saleCompleted", new Date().toISOString()], // we should update this after the sale is complete
        // ["quantity", posEvent.quantity], // we should update this after the sale is complete
        // ["upc", posEvent.upc], // we should update this after the sale is complete
      ],
      parameters: [],
    };
    return QubesAPI.get().insertRow<PointOfSaleEvent, UidKey>(
      posEventInsertJson
    );
  },

  /**
   * Updates a POS Event. The type {@link IPointOfSaleEvent}
   * @param {IPointOfSaleEvent} posEvent - the posEvent to update
   * @returns the result of the insert as a `CrudResponse<UidKey>`
   */
  updatePOSEvent: async (posEvent: IPointOfSaleEvent) => {
    const posEventInsertJson: CrudJson<PointOfSaleEvent> = {
      cube: "PointOfSaleEvent",
      appSpace: "sanofi",
      fields: [
        ["uid", posEvent.uid],
        ["active", posEvent.active],
        // ["correlationCodeUid", posEvent.correlationCodeUid], we should not be updating this field
        // ["initiated", new Date().toISOString()], we should not be updating this field
        // ["rawCorrelationCode", posEvent.rawCorrelationCode], we should not be updating this field
        // ["registerId", posEvent.registerId], we should not be updating this field
        // ["store", posEvent.store], we should not be updating this field
        ["status", posEvent.status],
        ["saleCompleted", posEvent.saleCompleted],
        ["quantity", posEvent.quantity],
        ["upc", posEvent.upc],
      ],
      parameters: [],
    };
    return QubesAPI.get().updateRow<PointOfSaleEvent, UidKey>(
      posEventInsertJson
    );
  },

  /**
   * Inserts a Survey. The type {@link Survey}
   * @param {Survey} survey - the survey to insert
   * @returns the result of the insert as a `CrudResponse<UidKey>`
   */
  insertSurvey: async (survey: Insertable<Survey>) => {
    const surveyInsertJson: CrudJson<Survey> = {
      cube: "Survey",
      appSpace: "sanofi",
      fields: [
        ["active", true],
        ["startedAt", new Date().toISOString()],
        ["status", survey.status],
        ["customerUid", survey.customerUid],
        ["surveyData", survey.surveyData],
        ["surveyId", survey.surveyId],
      ],
      parameters: [],
    };
    return QubesAPI.get().insertRow<Survey, UidKey>(surveyInsertJson);
  },

  /**
   * Updates a Survey. The type {@link Survey}
   * @param {Survey} survey - the Survey to update
   * @returns the result of the update as a `CrudResponse<UidKey>`
   */
  updateSurvey: async (survey: Survey) => {
    const surveyUpdateJson: CrudJson<Survey> = {
      cube: "Survey",
      appSpace: "sanofi",
      fields: [
        ["uid", survey.uid],
        ["active", survey.active],
        // ["startedAt", survey.startedAt], we should not be updating this field
        // ["customerUid", survey.customerUid], we should not be updating this field
        // ["zip", survey.zip], we should not be updating this field
        ["status", survey.status],
        ["completedAt", survey.completedAt],
      ],
      parameters: [],
    };
    return QubesAPI.get().updateRow<Survey, UidKey>(surveyUpdateJson);
  },

  /**
   * Inserts a QRCode. The type {@link QRCode}
   * @param {QRCode} qrCode - the qrCode to insert
   * @returns the result of the insert as a `CrudResponse<UidKey>`
   */
  insertQRCode: async (qrCode: Insertable<QRCode>) => {
    const qrCodeInsertJson: CrudJson<QRCode> = {
      cube: "poscorrelationcode",
      appSpace: "sanofi",
      fields: [
        ["active", true],
        ["correlationCode", qrCode.correlationCode],
        ["expirationDate", qrCode.expirationDate],
        ["surveyUid", qrCode.surveyUid],
      ],
      parameters: [],
    };
    return QubesAPI.get().insertRow<QRCode, UidKey>(qrCodeInsertJson);
  },

  /**
   * Updates a KeyValue entry.
   */
  updateKeyValue: async (kv: KeyValue) => {
    const companyConfigUpdateJson: CrudJson<KeyValue> = {
      cube: "keyvalue",
      appSpace: "qubes",
      fields: [
        ["ky", kv.ky],
        ["value", kv.value],
      ],
      parameters: [],
    };
    return QubesAPI.get().updateRow<KeyValue, string>(companyConfigUpdateJson);
  },
};
