import { EuiFlexGroup, EuiText } from "@elastic/eui";
import FakeLink from "../../../../components/fake-link/fake-link";

export const CostcoWebCartDeliveryInfo = () => {
  return (
    <EuiFlexGroup
      className="cart-delivery-info"
      direction="column"
      gutterSize="s"
    >
      <EuiText className="delivery-info-title">2-Day Delivery</EuiText>
      <EuiText className="delivery-info-details">
        2-Day Delivery orders are delivered within 2 business days when ordered
        by 12 p.m.
      </EuiText>
      <FakeLink text="Delivery Details" />
    </EuiFlexGroup>
  );
};
