import ENV from "../../../services/env-service";
import {
  aspirinQuestions,
  aspirinViews,
  cialisQuestions,
  cialisViews,
} from "../../../utils/survey-constants";
import { toTitleCase } from "../../../utils/text-utils";
import SurveyED from "../../cialis/survey-ed/survey-ed";
import SurveyCode, { SurveyCodeProps } from "./survey-code/survey-code";
import SurveyDoctorStop from "./survey-doctor-stop/survey-doctor-stop";
import SurveyLandingWeb, {
  SurveyLandingProps,
} from "./survey-landing-web/survey-landing-web";
import SurveyLanding from "./survey-landing/survey-landing";
import SurveyQuestion, {
  SurveyQuestionProps,
} from "./survey-question/survey-question";
import SurveyStop, { SurveyStopProps } from "./survey-stop/survey-stop";
import SurveyWarning from "./survey-warning/survey-warning";
import SurveyWelcome, {
  SurveyWelcomeProps,
} from "./survey-welcome/survey-welcome";
import SurveyWrapper, {
  SurveyWrapperProps,
} from "./survey-wrapper/survey-wrapper";

export type Product = "cialis" | "super aspirin";

export const ProductResolver = {
  subdomain: "cialis", // window.location.hostname?.split(".")?.[0] ?? "cialis",

  subdomainToProduct: (noErrors: boolean = false): Product => {
    switch (ProductResolver.subdomain) {
      case "cialis":
        return "cialis";
      case "super-aspirin":
        return "super aspirin";
      default:
        if (noErrors) return "cialis";
        else
          throw new Error(
            "don't know how to handle " + ProductResolver.subdomain
          );
    }
  },

  getRootPageContents: (reroute: (url: string) => void) => {
    switch (ProductResolver.subdomain) {
      case "cialis":
      case "super-aspirin": {
        return (
          <>
            <div
              className="button survey"
              onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/survey`)}
            >
              <div className="text">
                {toTitleCase(ProductResolver.subdomain)} HSA
              </div>
            </div>
          </>
        );
      }
      default: {
        return (
          <>
            <div
              className="button survey"
              onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/survey`)}
            >
              <div className="text">
                {toTitleCase(ProductResolver.subdomain)} HSA
              </div>
            </div>
          </>
        );
      }
    }
  },

  getViews: (product: Product) => {
    switch (product) {
      case "cialis": {
        return cialisViews;
      }
      case "super aspirin": {
        return aspirinViews;
      }
      default:
        throw new Error("don't know how to handle" + product);
    }
  },

  getQuestions: (product: Product) => {
    switch (product) {
      case "cialis": {
        return cialisQuestions;
      }
      case "super aspirin": {
        return aspirinQuestions;
      }
      default:
        throw new Error("don't know how to handle" + product);
    }
  },

  getProductWrapper: (props: SurveyWrapperProps) => {
    return <SurveyWrapper {...props} />;
  },

  getDoctorStop: (product: Product) => {
    let statements: string[];
    switch (product) {
      case "cialis": {
        statements = [
          "Taking over the counter Cialis® is not safe for you and you should speak to a healthcare professional.",
          "Your ED treatment should be under a doctor's supervision.",
        ];
        break;
      }
      case "super aspirin": {
        statements = [
          "Taking over the counter Super Aspirin® is not safe for you and you should speak to a healthcare professional.",
          "Your medical treatment should be under a doctor's supervision.",
        ];
        break;
      }
      default:
        throw new Error("don't know how to handle" + product);
    }
    return <SurveyDoctorStop product={product} statements={statements} />;
  },

  getSurveyStop: (props: SurveyStopProps) => {
    return <SurveyStop {...props} />;
  },

  getSurveyLandingPage: (props: SurveyLandingProps) => {
    return <SurveyLanding {...props} />;
  },

  getSurveyLandingPageWeb: (props: SurveyLandingProps) => {
    return <SurveyLandingWeb {...props} />;
  },

  getSurveyWelcome: (props: SurveyWelcomeProps) => {
    return <SurveyWelcome {...props} />;
  },

  getSurveyWarning: (product: Product) => {
    let bulletStatements: string[];
    let paragraphStatements: string[];
    let paragraphStatementsBold: string[] = [
      'By clicking "Continue" I confirm that I will answer the survey truthfully. Otherwise, it could lead to serious health consequences including blindness and heart attack.',
    ];
    switch (product) {
      case "cialis": {
        bulletStatements = [
          "Will NOT work if you do not have ED",
          "Will NOT increase the size of your penis",
          "Will NOT help with premature ejaculation",
          "Is NOT for women",
        ];
        paragraphStatements = [
          "Cialis® OTC is also not for men with certain health conditions or taking certain medications. This survey will check for those conditions and medications.",
        ];
        break;
      }
      case "super aspirin": {
        bulletStatements = [];
        paragraphStatements = [
          "Super Aspirin® OTC is also not for persons with certain health conditions or taking certain medications. This survey will check for those conditions and medications.",
        ];
        break;
      }
      default:
        throw new Error("don't know how to handle" + product);
    }
    return (
      <SurveyWarning
        product={product}
        bulletStatements={bulletStatements}
        paragraphStatements={paragraphStatements}
        paragraphStatementsBold={paragraphStatementsBold}
      />
    );
  },

  getSurveyCode: (props: SurveyCodeProps) => {
    return <SurveyCode {...props} />;
  },

  getSurveyQuestion: (props: SurveyQuestionProps) => {
    return <SurveyQuestion {...props} />;
  },

  getSurveyEd: () => {
    return <SurveyED />;
  },
};
