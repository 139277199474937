import { EuiProvider } from "@elastic/eui";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";
import "./App.scss";
import AppRoutes from "./routes/route";
import "@elastic/eui/dist/eui_theme_light.css";
import { Provider } from "react-redux";
import { store } from "./state";

function App() {
  /**
   * Run only once and log out any build properties
   */
  useEffect(() => {
    const propertiesMap: Record<string, string | undefined> = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
      REACT_ROUTER_BASENAME: process.env.REACT_APP_ROUTER_BASENAME,
    };

    console.log("***** ENVIRONMENT *****");
    Object.keys(propertiesMap).forEach((propKey) => {
      console.log(`${propKey}: ${propertiesMap[propKey]}`);
    });
    console.log("***** ENVIRONMENT *****");
  }, []);

  return (
    <EuiProvider colorMode="light">
      <Provider store={store}>
        {/* <GoogleOAuthProvider clientId="683532840212-iscfd98i72bhko477moeuun1p83r86ne.apps.googleusercontent.com"> */}
        {/* <GoogleOAuthProvider clientId="683532840212-2s96mcichhrbosshuv51k29as234b81l.apps.googleusercontent.com"></GoogleOAuthProvider> */}
        <div className="_app">
          <AppRoutes />
        </div>
        {/* </GoogleOAuthProvider> */}
      </Provider>
    </EuiProvider>
  );
}

export default App;
