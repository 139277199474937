import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import { CostcoLogo, ShoppingCartLogoBlue } from "../../../../assets/assets";
import FakeLink from "../../../../components/fake-link/fake-link";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";
import "./web-session-banner.scss";

export const CostcoWebSessionBanner = ({
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="costco-web-session-banner">
      <div className="web-session-top-banner">
        Online Savings Starts Today, Shop Now!
      </div>
      <div className="web-session-links-banner">
        <FakeLink text="Costco Next" />
        <FakeLink text="While Supplies Last" />
        <FakeLink text="Online-Only" />
        <FakeLink text="Treasure Hunt" />
        <FakeLink text="What's New" />
        <FakeLink text="New Lower Prices" />
        <FakeLink text="Get Email Offers" />
        <FakeLink text="Customer Service" />
      </div>
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceAround"
        alignItems="center"
      >
        <EuiFlexItem grow={false}>
          <Link to={"/web"}>
            <EuiImage className="costco-logo" src={CostcoLogo} alt="" />
          </Link>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFieldSearch
            className="search-box"
            placeholder="Search"
            fullWidth
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} className="links">
          <EuiFlexGroup
            gutterSize="s"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <FakeLink text="Account" />
            <div className="divider" />
            <FakeLink text="Orders & Returns" />
            <div className="divider" />
            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <img
                  className="shopping-cart-image"
                  src={ShoppingCartLogoBlue}
                  alt="shopping cart logo"
                />
                {shoppingCartQuantity > 0 ? (
                  <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                    {shoppingCartQuantity}
                  </EuiFlexItem>
                ) : (
                  <EuiFlexItem className="shopping-cart-quantity-placeholder"></EuiFlexItem>
                )}

                <FakeLink text="Cart" />
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
