import "./fake-link.scss";

export type FakeLinkProps = {
  text: string;
};

/**
 * A component that behaves and is styled like a link,
 * but in reality goes nowhere
 */
const FakeLink = ({ text }: FakeLinkProps) => {
  return <div className="fake-link">{text}</div>;
};

export default FakeLink;
