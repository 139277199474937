import SurveyPageWeb from "../survey-web/survey-page-web";
import { ProductResolver } from "../../product/common/survey/product-resolver";
import { useMemo } from "react";
import "./survey-page-wrapper.scss";

/**
 * Wraps the surveys and provides the correct survey component based upon the provided source
 * @returns {JSX.Element}
 */
const SurveyPageWrapper = (): JSX.Element => {
  const memoizedComponent = useMemo(() => {
    return (
      <SurveyPageWeb
        product={ProductResolver.get().getProduct()}
        isWeb={false}
        onNewSurveyCode={() => {}}
        onClose={() => {}}
      />
    );
  }, []);

  return <div className="survey-page-wrapper">{memoizedComponent}</div>;
};

export default SurveyPageWrapper;
