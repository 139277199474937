import { StopSign } from "../../../../assets/assets";
import { toTitleCase } from "../../../../utils/text-utils";
import { Product } from "../product-resolver";
import "./survey-doctor-stop.scss";

export type SurveyDoctorStopProps = {
  product: Product;
  statements: string[];
};

const SurveyDoctorStop = ({ product, statements }: SurveyDoctorStopProps) => {
  return (
    <div className="survey-doctor-stop-container">
      <div className="top">
        <img src={StopSign} alt="stopsign" />
        <div className="warning-text">
          Do Not Use {toTitleCase(product)}® OTC.
        </div>
      </div>
      <div className="bold-text">
        You will not be able to purchase {toTitleCase(product)}® OTC.
      </div>
      {statements.map((statement) => {
        return <div className="text">{statement}</div>;
      })}
      <div className="text">
        Thank you for completing this health survey assessment.
      </div>
    </div>
  );
};

export default SurveyDoctorStop;
