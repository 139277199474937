import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";
import "./survey-page-web.scss";
import {
  Product,
  ProductResolver,
} from "../../product/common/survey/product-resolver";
import { QubesService } from "../../services/qubes-service";
import { Survey, SurveyData } from "../../model/sanofi-otc/survey";
import { Customer } from "../../model/sanofi-otc/customer";
import { Answer } from "../../model/sanofi-otc/answer";
import { Question } from "../../model/sanofi-otc/question";
import { getProductThemeOverrides } from "./theme-provider";
import { completeSurvey, startNewSurvey } from "../survey/survey-helpers";
import { SurveyLandingProps } from "../../product/common/survey/survey-landing-web/survey-landing-web";

export type SurveyPageWebProps = {
  product: Product;
  isWeb: boolean;
  onNewSurveyCode: (newSurveyCode: string, qrUid: string) => void;
  onClose: (addToCart: boolean) => void;
};

/**
 * The main page for the Survey that controls which view is currently being rendered
 * @returns {JSX.Element}
 */
const SurveyPageWeb = ({
  product,
  isWeb,
  onNewSurveyCode,
  onClose,
}: SurveyPageWebProps): JSX.Element => {
  // The current content that should be rendered
  const [currentContent, setCurrentContent] = useState<string>(
    ProductResolver.getViews(product)[0]
  );

  // Show doctor stop page and end survey
  const [showDoctorStop, setShowDoctorStop] = useState<boolean>(false);

  // Show regular stop page and end survey
  const [showStop, setShowStop] = useState<boolean>(false);

  // The current question we are on
  const [currentQuestion, setCurrentQuestion] = useState<Question>(
    ProductResolver.getQuestions(product)[0]
  );

  // The answer to the current question
  const [currentAnswer, setCurrentAnswer] = useState<string>("unanswered");

  // The current instance of the survey
  const [currentSurvey, setCurrentSurvey] = useState<Survey>();

  // The POS Correlation Code (QRCode) of the completed survey
  const [currentQRCode, setCurrentQRCode] = useState<string>(
    Math.random().toString(36).slice(2, 9).toUpperCase()
  );

  // The expiration date
  const [expirationDate, setExpirationDate] = useState<string>();

  const currentQRCodeRef = useRef<string>();

  useEffect(() => {
    currentQRCodeRef.current = currentQRCode;
  }, [currentQRCode]);

  const [currentCustomer, setCurrentCustomer] = useState<Customer>();

  const handleSurveyStarted = useCallback(async () => {
    if (currentCustomer) {
      startNewSurvey(currentCustomer, product, setCurrentSurvey);
    }
  }, [currentCustomer, product]);

  useEffect(() => {
    //load the customer
    QubesService.loadCustomersResult()
      .then((resp) => {
        if (resp.data?.[0]) {
          setCurrentCustomer(resp.data[0]);
        } else {
          throw new Error("Unable to load customer");
        }
      })
      .catch((err: unknown) => {
        console.error(err);
      });
  }, []);

  // Reset the current answer to "unanswered" when the current question changes
  useEffect(() => {
    setCurrentAnswer("unanswered");
  }, [currentQuestion]);

  useEffect(() => {
    const newAnswer: Answer = {
      uid: v4(),
      questionUid: currentQuestion.uid,
      response: currentAnswer,
      extraConfig: {},
    };

    setCurrentSurvey((oldSurvey) => {
      if (!oldSurvey) return;

      const surveyDataClone: SurveyData =
        cloneDeep(oldSurvey?.surveyData) || {};
      const surveyClone: Survey = cloneDeep(oldSurvey) || ({} as Survey);

      surveyDataClone[currentQuestion.uid] = newAnswer;

      surveyClone.surveyData = surveyDataClone;

      return surveyClone;
    });
  }, [currentAnswer, currentQuestion.uid]);

  const handleSurveyComplete = useCallback(
    async (correlationCode?: string) => {
      if (!currentCustomer) {
        console.error("Customer is required");
        return;
      }
      if (!currentSurvey) {
        console.error("Current survey is not defined");
        return;
      }
      completeSurvey(
        currentCustomer,
        currentSurvey,
        setCurrentSurvey,
        setCurrentSurvey,
        setExpirationDate,
        onNewSurveyCode,
        correlationCode
      );
    },
    [currentCustomer, currentSurvey, onNewSurveyCode]
  );

  useEffect(() => {
    if (showDoctorStop || showStop) {
      handleSurveyComplete();
    } else if (currentContent === "code") {
      handleSurveyComplete(currentQRCodeRef.current);
    }
    // eslint-disable-next-line
  }, [currentContent, showDoctorStop, showStop]); //TODO adding handleSurveyComplete creates an infinite loop. Fix this at some point

  /**
   * Render the appropriate content
   */
  const renderContent = useMemo(() => {
    switch (currentContent) {
      case "code":
        return ProductResolver.getSurveyCode({
          product,
          expiriDate: expirationDate || "",
          code: currentQRCode,
        });
      case "questions":
        return ProductResolver.getSurveyQuestion({
          answer: (answer: string) => setCurrentAnswer(answer),
          question: currentQuestion,
        });
      case "warning":
        return ProductResolver.getSurveyWarning(product);
      case "ed":
        return ProductResolver.getSurveyEd();
      default:
        return ProductResolver.getSurveyWelcome({ product });
    }
  }, [currentContent, currentQRCode, currentQuestion, expirationDate, product]);

  /**
   * Handles resetting the survey back to the original state
   */
  const handleResetSurvey = useCallback(() => {
    setCurrentContent(ProductResolver.getViews(product)[0]);
    setShowDoctorStop(false);
    setShowStop(false);
    setCurrentAnswer("unanswered");
    setCurrentQuestion(ProductResolver.getQuestions(product)[0]);
    setCurrentQRCode(Math.random().toString(36).slice(2, 9).toUpperCase());
    onClose(true);
  }, [onClose, product]);

  /**
   * Render the right main view
   */
  const renderMainView = useMemo(() => {
    /**
     * Handle navigating to the next view
     */
    const iterateContent = () => {
      if (!currentContent) return;

      if (currentContent === "questions") {
        if (currentAnswer === "unanswered") {
          return;
        } else if (
          currentAnswer !== currentQuestion.acceptedAnswer &&
          currentQuestion.isDoctorQuestion
        ) {
          setShowDoctorStop(true);
          return;
        } else if (currentAnswer !== currentQuestion.acceptedAnswer) {
          setShowStop(true);
          return;
        } else if (
          currentQuestion.order >= ProductResolver.getQuestions(product).length
        ) {
          // no op so we continue with the normal flow iteration
        } else {
          setCurrentQuestion(
            ProductResolver.getQuestions(product)[currentQuestion.order]
          );
          return;
        }
      }

      // Grab the index of the current content
      const currentIndex = ProductResolver.getViews(product).findIndex(
        (view) => view === currentContent
      );

      if (currentIndex === ProductResolver.getViews(product).length - 1) {
        if (!currentQRCode) {
          console.log("Error: No QR code created.");
        } else {
          handleResetSurvey();
        }
      } else {
        // Move on to the next view
        setCurrentContent(ProductResolver.getViews(product)[currentIndex + 1]);
      }
    };

    if (showDoctorStop) {
      // If the customer needs to speak with a doctor
      // we will render the doctor stop and exit path
      return ProductResolver.getProductWrapper({
        iterateContent: handleResetSurvey,
        atEnd: true,
        isWeb,
        onCancel: () => onClose(false),
        children: ProductResolver.getDoctorStop(product),
        product,
      });
    } else if (showStop) {
      // If the drug is unsafe for the customer
      // we will render the stop and exit path
      return ProductResolver.getProductWrapper({
        iterateContent: handleResetSurvey,
        atEnd: true,
        isWeb,
        onCancel: () => onClose(false),
        children: ProductResolver.getSurveyStop({ product }),
        product,
      });
    } else if (currentContent === "landing") {
      // If the survey just started
      const props: SurveyLandingProps = {
        product,
        iterateContent: () => {
          handleSurveyStarted();
          setCurrentContent(ProductResolver.getViews(product)[1]);
        },
        onCancel: () => onClose(false),
      };
      if (isWeb) return ProductResolver.getSurveyLandingPageWeb(props);
      else return ProductResolver.getSurveyLandingPage(props);
    } else {
      // If we are in the normal path

      const index = ProductResolver.getViews(product).findIndex(
        (view) => view === currentContent
      );

      return ProductResolver.getProductWrapper({
        iterateContent: iterateContent,
        atEnd: index === ProductResolver.getViews(product).length - 1 || false,
        isWeb,
        onCancel: () => onClose(false),
        codeGenerated: !!currentQRCode,
        children: renderContent,
        product,
      });
    }
  }, [
    currentAnswer,
    currentContent,
    currentQRCode,
    currentQuestion.acceptedAnswer,
    currentQuestion.isDoctorQuestion,
    currentQuestion.order,
    handleResetSurvey,
    handleSurveyStarted,
    onClose,
    renderContent,
    showDoctorStop,
    showStop,
    product,
    isWeb,
  ]);

  return (
    <div
      className={`survey-page-container${isWeb ? "-web" : ""}`}
      style={getProductThemeOverrides(product) as any}
    >
      <div className="inner-container">{renderMainView}</div>
    </div>
  );
};

export default SurveyPageWeb;
