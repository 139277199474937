import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiPanel,
} from "@elastic/eui";
import { QubesAPI } from "../../qubes-client/api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser, userSelector } from "../../state/slices/user-slice";
import { useNavigate } from "react-router-dom";
import "./login-page.scss";
import { useSelector } from "react-redux";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [un, setUn] = useState("");
  const [pw, setPw] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState("");
  const [loginInProgress, setLoginInProgress] = useState(false);

  const user = useSelector(userSelector);

  /**
   * We need to watch for the user to load from redux
   * after a successful login. Since we're using useNavigate,
   * the page won't refresh and the AuthWall won't be
   * processed again. Thus! we need to set the XSESS on the
   * QubesAPI
   */
  useEffect(() => {
    if (user?.sess) {
      QubesAPI.get().setXSESS(user.sess);
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div className="_login_page page">
      <EuiPanel grow={false} className="panel">
        <EuiForm component="form" isInvalid={showErrors} error={errors}>
          <EuiFlexGroup direction="column">
            <div className="row">
              <EuiFieldText
                fullWidth
                id="un"
                name="un"
                placeholder="Username"
                autoComplete="current-username"
                onChange={(e) => setUn(e.target.value)}
                icon={"user"}
              />
            </div>
            <EuiFormRow>
              <EuiFieldPassword
                fullWidth
                type="password"
                id="pw"
                name="pw"
                placeholder="Password"
                autoComplete="current-password"
                onChange={(e) => setPw(e.target.value)}
              />
            </EuiFormRow>
            <EuiButton
              isLoading={loginInProgress}
              onClick={async () => {
                setLoginInProgress(true);
                setShowErrors(false);
                try {
                  const user = await QubesAPI.get().login(un, pw);
                  dispatch(setUser({ user }));
                } catch (err: any) {
                  setErrors(err);
                  setShowErrors(true);
                  setLoginInProgress(false);
                }
                //todo go to next page
              }}
            >
              Login
            </EuiButton>
            {/* <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        useOneTap
      /> */}
          </EuiFlexGroup>
        </EuiForm>
      </EuiPanel>
    </div>
  );
};

export default LoginPage;
