import "./web-home-page.scss";
import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiSpacer } from "@elastic/eui";

import { HomePageShoppingBannerCVS } from "../../../../assets/assets";
import HomePageItemRow from "../../../../components/home-page-item-row/home-page-item-row";
import { WebHomePageProps } from "../../../common/web/web-home-page";
import { ItemMaster } from "../../../../model/pos/item-master";
import { useMemo } from "react";

/**
 * This is the home page for the Kroger demo
 * @returns {JSX.Element}
 */
const CvsWebsiteHomePage = ({ items }: WebHomePageProps): JSX.Element => {
  const row1Items: ItemMaster[] = useMemo(() => {
    return items?.slice(0, 4) ?? [];
  }, [items]);

  const row2Items: ItemMaster[] = useMemo(() => {
    return items?.slice(4, 8) ?? [];
  }, [items]);

  const row3Items: ItemMaster[] = useMemo(() => {
    return items?.slice(8, 12) ?? [];
  }, [items]);

  return (
    <EuiFlexGroup
      className="cvs-website-home-page"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gutterSize="none"
    >
      <EuiSpacer size="l" />
      <EuiFlexItem className="sale-banner" grow={false}>
        <EuiImage
          src={HomePageShoppingBannerCVS}
          alt="shopping 1"
          className="rounded-corner-image"
        />
      </EuiFlexItem>
      <EuiSpacer size="xl" />
      <EuiFlexItem className="trending-items-text">
        Shop Trending Items
      </EuiFlexItem>
      <EuiSpacer size="xl" />
      <HomePageItemRow key="row1" items={row1Items} />
      <EuiSpacer size="xl" />
      <HomePageItemRow key="row2" items={row2Items} />
      <EuiSpacer size="xl" />
      <HomePageItemRow key="row3" items={row3Items} />
    </EuiFlexGroup>
  );
};

export default CvsWebsiteHomePage;
