import { StopSign } from "../../../../assets/assets";
import { toTitleCase } from "../../../../utils/text-utils";
import { Product } from "../product-resolver";
import "./survey-stop.scss";

export type SurveyStopProps = {
  product: Product;
};

const SurveyStop = ({ product }: SurveyStopProps) => {
  return (
    <div className="survey-stop-container">
      <div className="top">
        <img src={StopSign} alt="stopsign" />
        <div className="warning-text">
          Do Not Use {toTitleCase(product)}® OTC.
        </div>
      </div>
      <div className="bold-text">
        You will not be able to purchase {toTitleCase(product)}® OTC.
      </div>
      <div className="text">
        Thank you for participating in this health survey assessment.
      </div>
    </div>
  );
};

export default SurveyStop;
