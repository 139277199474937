import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Header from "../../components/header/header";
import KioskDetailsPanel from "../../components/kiosk-content/details/kiosk-details-panel";
import { ItemMaster } from "../../model/pos/item-master";
import "./kiosk-page.scss";
import SurveyModal from "../../components/survey-modal/survey-modal";
import CheckoutModal from "../../components/checkout-modal/checkout-modal";
import { useDispatch } from "react-redux";
import {
  posStoreStateSelector,
  registerStateSelector,
  setRegister,
  setStore,
} from "../../state/slices/model-slice";
import { useSelector } from "react-redux";
import { QubesService } from "../../services/qubes-service";
import { ReceiptLineItem } from "../../model/pos/receipt-line-item";
import { PointOfSaleEvent } from "../../model/sanofi-otc/point-of-sale-event";
import NeedSurveyModal from "../../components/need-survey-modal/need-survey-modal";

// The total number of packs a single survey will grant the user
const maxPackages = 5;

/**
 * The self serve Kiosk demo page
 * @returns {JSX.Element}
 */
const KioskPage = (): JSX.Element => {
  const dispatch = useDispatch();

  // The items for sale
  const [items, setItems] = useState<ItemMaster[]>([]);

  // The currently selected or most recently added item
  const [selectedItem, setSelectedItem] = useState<ReceiptLineItem | undefined>(
    undefined
  );

  // The number of packs remaining for the user
  const [remaining] = useState<number>(maxPackages - 1);

  // Whether or not the user has scanned a valid QR code
  const [acceptedQR, setAcceptedQR] = useState<boolean>(false);

  // Whether or not the SurveyModal is open
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState<boolean>(false);

  // Whether or not the customer is checking out
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);

  // Whether or not the NeedSurveyModal is open
  const [isNeedSurveyModalOpen, setIsNeedSurveyModalOpen] =
    useState<boolean>(false);

  // This map tracks any items that have an associated POSEvent that needs to be updated upon sale completion
  const itemToEventMap = useRef<Record<string, PointOfSaleEvent>>({});

  // The register we are running on
  const register = useSelector(registerStateSelector);
  // The store the register is in
  const store = useSelector(posStoreStateSelector);

  /**
   * Load the data model
   */
  useEffect(() => {
    QubesService.loadItems()
      .then((resp) => {
        if (resp.data) {
          const sortedItems = resp.data.sort((a, b) => {
            return a.weight - b.weight;
          });
          const items = sortedItems.filter((item) => item.sanofiOtcSku);
          if (!items) return;
          setItems(items);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    //load the registers
    QubesService.loadRegisters()
      .then((resp) => {
        if (resp.data) {
          const register = resp.data.find((reg) =>
            reg.name.startsWith("Kiosk")
          );

          dispatch(setRegister(register!));
        }
      })
      .catch((err) => {
        console.error(err);
      });

    //load the store
    QubesService.loadStores()
      .then((resp) => {
        if (resp.data && resp.data[0]) {
          dispatch(setStore(resp.data[0]));
        } else {
          throw new Error("Unable to load stores");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  /**
   * Render the landing
   */
  const renderLanding = useMemo(() => {
    return (
      <div className="content-container">
        <KioskDetailsPanel
          receiptLineItem={selectedItem}
          item={undefined}
          handleUpdateQuantity={() => {}}
          remaining={remaining}
        />
        <div
          className="goto-scan button"
          onClick={() => {
            const receiptLineItem = ReceiptLineItem.fromItem(items?.[0]);
            setSelectedItem(receiptLineItem);
            setIsSurveyModalOpen(true);
          }}
        >
          I already have a QR code
        </div>
        <div
          className="goto-survey button"
          onClick={() => setIsNeedSurveyModalOpen(true)}
        >
          I need to take the survey
        </div>
      </div>
    );
  }, [remaining, setIsSurveyModalOpen, setSelectedItem, selectedItem, items]);

  /**
   * Handles the canceling of the transaction
   */
  const handleCancel = () => {
    setAcceptedQR(false);
  };

  /**
   * Renders the screen for customizing the transaction
   */
  const renderTransactions = useMemo(() => {
    return (
      <div className="content-container">
        <KioskDetailsPanel
          receiptLineItem={selectedItem}
          item={items.find((i) => i.uid === selectedItem?.itemUid)}
          handleUpdateQuantity={() => {}} //handleUpdateQuantity}
          remaining={remaining}
        />
        <div
          className="check-out button"
          onClick={() => setIsCheckingOut(true)}
        >
          Check Out
        </div>
        <div className="cancel button" onClick={() => handleCancel()}>
          Cancel Transaction
        </div>
      </div>
    );
  }, [items, selectedItem, remaining]);

  /**
   * Dynamically choose the correct renderer
   */
  const chooseRenderer = useMemo(() => {
    if (!acceptedQR) {
      return renderLanding;
    } else {
      return renderTransactions;
    }
  }, [acceptedQR, renderLanding, renderTransactions]);

  /**
   * Handles the submission of the new survey
   */
  const handleSubmitSurvey = useCallback(
    (posEvent?: PointOfSaleEvent) => {
      if (!selectedItem) return;

      setIsSurveyModalOpen(false);
      if (posEvent) {
        itemToEventMap.current[selectedItem.uid] = posEvent;
        setAcceptedQR(true);
      }
    },
    [setIsSurveyModalOpen, setAcceptedQR, selectedItem]
  );

  /**
   * Render the simulated survey modal
   */
  const renderSurveyModal = useMemo(() => {
    if (!register || !store) {
      return;
    }
    return (
      <SurveyModal
        handleSubmitSurvey={handleSubmitSurvey}
        registerId={register.uid}
        storeId={store.uid}
      />
    );
  }, [handleSubmitSurvey, register, store]);

  /**
   * Render the need to complete a survey modal
   */
  const renderNeedSurveyModal = useMemo(() => {
    return <NeedSurveyModal onClose={() => setIsNeedSurveyModalOpen(false)} />;
  }, [setIsNeedSurveyModalOpen]);

  const reset = useCallback(() => {
    setIsCheckingOut(false);
    setIsSurveyModalOpen(false);
    setSelectedItem(undefined);
    setAcceptedQR(false);
    setIsNeedSurveyModalOpen(false);
    itemToEventMap.current = {};
  }, [setIsCheckingOut, setIsSurveyModalOpen, setSelectedItem, setAcceptedQR]);

  /**
   * Render the CheckoutModal
   */
  const renderCheckoutModal = useMemo(() => {
    return (
      <CheckoutModal
        kiosk={true}
        receiptLineItems={[selectedItem!]}
        items={items}
        register={register!}
        onCancel={() => setIsCheckingOut(false)}
        onCompleted={async () => {
          //for every receiptLineItem with an associated POSEvent, need to resolve those
          // NOTE: This should really be done transactionally with the checkout. We would
          // not want to have a case where the checkout succeeded but the POSEvent update
          // had failed
          try {
            const updates = Object.entries(itemToEventMap.current).map(
              async (entry) => {
                const rcl = selectedItem;
                const item = items.find((i) => i.uid === rcl?.itemUid);
                console.info(
                  `Update the POSEvent records to "Sold" | ${item?.name}`
                );
                entry[1].upc = item?.upc;
                entry[1].quantity = 1;
                entry[1].status = "Sold";
                entry[1].saleCompleted = new Date().toISOString();
                return QubesService.updatePOSEvent(entry[1]);
              }
            );

            await Promise.all(updates);
          } catch (err) {
            console.error("Error update the pos events", err);
          }

          reset();
        }}
      />
    );
  }, [items, selectedItem, setIsCheckingOut, reset, register]);

  /**
   * Dynamicall render the correct modal, if needed
   */
  const chooseModal = useMemo(() => {
    if (isSurveyModalOpen) {
      return renderSurveyModal;
    } else if (isCheckingOut) {
      return renderCheckoutModal;
    } else if (isNeedSurveyModalOpen) {
      return renderNeedSurveyModal;
    }
  }, [
    isCheckingOut,
    isSurveyModalOpen,
    isNeedSurveyModalOpen,
    renderCheckoutModal,
    renderSurveyModal,
    renderNeedSurveyModal,
  ]);

  return (
    <div className="kiosk-page-container">
      <Header />
      {chooseRenderer}
      {chooseModal}
    </div>
  );
};

export default KioskPage;
