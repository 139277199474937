import { EuiFlexItem, EuiFlexGroup, EuiText, EuiSpacer } from "@elastic/eui";
import "./web-item-detail-further-details.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const CostcoWebItemFurtherDetails = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      key={"item-further-details"}
      className="costco-item-further-details"
      direction="column"
      alignItems="baseline"
    >
      <EuiFlexItem className="section-names">
        <EuiFlexGroup direction="row" justifyContent="spaceEvenly">
          <EuiText>Product Details</EuiText>
          <EuiText>Specifications</EuiText>
          <EuiText>Shipping & Returns</EuiText>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem className="product-details" grow={false}>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiSpacer size="l" />
          <EuiText className="section-name-title">Product Details</EuiText>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <ul>
              {item.primaryFeatures?.map((feature) => {
                return <li key={feature}>{feature}</li>;
              })}
            </ul>
          </EuiFlexItem>
          <EuiSpacer size="s" />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem className="specifications" grow={false}>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiSpacer size="l" />
          <EuiText className="section-name-title">Specifications</EuiText>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <table>
              <tbody>
                {item.specifications?.map((spec) => {
                  return (
                    <tr key={spec[1]}>
                      <td>{spec[0]}</td>
                      <td>{spec[1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </EuiFlexItem>
          <EuiSpacer size="s" />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem className="shipping-and-returns" grow={false}>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiSpacer size="l" />
          <EuiText className="section-name-title">Shipping & Returns</EuiText>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <table>
              <thead>
                <tr>
                  <td>Order by Noon</td>
                  <td>Delivered By</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Monday</td>
                  <td>Wednesday</td>
                </tr>
                <tr>
                  <td>Tuesday</td>
                  <td>Thursday</td>
                </tr>
                <tr>
                  <td>Wednesday</td>
                  <td>Friday</td>
                </tr>
                <tr>
                  <td>Thursday</td>
                  <td>Monday</td>
                </tr>
                <tr>
                  <td>Friday</td>
                  <td>Tuesday</td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>Tuesday</td>
                </tr>
                <tr>
                  <td>Sunday</td>
                  <td>Tuesday</td>
                </tr>
              </tbody>
            </table>
          </EuiFlexItem>
          <EuiSpacer size="xl" />
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
