import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiFieldText,
  EuiIcon,
  EuiButton,
  EuiSpacer,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";

export const CvsWebItemDetailOrdering = ({
  item,
  itemCount,
  updateItemCount,
  increment,
  decrement,
  addToCart,
}: WebItemDetailOrderingProps) => {
  return (
    <>
      <EuiSpacer size="s" />

      <EuiFlexItem className="cvs-item-ordering-wrapper" grow={false}>
        <EuiFlexGroup
          className="item-ordering"
          direction="row"
          style={{ flexGrow: 0 }}
        >
          <EuiFieldText
            value={itemCount}
            onChange={(evt) => updateItemCount(evt.target.value)}
            prepend={
              !item.sanofiOtcSku ? (
                <EuiIcon type={"minus"} onClick={decrement} />
              ) : undefined
            }
            append={
              !item.sanofiOtcSku ? (
                <EuiIcon type={"plus"} onClick={increment} />
              ) : undefined
            }
          />
          <EuiButton onClick={addToCart}>Add for pickup</EuiButton>
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};
