import { EuiFlexGroup, EuiIcon, EuiText } from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";
import { toCurrency } from "../../../../utils/text-utils";

export const KrogerWebItemDetailPricing = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      className="kroger-item-details--price"
      direction="column"
      justifyContent="flexStart"
    >
      <EuiText className="item-details--price-purchaseoptions">
        Purchase Options
      </EuiText>
      <EuiFlexGroup
        className="item-details--price-box"
        dir="row"
        justifyContent="spaceBetween"
      >
        <EuiText className="item-details--price-values">
          {toCurrency(item.price)}
        </EuiText>
        <EuiFlexGroup
          gutterSize="xs"
          alignItems="center"
          style={{ flexGrow: 0 }}
        >
          <EuiText className="item-details--price-pickup">Pickup</EuiText>
          <EuiIcon type="arrowDown" size="s" color="primary" />
        </EuiFlexGroup>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
