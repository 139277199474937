import { BehaviorSubject } from "rxjs";
import { ItemMaster } from "../model/pos/item-master";
import { replacer, reviver } from "../utils/json-serialization-utils";
import { PointOfSaleEvent } from "../model/sanofi-otc/point-of-sale-event";

export class ShoppingCartService {
  private static _instance = new ShoppingCartService();

  static get() {
    return this._instance;
  }

  observable() {
    return this.items.asObservable();
  }

  private SESSION_STORAGE_KEY = "shopping-cart-service";
  private HSA_STORAGE_KEY = "hsa-service";
  private items = new BehaviorSubject(new Map<string, number>());

  // This map tracks any items that have an associated POSEvent that needs to be updated upon sale completion
  private itemToEventMap = new Map<string, string>();

  private constructor() {
    this.restoreSession();
  }

  private saveSession() {
    window.sessionStorage.setItem(
      this.SESSION_STORAGE_KEY,
      JSON.stringify(this.items.getValue(), replacer)
    );
    window.sessionStorage.setItem(
      this.HSA_STORAGE_KEY,
      JSON.stringify(this.itemToEventMap, replacer)
    );
  }

  private restoreSession() {
    const previousSession = window.sessionStorage.getItem(
      this.SESSION_STORAGE_KEY
    );
    if (previousSession) {
      const resolvedSession = JSON.parse(previousSession, reviver);
      this.items.next(resolvedSession);
    }

    const previousHsaSession = window.sessionStorage.getItem(
      this.HSA_STORAGE_KEY
    );
    if (previousHsaSession) {
      const resolvedSession = JSON.parse(previousHsaSession, reviver);
      this.itemToEventMap = resolvedSession;
    }
  }

  // getItems() {
  //   return this.items;
  // }

  getItemToEventMap() {
    return this.itemToEventMap;
  }

  getShoppingCartItems() {
    return this.items.getValue().entries();
  }

  add(item: ItemMaster, quantity: number = 1) {
    const theseItems = this.items.getValue();
    const thisQuantity = theseItems.get(item.uid);
    if (thisQuantity) {
      theseItems.set(item.uid, thisQuantity + quantity);
    } else theseItems.set(item.uid, quantity);
    this.items.next(theseItems);
    this.saveSession();
  }

  remove(item: ItemMaster) {
    const theseItems = this.items.getValue();

    const thisQuantity = theseItems.get(item.uid);

    if (thisQuantity) {
      const newQuantity = thisQuantity - 1;

      if (newQuantity < 1) theseItems.delete(item.uid);
      else theseItems.set(item.uid, newQuantity);
    }
    this.items.next(theseItems);
    this.saveSession();
  }

  update(item: ItemMaster, newQuantity: number) {
    const theseItems = this.items.getValue();
    if (newQuantity > 0) {
      theseItems.set(item.uid, newQuantity);
    } else {
      theseItems.delete(item.uid);
    }
    this.items.next(theseItems);
    this.saveSession();
  }

  clear() {
    this.items.next(new Map());
    this.itemToEventMap = new Map();
    this.saveSession();
  }

  setItemToEvent(item: ItemMaster, posEvent: PointOfSaleEvent) {
    this.itemToEventMap.set(item.uid, posEvent.uid);
    this.saveSession();
  }

  removeItemToEvent(item: ItemMaster) {
    this.itemToEventMap.delete(item.uid);
    this.saveSession();
  }
}
