import { useCallback, useEffect, useRef, useState } from "react";
import "./web-item-detail-page.scss";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { ShoppingCartService } from "../../../../services/shopping-cart-service";
import { useSelector } from "react-redux";
import {
  posStoreStateSelector,
  registerStateSelector,
} from "../../../../state/slices/model-slice";
import { PointOfSaleEvent } from "../../../../model/sanofi-otc/point-of-sale-event";
import SurveyModalWeb from "../../../../components/survey-modal-web/survey-modal-web";
import { CostcoLogo } from "../../../../assets/assets";
import { QubesService } from "../../../../services/qubes-service";
import SurveyPageWeb from "../../../../pages/survey-web/survey-page-web";
import { Product } from "../../../../product/common/survey/product-resolver";
import { CompanyResolver } from "../company-resolver";
import { WebItemProps } from "../web-item-props";

/**
 * This is the page that controls all actions within the POS demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebItemDetailPage = ({
  item,
  itemsDatabase,
}: WebItemProps): JSX.Element => {
  const [itemCount, setItemCount] = useState(1);

  // The register we are running on
  const register = useSelector(registerStateSelector);
  // The store the register is in
  const store = useSelector(posStoreStateSelector);

  // The state of the code entry modal
  const [isCodeModalOpen, setIsCodeModalOpen] = useState<boolean>(false);

  // The state of the survey scan modal
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState<boolean>(false);

  const validSurveyCode = useRef<string>();
  const validQrUid = useRef<string>();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const updateItemCount = useCallback(
    (newValue: string) => {
      const parsedInt = parseInt(newValue);
      if (!Number.isNaN(parsedInt)) return;
      else setItemCount(parsedInt);
    },
    [setItemCount]
  );

  const decrement = useCallback(() => {
    if (itemCount > 0) {
      setItemCount((currVal) => currVal - 1);
    }
  }, [itemCount, setItemCount]);

  const increment = useCallback(() => {
    setItemCount((currVal) => currVal + 1);
  }, [setItemCount]);

  const handleSurveyScan = useCallback(
    (posEvent?: PointOfSaleEvent) => {
      //if there isn't a selectedItem, what do we do??? There's no way to recover and deal with the modal
      if (!item) return;

      setIsCodeModalOpen(false);
      if (posEvent) {
        ShoppingCartService.get().add(item, 1);
        ShoppingCartService.get().setItemToEvent(item, posEvent);
      }
    },
    [setIsCodeModalOpen, item]
  );

  const addToCart = useCallback(() => {
    if (item.sanofiOtcSku) {
      if (!validSurveyCode.current) {
        console.debug("User has requested a product requiring HSA");
        setIsCodeModalOpen(true);
      }
    } else {
      ShoppingCartService.get().add(item, itemCount);
    }
  }, [item, itemCount]);

  const handleNewSurveyCode = useCallback(
    (newSurveyCode: string, qrUid: string) => {
      validSurveyCode.current = newSurveyCode;
      validQrUid.current = qrUid;
    },
    []
  );

  const handleSurveyClose = useCallback(
    (addToCart: boolean) => {
      setIsSurveyModalOpen(false);
      if (addToCart && validSurveyCode.current && register && store) {
        console.info(`Insert POSEvent`);
        const posEvent = new PointOfSaleEvent({
          uid: "",
          initiated: new Date().toISOString(),
          quantity: 0,
          rawCorrelationCode: validSurveyCode.current,
          correlationCodeUid: validQrUid.current,
          registerId: register?.uid,
          status: "Granted",
          store: store.uid,
          active: true,
        });
        QubesService.insertPOSEvent(posEvent).then((event) => {
          if (
            !event.success ||
            (event.numberOfRowsUpdated ?? 0) < 1 ||
            !event.keys
          ) {
            console.error(`Failed to insert the POSEvent record`);
          } else {
            posEvent.uid = event.keys?.uid;
          }
          handleSurveyScan(posEvent);
        });
      }
    },
    [handleSurveyScan, register, store]
  );

  return (
    <EuiFlexGroup
      className="website-item-detail-page"
      direction="column"
      alignItems="center"
      justifyContent="flexStart"
      gutterSize="none"
    >
      <EuiSpacer size="l" />

      {/* path */}
      {CompanyResolver.get().getComponentResolver().getWebItemPath(item)}
      <EuiSpacer size="l" />

      {/* detail row */}
      <EuiFlexItem className="item-main-detail-row-wrapper">
        <EuiFlexGroup className="item-main-detail-row" direction="row">
          <EuiFlexItem className="item-image-wrapper" grow={true}>
            <EuiImage
              className="item-image"
              src={item.imageUrlLarge}
              alt="item image"
            />
          </EuiFlexItem>
          <EuiFlexGroup
            className="item-details-and-ordering-wrapper"
            justifyContent="flexStart"
            gutterSize="s"
          >
            <EuiFlexItem className="item-details-wrapper">
              <EuiFlexGroup
                className="item-details"
                direction="column"
                justifyContent="flexStart"
                gutterSize="none"
              >
                <EuiText className="item-details--name">{item.name}</EuiText>
                <EuiSpacer size="m" />
                <EuiText className="item-details--itemno" size="xs">
                  Item 269852
                </EuiText>
                <EuiSpacer size="s" />
                <EuiFlexItem grow={false}>
                  {CompanyResolver.get()
                    .getComponentResolver()
                    .getItemDetailPricing({ item, itemsDatabase })}
                </EuiFlexItem>
                {CompanyResolver.get()
                  .getComponentResolver()
                  .getItemDetailFeatures({ item, itemsDatabase })}
                {item.sanofiOtcSku ? (
                  <EuiFlexItem className="item-features-restrictions">
                    <span>
                      **Purchase of this product requires a valid HSA (health
                      survey assessment) code.{" "}
                      {
                        // eslint-disable-next-line
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsSurveyModalOpen(true);
                          }}
                        >
                          Click here
                        </a>
                      }{" "}
                      for more details.
                    </span>
                  </EuiFlexItem>
                ) : null}
              </EuiFlexGroup>
            </EuiFlexItem>
            {/* add the appropriate ordering section for this company */}
            {CompanyResolver.get()
              .getComponentResolver()
              .getItemDetailOrdering({
                item,
                itemCount,
                updateItemCount,
                increment,
                decrement,
                addToCart,
              })}
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiFlexItem>
      {/* further details */}
      {CompanyResolver.get()
        .getComponentResolver()
        .getItemDetailFurtherDetails({ item, itemsDatabase })}

      {/* survey modal */}
      {isCodeModalOpen && register && store ? (
        <SurveyModalWeb
          handleSubmitSurvey={handleSurveyScan}
          onTakeSurvey={() => {
            setIsCodeModalOpen(false);
            setIsSurveyModalOpen(true);
          }}
          registerId={register.uid}
          storeId={store.uid}
        />
      ) : null}
      {/* survey modal */}
      {isSurveyModalOpen ? (
        <EuiFlexGroup className="survey-page" direction="column">
          <EuiImage className="costco-logo" src={CostcoLogo} alt="" />
          <SurveyPageWeb
            onNewSurveyCode={handleNewSurveyCode}
            onClose={handleSurveyClose}
            product={item.surveyId as Product}
            isWeb={true}
          />
        </EuiFlexGroup>
      ) : null}
    </EuiFlexGroup>
  );
};

export default WebItemDetailPage;
