import { Question } from "../model/sanofi-otc/question";

export type SurveyIds = "cialis" | "super aspirin";

// The order of the main views
export const cialisViews: string[] = [
  "landing",
  "welcome",
  "ed",
  "warning",
  "questions",
  "code",
];

// The questions to ask
export const cialisQuestions: Question[] = [
  {
    order: 1,
    header: "Are you over 18?",
    details: "This medicine is only for men who are 18 years of age and older.",
    acceptedAnswer: "yes",
    uid: "one",
  },
  {
    order: 2,
    header: "Is your birth gender male?",
    details:
      "Cialis® OTC has only been shown to work in people with ED who were assigned male at birth and have a penis.",
    acceptedAnswer: "yes",
    uid: "two",
  },
  {
    order: 3,
    header: "Do you take Nitrates?",
    details:
      'Taking Cialis® OTC with "Poppers" (nitrates) could lead to a dangerous drop in blood pressure, which can lead to a stroke or heart attack.',
    acceptedAnswer: "no",
    last: true,
    isDoctorQuestion: true,
    uid: "three",
  },
];

// The order of the main views
export const aspirinViews: string[] = [
  "landing",
  "welcome",
  "warning",
  "questions",
  "code",
];

// The questions to ask
export const aspirinQuestions: Question[] = [
  {
    order: 1,
    header: "Are you over 18?",
    details:
      "This medicine is only for persons who are 18 years of age and older.",
    acceptedAnswer: "yes",
    uid: "one",
  },
  {
    order: 2,
    header: "Do you take blood pressure medication?",
    details:
      "Super aspirin should not be taken in conjunction with prescription blood pressure medication.",
    acceptedAnswer: "no",
    uid: "two",
  },
];
