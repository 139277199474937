import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiFieldText,
  EuiIcon,
  EuiButton,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";

export const CostcoWebItemDetailOrdering = ({
  item,
  itemCount,
  updateItemCount,
  increment,
  decrement,
  addToCart,
}: WebItemDetailOrderingProps) => {
  return (
    <EuiFlexItem className="costco-item-ordering-wrapper">
      <EuiFlexGroup className="item-ordering" direction="column">
        <EuiText>Delivery</EuiText>
        <EuiFieldText
          value={itemCount}
          onChange={(evt) => updateItemCount(evt.target.value)}
          prepend={
            !item.sanofiOtcSku ? (
              <EuiIcon type={"minus"} onClick={decrement} />
            ) : undefined
          }
          append={
            !item.sanofiOtcSku ? (
              <EuiIcon type={"plus"} onClick={increment} />
            ) : undefined
          }
        />
        <EuiButton onClick={addToCart}>Add to Cart</EuiButton>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};
