import { UidKey } from "../common";

export type Receipt = UidKey & {
  uid: string;
  registerUid: string;
  active: boolean;
  extraConfig?: unknown;
};

const _sample: Required<Receipt> = {
  uid: "string",
  active: false,
  registerUid: "string",
  extraConfig: "json",
};
export const ReceiptFieldList = Object.keys(_sample);
