import "./scan-button.scss";

/**
 * The props for ScanButton to work
 * @prop {() => void} getRandomItem - The method to call when 'scanning' a new item
 */
export type ScanButtonProps = {
  getRandomItem: () => void;
};

/**
 * The button for simulating the scanning of an item at a POS
 * @prop {ScanButtonProps} - The props needed to function
 * @returns {React.Component}
 */
const ScanButton = ({ getRandomItem }: ScanButtonProps) => {
  return (
    <div className="scan-button-container" onClick={() => getRandomItem()}>
      Scan Item
    </div>
  );
};

export default ScanButton;
